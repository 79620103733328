import Vue from 'vue'
import {
  InternalNavigateMentionsMutationInterface,
  NavigateMentionsMutationInterface,
  ClientsActionInterface,
  NavigateParticipantsActionInterface
} from '../storeInterfaces.js'

const TemplateNavigateMentionedUser = {
  leadId: '',
  userId: '',
  fullName: '',
  modifiedAt: new Date(),
  createdAt: new Date()
}

const state = {
  user: undefined,
  messageIds: [],
  messageIndex: undefined
}

const getters = {
  hasUser: state => !!state.user,
  hasMessages: state => !!state.user && Array.isArray(state.messageIds) && state.messageIds.length > 0,
  hasMessage: state => !!state.user && Array.isArray(state.messageIds) && state.messageIds.length > 0 && state.messageIndex >= 0 && state.messageIndex < state.messageIds.length,
  user: state => state.user,
  messageIds: state => !!state.user && Array.isArray(state.messageIds) ? state.messageIds : [],
  messageIndex: state => state.messageIndex,
  messageId: state => !!state.user && Array.isArray(state.messageIds) && state.messageIds.length > 0 && state.messageIndex >= 0 && state.messageIndex < state.messageIds.length ? state.messageIds[state.messageIndex] : '',
  messageStatus: state => !!state.user && Array.isArray(state.messageIds) && state.messageIds.length > 0 && state.messageIndex >= 0 && state.messageIndex < state.messageIds.length ? `${state.messageIndex + 1} / ${state.messageIds.length}` : '(None)'
}

const mutations = {
  [InternalNavigateMentionsMutationInterface.Reset] (state, payload) {
    state.messageIndex = undefined
    state.messageIds = []
    state.user = undefined
  },
  [InternalNavigateMentionsMutationInterface.Initialize] (state, payload) {
    if (typeof payload !== 'object') return
    state.messageIndex = undefined
    state.messageIds = []
    state.user = { ...payload }
  },
  [InternalNavigateMentionsMutationInterface.InitializeMessages] (state, payload) {
    if (!Array.isArray(payload)) return
    state.messageIds = payload
    state.messageIndex = 0
    // Vue.$log.debug(`[DEV]: navigateMentions >> ${NavigateMentionsMutationInterface.InitializeMessages}`, state.messageIds)
  },
  [InternalNavigateMentionsMutationInterface.Select] (state, payload) {
    if (!Array.isArray(state.messageIds) || state.messageIds.length === 0 || !Number.isInteger(payload)) return
    // const prevMessageIndex = state.messageIndex
    let newIndex = state.messageIndex + payload
    if (newIndex === -1) {
      newIndex = state.messageIds.length - 1
    } else if (newIndex === state.messageIds.length) {
      newIndex = 0
    }
    state.messageIndex = newIndex
    // Vue.$log.debug(`[DEV]: navigateMentions >> ${NavigateMentionsMutationInterface.Select}`, prevMessageIndex, state.messageIndex, state.messageIds[state.messageIndex])
  }
}

const actions = {
  reset ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    commit(InternalNavigateMentionsMutationInterface.Reset)
  },
  setUser ({ state, rootState, commit, dispatch, getters, rootGetters }, payload) {
    if (typeof payload !== 'object') return
    dispatch(ClientsActionInterface.resetMessageFilter, null, { root: true })
    dispatch(NavigateParticipantsActionInterface.reset, null, { root: true })
    const newActiveMentionedUser = {
      leadId: payload.leadId,
      userId: payload.userId,
      fullName: payload.fullName,
      createdAt: payload.createdAt,
      modifiedAt: payload.modifiedAt
    }
    commit(InternalNavigateMentionsMutationInterface.Initialize, newActiveMentionedUser)
  },
  setMessageIds ({ state, rootState, commit, dispatch, getters, rootGetters }, messageIds) {
    if (!Array.isArray(messageIds) || messageIds.length === 0) return
    commit(InternalNavigateMentionsMutationInterface.InitializeMessages, messageIds)
  },
  selectPrevMessage ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    if (getters.messageIds.length === 0) return
    commit(InternalNavigateMentionsMutationInterface.Select, -1)
  },
  selectNextMessage ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    if (getters.messageIds.length === 0) return
    commit(InternalNavigateMentionsMutationInterface.Select, 1)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
