  <template>

  <!-- Login / Loader -->
  <main v-if="!isSystemInitialized" id="gs-app" style="height:100vh !important;">
    <router-view />
  </main>
  <!-- Dashboard -->
  <div v-else id="gs-app" style="height:100vh !important;display:grid;grid-template-rows:auto 1fr auto;grid-template-columns:auto 1fr auto;">
    <!-- Header -->
    <header id="gs-header" style="grid-row:1;grid-column:1/span 3;">
      <app-header-component />
    </header>
    <!-- Main Content -->
    <main id="gs-contents" class="is-full-height" style="grid-row:2;grid-column:2/span 2;overflow-y:auto;">
      <router-view />
    </main>
    <!-- TODO: Quick-View -->
    <aside v-if="false" id="gs-quickview" style="grid-row:1/span 3;grid-column:3;z-index:10;">
    </aside>
    <!-- Side-Panel -->
    <aside id="gs-right-sidebar" style="grid-row:2/span 2;grid-column:4;border-left:solid 1px #e6e6e6;">
    </aside>
    <!-- TODO: Footer -->
    <footer v-if="false" id="gs-footer" style="grid-row:3;grid-column:2/span 2;">
      <app-footer-component />
    </footer>

    <!-- Global Portal Placeholder-->
    <portal-target name="global-portal" slim />
  </div>
</template>

<script>
// import logger from '@/services/loggingService'
import {
  SystemGetterInterface,
  SystemActionInterface
} from '@/store/storeInterfaces'
import { mapGetters, mapActions } from 'vuex'
import rtApiService from '@/services/rtApiService'
import AppHeaderComponent from './components/AppHeaderComponent.vue'
import AppFooterComponent from './components/AppFooterComponent.vue'

export default {
  name: 'App',
  data: function () {
    return { }
  },
  components: {
    'app-header-component': AppHeaderComponent,
    'app-footer-component': AppFooterComponent
  },
  computed: {
    ...mapGetters({
      isSystemInitialized: SystemGetterInterface.isSystemInitialized
    })
  },
  methods: {
    ...mapActions({
      systemReset: SystemActionInterface.systemReset
    })
  },
  mounted: function () {
    this.$log.info(`${this.$options.name} Mounted`)
  },
  beforeDestroy: function () {
    rtApiService.destroy()
    this.systemReset()
    this.$log.info(`${this.$options.name} Before Destroy`, rtApiService.isConnected())
  }
}
</script>

<style lang="scss">
  html,
  body {
    margin: 0 !important;
    padding: 0 !important;
  }
</style>

<style scoped lang="scss">
  @media only screen and (max-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
    .app-height {
      height: 100vh;
      max-height: calc(100vh - 69px);
    }
  }
  @media only screen and (max-width: 1366px) and (max-height: 1024px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: landscape) {
    .app-height {
      height: 100vh;
      max-height: calc(100vh - 69px);
    }
  }
</style>
