<!-- TODO: Implement Routing Navigations (Clients, Admin) -->
<template>
  <!-- INFO: Clients -->
  <app-clients-page v-if="hasRouteFilter && routeFilter === CrmRouteEnum.Clients" />
  <!-- INFO: Administration -->
  <app-admin-page v-else-if="hasRouteFilter && routeFilter === CrmRouteEnum.Administration" />
</template>

<script>
import { CrmRouteEnum } from '@/enums'
import {
  SystemGetterInterface,
  SystemActionInterface
} from '@/store/storeInterfaces'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AppDashboardPage',
  components: {
    'app-clients-page': () => import('./AppClientsPage'),
    'app-admin-page': () => import('./AppAdminPage')
  },
  props: { },
  data () {
    return {
      CrmRouteEnum,
      SystemGetterInterface,
      SystemActionInterface
    }
  },
  computed: {
    ...mapGetters({
      hasRoute: SystemGetterInterface.hasRoute,
      hasRouteFilter: SystemGetterInterface.hasRouteFilter,
      routeFilter: SystemGetterInterface.routeFilter
    })
  },
  methods: {
    ...mapActions({
      setRouteAsync: SystemActionInterface.setRouteAsync
    }),
    onWatchRouteChangeAsync: async function (to, from) {
      this.$log.debug(`[DEV]: WATCH: ${this.$options.name}.onWatchRouteChangeAsync(to, from)`, to, from)
      if (!to || !from || to.fullPath === from.fullPath) return
      await this.setRouteAsync(to)
    }
  },
  created () {
    this.$log.info(`${this.$options.name} Created`)
    this.$watch('$route', this.onWatchRouteChangeAsync)
  },
  mounted: async function () {
    this.$log.info(`${this.$options.name} Mounted`)
    if (!this.hasRoute) {
      await this.setRouteAsync(this.$route)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
