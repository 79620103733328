import Vue from 'vue'
import App from './App'
import router from './router/routerIndex'
import store from './store/storeIndex'
import PortalVue from 'portal-vue'
import Buefy from 'buefy'
import lodash from 'lodash'
import Multiselect from 'vue-multiselect'
import UUID from 'vue-uuid'
import Viewer from 'v-viewer'
import Vue2Filters from 'vue2-filters'
import VueVirtualScroller, { RecycleScroller } from 'vue-virtual-scroller'
import VueObserveVisibility from 'vue-observe-visibility'
import money from '@/components/generic/vmoney'
import VTooltip from 'v-tooltip'
import VueNativeNotification from 'vue-native-notification'
import VueLogger from 'vuejs-logger'
import VueDraggableResizable from 'vue-draggable-resizable'
import 'intersection-observer'
import 'bulma-extensions/bulma-quickview/dist/js/bulma-quickview.min.js'
import 'splitpanes/dist/splitpanes.css'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import './css/main.scss'

// import locale from 'element-ui/lib/locale'
// import lang from 'element-ui/lib/locale/lang/en'
// - locale.use(lang) // configure language
// import vueShortKey from 'vue-shortkey'
// - Vue.use(vueShortKey, { prevent: ['.suggestion-list', '.editor__content'] })
// import VueCookies from 'vue-cookies'
// - Vue.use(VueCookies)
// - Vue.$cookies.config('7d')

import globalInit from './global_extensions'

const options = {
  isEnabled: true,
  logLevel: (process.env.NODE_ENV === 'production') ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: false,
  separator: '>>',
  showConsoleColors: true
}

Vue.use(VueLogger, options)
Object.defineProperty(Vue.prototype, '$lodash', { value: lodash })

Vue.$log.info('Install Vue.js plugins & Initializations...')

Vue.use(PortalVue)
Vue.use(VTooltip)
Vue.use(UUID)
Vue.use(VueObserveVisibility)
Vue.use(VueVirtualScroller)
Vue.use(Buefy)
Vue.use(Viewer, { defaultOptions: { zIndex: 100 } })
Vue.use(Vue2Filters) // Usage: InventoryItemComponent
Vue.use(VueNativeNotification, { // TODO
  requestOnNotify: true
})
Vue.use(money, { precision: 2 })

Vue.component('multi-select', Multiselect)
Vue.component('vue-draggable-resizable', VueDraggableResizable)
Vue.component('recycle-scroller', RecycleScroller)

Vue.directive('observe-visibility', VueObserveVisibility.ObserveVisibility)

Vue.config.productionTip = false

globalInit(Vue)

new Vue({
  el: '#gs-app',
  store,
  router,
  components: { App },
  template: '<App/>'
})
