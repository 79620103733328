import Vue from 'vue'
import {
  InternalNavigateParticipantsMutationInterface,
  NavigateParticipantsMutationInterface,
  ClientsActionInterface,
  NavigateMentionsActionInterface
} from '../storeInterfaces.js'

const TemplateNavigateParticipant = {
  clientId: '',
  leadId: '',
  participantId: '',
  userId: '',
  fullName: ''
}

const state = {
  participant: undefined,
  messageIds: [],
  messageIndex: undefined
}

const getters = {
  hasParticipant: state => !!state.participant,
  hasMessages: state => !!state.participant && Array.isArray(state.messageIds) && state.messageIds.length > 0,
  hasMessage: state => !!state.participant && Array.isArray(state.messageIds) && state.messageIds.length > 0 && state.messageIndex >= 0 && state.messageIndex < state.messageIds.length,
  participant: state => state.participant,
  messageIds: state => !!state.participant && Array.isArray(state.messageIds) ? state.messageIds : [],
  messageIndex: state => state.messageIndex,
  messageId: state => !!state.participant && Array.isArray(state.messageIds) && state.messageIds.length > 0 && state.messageIndex >= 0 && state.messageIndex < state.messageIds.length ? state.messageIds[state.messageIndex] : undefined,
  messageStatus: state => !!state.participant && Array.isArray(state.messageIds) && state.messageIds.length > 0 && state.messageIndex >= 0 && state.messageIndex < state.messageIds.length ? `${state.messageIndex + 1} / ${state.messageIds.length}` : '(None)'
}

const mutations = {
  // CrmParticipantsPanelComponent
  // CrmClientContentHeaderComponent
  [InternalNavigateParticipantsMutationInterface.Reset] (state) {
    state.messageIndex = undefined
    state.messageIds = []
    state.participant = undefined
  },
  // CrmParticipantsPanelComponent.onSetActiveParticipant
  [InternalNavigateParticipantsMutationInterface.Initialize] (state, payload) {
    if (typeof payload !== 'object') return
    state.messageIds = []
    state.messageIndex = undefined
    state.participant = { ...payload }
  },
  // CrmLeadContentComponent.storeSubscriptionAsync
  [InternalNavigateParticipantsMutationInterface.InitializeMessages] (state, payload) {
    if (!Array.isArray(payload)) return
    state.messageIds = payload
    state.messageIndex = 0
  },
  // CrmLeadContentComponent.storeSubscriptionAsync
  [InternalNavigateParticipantsMutationInterface.Select] (state, payload) {
    if (!Array.isArray(state.messageIds) || state.messageIds.length === 0 || !Number.isInteger(payload)) return
    // const prevMessageIndex = state.messageIndex
    let newIndex = state.messageIndex + payload
    if (newIndex === -1) {
      newIndex = state.messageIds.length - 1
    } else if (newIndex === state.messageIds.length) {
      newIndex = 0
    }
    state.messageIndex = newIndex
    // Vue.$log.debug(`[DEV]: navigateParticipants >> ${NavigateParticipantsMutationInterface.Select}`, prevMessageIndex, state.messageIndex, state.messageIds[state.messageIndex])
  }
}

const actions = {
  // CrmParticipantsPanelComponent.onClearActiveParticipant
  // CrmClientContentHeaderComponent.onClearActiveParticipant
  // CrmLeadContentComponent.beforeUpdate
  // CrmLeadContentComponent.beforeDestroy
  reset ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    commit(InternalNavigateParticipantsMutationInterface.Reset)
  },
  // CrmParticipantsPanelComponent.onSetActiveParticipant
  setParticipant ({ state, rootState, commit, dispatch, getters, rootGetters }, participant) {
    if (typeof participant !== 'object') return
    dispatch(ClientsActionInterface.resetMessageFilter, null, { root: true })
    dispatch(NavigateMentionsActionInterface.reset, null, { root: true })
    const newActiveParticipant = {
      clientId: participant.clientId,
      leadId: participant.leadId,
      participantId: participant.participant.id,
      userId: participant.participant.user.id,
      fullName: participant.participant.user.fullName
    }
    commit(InternalNavigateParticipantsMutationInterface.Initialize, newActiveParticipant)
  },
  // CrmLeadContentComponent.storeSubscriptionAsync
  setMessageIds ({ state, rootState, commit, dispatch, getters, rootGetters }, messageIds) {
    if (!Array.isArray(messageIds) || messageIds.length === 0) return
    commit(InternalNavigateParticipantsMutationInterface.InitializeMessages, messageIds)
  },
  // CrmClientContentHeaderComponent.onGotoPrevMessage
  selectPrevMessage ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    // Vue.$log.debug(`[DEV]: ${'participant'}.selectPrevMessage(...)`, state.messageIndex)
    if (getters.messageIds.length === 0) return
    commit(InternalNavigateParticipantsMutationInterface.Select, -1)
  },
  // CrmClientContentHeaderComponent.onGotoNextMessage
  selectNextMessage ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    // Vue.$log.debug(`[DEV]: ${'participant'}.selectNextMessage(...)`, state.messageIndex)
    if (getters.messageIds.length === 0) return
    commit(InternalNavigateParticipantsMutationInterface.Select, 1)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
