import Vue from 'vue'
import {
  InternalInventoriesMutationInterface,
  InventoriesCriteriaGetterInterface,
  InventoriesCriteriaActionInterface
} from '../storeInterfaces.js'
import inventoriesProfileApiService from '../../services/inventoriesProfileApiService.js'
import vehicleMakeService from '../../services/vehicleMakesProfileApiService.js'

const state = {
  coreInventories: undefined,
  coreVehicleMakes: undefined
}

const getters = {
  hasCoreInventories: state => Array.isArray(state.coreInventories),
  coreInventories: state => state.coreInventories,
  isCoreInventoriesEmpty: state => !Array.isArray(state.coreInventories) || state.coreInventories.length === 0,
  hasCoreVehicleMakes: state => Array.isArray(state.coreVehicleMakes),
  coreVehicleMakes: state => state.coreVehicleMakes
}

const mutations = {
  [InternalInventoriesMutationInterface.Reset] (state) {
    state.coreInventories = undefined
    state.coreVehicleMakes = undefined
  },
  [InternalInventoriesMutationInterface.CoreInventories] (state, payload) {
    if (!Array.isArray(payload)) return
    state.coreInventories = [...payload]
  },
  [InternalInventoriesMutationInterface.CoreVehicleMakes] (state, payload) {
    if (!Array.isArray(payload)) return
    state.coreVehicleMakes = [...payload]
  }
}

const actions = {
  reset: function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    commit(InternalInventoriesMutationInterface.Reset)
  },
  loadCoreInventoriesAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }, criteria) {
    if (typeof criteria === 'object') {
      // Vue.$log.debug('[DEV]: inventories.loadCoreInventoriesAsync(criteria) >> DISPATCH', criteria)
      dispatch(InventoriesCriteriaActionInterface.setCriteria, criteria, { root: true })
    }
    // Vue.$log.debug('[DEV]: inventories.loadCoreInventoriesAsync(criteria) >> REQUEST', rootGetters[InventoriesCriteriaGetterInterface.searchCriteria])
    const response = await inventoriesProfileApiService.loadAllAsync(rootGetters[InventoriesCriteriaGetterInterface.searchCriteria])
    // Vue.$log.debug('[DEV]: inventories.loadCoreInventoriesAsync(criteria) >> RESPONSE', response)
    // TODO: Flag all Send CoreInventories
    commit(InternalInventoriesMutationInterface.CoreInventories, response.data.results)
  },
  loadInventoriesNextPageAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    // TODO: Load CoreInventories Next Page
  },
  loadCoreVehicleMakesAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    const response = await vehicleMakeService.allVehicleMakes()
    // Vue.$log.debug('[DEV]: stor.inventories.loadCoreVehicleMakesAsync()', Array.isArray(response), response.length)
    commit(InternalInventoriesMutationInterface.CoreVehicleMakes, response)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
