import {
  InternalInventoriesCriteriaMutationInterface,
  SubscriberGetterInterface
} from '../storeInterfaces'
import {
  InventoryTypeEnum
} from '../../enums'
import {
  DefaultInventoryPriceCriteria,
  DefaultInventorySearchCriteria
} from '../../definitions'

const TemplateDefaultInventoryFilterCriteria = {
  queryText: '',
  type: undefined, // enums.InventoryTypeEnum.All,
  make: undefined,
  productionYear: undefined,
  model: undefined,
  trim: undefined,
  maxAskingPrice: DefaultInventoryPriceCriteria.maxAskingPrice,
  maxLeasePrice: DefaultInventoryPriceCriteria.maxLeasePrice,
  minAskingPrice: DefaultInventoryPriceCriteria.minAskingPrice,
  minLeasePrice: DefaultInventoryPriceCriteria.minLeasePrice,
  interiorColor: '',
  exteriorColor: '',
  showUnsold: false,
  showAll: false,
  showSpecialsSpiffs: false,
  showCpoOnly: false,
  showServiceLoaners: false,
  dealerIds: [-1],
  activePricing: 'Asking Price',
  get validMakeIds () { return this.make ? [this.make.id] : [-1] },
  get validModels () { return this.model ? [this.model.name] : [] },
  get validYears () { return this.productionYear ? [this.productionYear] : [] },
  get validTrims () { return this.trim ? [this.trim] : [] },
  get validMaxAskingPrice () { return this.maxAskingPrice && this.maxAskingPrice !== DefaultInventoryPriceCriteria.maxAskingPrice ? this.maxAskingPrice : undefined },
  get validMaxLeasePrice () { return this.maxLeasePrice && this.maxLeasePrice !== DefaultInventoryPriceCriteria.maxLeasePrice ? this.maxLeasePrice : undefined },
  get validMinAskingPrice () { return this.minAskingPrice && this.minAskingPrice !== DefaultInventoryPriceCriteria.minAskingPrice ? this.minAskingPrice : undefined },
  get validMinLeasePrice () { return this.minLeasePrice && this.minLeasePrice !== DefaultInventoryPriceCriteria.minLeasePrice ? this.minLeasePrice : undefined },
  get validDealerIds () { return this.dealerIds.length > 1 ? this.dealerIds.filter(dealerId => dealerId !== -1) : (this.dealerIds.length > 0 ? this.dealerIds : [-1]) },
  toggleActivePricing: function () { this.activePricing = this.activePricing === 'Lease Price' ? 'Asking Price' : 'Lease Price' }
}

const TemplateDefaultInventorySearchCriteria = {
  accessoryStatus: undefined, // []
  agingIndex: undefined, // 0
  dealerId: undefined, // 0
  dealerIds: [-1],
  dealStatusType: undefined, // ''
  defaultSearchView: undefined, // 0
  documentServiceEntry: undefined, // false
  endDate: undefined, // null
  exteriorColors: undefined, // DefaultExteriorColors,
  fullVin: true,
  interiorColors: undefined, // DefaultInteriorColors,
  isPricingView: undefined, // false
  isSelector: undefined, // false
  leadStatus: undefined, // ''
  loanedOutStatus: undefined, // []
  loanerTypeIds: undefined, // []
  locationIds: undefined, // []
  locationStatus: undefined, // ''
  makeIds: [-1],
  maxAskingPrice: undefined, // 0.00
  maxLeasePrice: undefined, // 0.00
  minAskingPrice: undefined, // 0.00
  minLeasePrice: undefined, // 0.00
  modelNames: undefined, // []
  models: [],
  offerStatus: undefined, // ''
  originalTitleStatus: undefined, // ''
  page: 0,
  pageSize: 250,
  payoffUploadStatus: undefined, // []
  photoUploadStatus: undefined, // []
  possessionStatus: undefined, // []
  possessionTypeId: [0, 3, 4],
  publishStatus: undefined, // ''
  purchasePaperworkStatus: undefined, // []
  query: '',
  requiresStatistics: undefined, // false
  saleType: undefined, // []
  serviceLoanerStatus: undefined, // ''
  showCancelledOnly: false,
  showCdkIssues: undefined, // false
  showCpoOnly: false,
  showCustomVehicleOnly: undefined, // false
  showDamaged: false,
  showDemo: undefined, // false
  showDispositionChanged: undefined, // false
  showDispositionExpired: undefined, // false
  showFleet: undefined, // false
  showGoneButAccessoriesRetained: undefined, // false
  showInboundOnly: undefined, // false
  showInStockOnly: false,
  showLoaners: undefined, // false
  showOnSpecial: false,
  showPpa: undefined, // false
  showPreEntry: false,
  showPreEntryPpa: undefined, // false
  showPriorityNotes: undefined, // false
  showSellersAgreementUploadedOnly: undefined, // false
  showServiceLoaners: false,
  showSold: undefined, // false
  showSoldOnly: undefined, // false
  showSpiff: false,
  showUnGrouped: undefined, // false
  showUnPublished: undefined, // false
  soldStatus: undefined, // []
  sortBy: 'name.asc',
  startDate: undefined, // null
  status: undefined, // []
  styles: [],
  subModelNames: undefined, // []
  subscriberId: 0,
  titleUploadStatus: undefined, // []
  trims: [],
  typeId: undefined, // [enums.InventoryTypeEnum.All],
  vehicleType: undefined, // ''
  years: []
}

const state = {
  criteria: undefined,
  searchCriteria: undefined
}

const getters = {
  criteria: state => state.criteria,
  searchCriteria: state => state.searchCriteria
}

const mutations = {
  [InternalInventoriesCriteriaMutationInterface.Reset]: function (state) {
    state.criteria = undefined
    state.searchCriteria = undefined
  },
  [InternalInventoriesCriteriaMutationInterface.Criteria]: function (state, payload) {
    if (typeof payload !== 'object') return
    state.criteria = { ...payload }
  },
  [InternalInventoriesCriteriaMutationInterface.SearchCriteria]: function (state, payload) {
    if (typeof payload !== 'object') return
    state.searchCriteria = { ...payload }
  }
}

const actions = {
  reset: function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    commit(InternalInventoriesCriteriaMutationInterface.Reset)
  },
  setCriteria: function ({ state, rootState, commit, dispatch, getters, rootGetters }, filterCriteria) {
    if (typeof filterCriteria !== 'object') return

    commit(InternalInventoriesCriteriaMutationInterface.Criteria, filterCriteria)
    const inventorySearchCriteria = JSON.parse(JSON.stringify(DefaultInventorySearchCriteria))

    if (filterCriteria.type === InventoryTypeEnum.Used) {
      inventorySearchCriteria.typeId = [InventoryTypeEnum.Purchased, InventoryTypeEnum.Consigned, InventoryTypeEnum.TradeIn]
    } else {
      inventorySearchCriteria.typeId = [filterCriteria.type]
    }
    inventorySearchCriteria.query = filterCriteria.queryText
    inventorySearchCriteria.showInStockOnly = filterCriteria.showUnsold || !filterCriteria.showAll
    inventorySearchCriteria.showOnSpecial = filterCriteria.showSpecialsSpiffs
    inventorySearchCriteria.showSpiff = filterCriteria.showSpecialsSpiffs
    inventorySearchCriteria.makeIds = filterCriteria.validMakeIds
    inventorySearchCriteria.models = filterCriteria.validModels
    inventorySearchCriteria.years = filterCriteria.validYears
    inventorySearchCriteria.trims = filterCriteria.validTrims
    inventorySearchCriteria.minAskingPrice = filterCriteria.validMinAskingPrice
    inventorySearchCriteria.maxAskingPrice = filterCriteria.validMaxAskingPrice
    inventorySearchCriteria.minLeasePrice = filterCriteria.validMinLeasePrice
    inventorySearchCriteria.maxLeasePrice = filterCriteria.validMaxLeasePrice
    inventorySearchCriteria.interiorColors = filterCriteria.interiorColor
    inventorySearchCriteria.exteriorColors = filterCriteria.exteriorColor
    inventorySearchCriteria.showServiceLoaners = filterCriteria.showServiceLoaners
    inventorySearchCriteria.showCpoOnly = filterCriteria.showCpoOnly
    inventorySearchCriteria.subscriberId = rootGetters[SubscriberGetterInterface.sqlId] // this.coreProfileUser.subscriberId
    inventorySearchCriteria.dealerIds = filterCriteria.validDealerIds

    commit(InternalInventoriesCriteriaMutationInterface.SearchCriteria, inventorySearchCriteria)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
