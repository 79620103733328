import Vue from 'vue'
import dealersProfileApiService from '../../services/dealersProfileApiService.js'
import { InternalDealersMutationInterface } from '../storeInterfaces'

const state = {
  coreDealers: [],
  selectedCoreDealerIds: []
}

const getters = {
  isInitialized: state => Array.isArray(state.coreDealers) && state.coreDealers.length > 0,
  coreDealers: state => state.coreDealers,
  dealerCodes: state => state.coreDealers.filter(dealer => dealer.id !== 2).map(dealer => dealer.code),
  selectedDealers: state => state.coreDealers.filter(dealer => state.selectedCoreDealerIds.some(dealerId => dealerId === dealer.id)),
  selectedDealerCodes: state => state.coreDealers.filter(dealer => state.selectedCoreDealerIds.some(dealerId => dealerId === dealer.id)).map(dealer => dealer.code),
  isAllDealersSelected: state => state.selectedCoreDealerIds.length === 0
}

const mutations = {
  [InternalDealersMutationInterface.Reset] (state, dealers) {
    if (state.coreDealers.length === 0 && state.selectedCoreDealerIds.length === 0) return
    state.coreDealers = []
    state.selectedCoreDealerIds = []
  },
  [InternalDealersMutationInterface.CoreDealers] (state, dealers) {
    if (!Array.isArray(dealers)) return
    state.coreDealers = dealers.length > 0 ? dealers : []
  },
  [InternalDealersMutationInterface.SelectedDealers] (state, selectedCoreDealers) {
    if (!Array.isArray(selectedCoreDealers)) return
    state.selectedCoreDealerIds = selectedCoreDealers.length > 0 ? selectedCoreDealers.map(dealer => dealer.id) : []
  }
}

const actions = {
  reset: function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    commit(InternalDealersMutationInterface.Reset)
  },
  loadCoreDealersAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    try {
      // Vue.$log.debug('[DEV]: store.dealers.loadCoreDealersAsync()')
      const response = await dealersProfileApiService.loadCoreDealersAsync()
      commit(InternalDealersMutationInterface.CoreDealers, response)
    } catch (error) {
      Vue.$log.debug('[DEV]: store.dealers.loadCoreDealersAsync() >> ERROR', error)
      commit(InternalDealersMutationInterface.CoreDealers, [])
    }
  },
  selectAllDealers ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    commit(InternalDealersMutationInterface.SelectedDealers, [])
  },
  selectDealers ({ state, rootState, commit, dispatch, getters, rootGetters }, dealers) {
    if (!Array.isArray(dealers)) return
    commit(InternalDealersMutationInterface.SelectedDealers, dealers)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
