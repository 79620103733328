import { format, formatDistanceToNow } from 'date-fns'
// import { VueMaskDirective } from 'v-mask'

function documentPositionComparator (a, b) {
  if (a === b) {
    return 0
  }
  const position = a.compareDocumentPosition(b)
  if (position & Node.DOCUMENT_POSITION_FOLLOWING || position & Node.DOCUMENT_POSITION_CONTAINED_BY) {
    return -1
  } else if (position & Node.DOCUMENT_POSITION_PRECEDING || position & Node.DOCUMENT_POSITION_CONTAINS) {
    return 1
  } else {
    return 0
  }
}

function sortByDocumentAppearence (ids) {
  const elementArray = ids.map((x) => document.querySelector('[data-vv-id="' + x + '"]'))
  return elementArray.sort(documentPositionComparator)
}

function addPathSupport () {
  if (!('path' in Event.prototype)) {
    Object.defineProperty(Event.prototype, 'path', {
      get: function () {
        const path = []
        let currentElem = this.target
        while (currentElem) {
          path.push(currentElem)
          currentElem = currentElem.parentElement
        }
        if (path.indexOf(window) === -1 && path.indexOf(document) === -1) { path.push(document) }
        if (path.indexOf(window) === -1) { path.push(window) }
        return path
      }
    })
  }
}
function addClosestSupport () {
  if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector
  }

  if (!Element.prototype.closest) {
    Element.prototype.closest = function (s) {
      let el = this
      if (!document.documentElement.contains(el)) return null
      do {
        if (el.matches(s)) return el
        el = el.parentElement || el.parentNode
      } while (el !== null && el.nodeType === 1)
      return null
    }
  }
}

function byClassName (el, query) {
  if (el && el.className) {
    return el.className !== '' && el.className.indexOf(query) >= 0
  }
  return undefined
}

function closestAncestor (el, fn, query) {
  return el && (fn(el, query) ? el : closestAncestor(el.parentNode, fn, query))
}

let scrollTimer = null

function scrollHandler () {
  if (scrollTimer !== null) {
    clearTimeout(scrollTimer)
  }
  scrollTimer = setTimeout(function () {
    const mobileSorter = document.getElementsByClassName('table-mobile-sort')[0]
    const isMobileMode = mobileSorter && getComputedStyle(mobileSorter).display !== 'none'
    if (isMobileMode) {
      return
    }
    const headerElements = document.getElementsByTagName('THEAD')
    const bodyElements = document.getElementsByTagName('TBODY')
    if (headerElements.length > 0 && bodyElements.length > 0) {
      const table = document.getElementsByClassName('table')[0]
      const distanceToTop = table.getBoundingClientRect().top
      const header = headerElements[0]
      const body = bodyElements[0]
      const isAlreadyAdded = header.classList.contains('sticky') || header.classList.contains('fc-head')
      const headerRow = header.firstChild
      const dataRow = body.querySelector('tr:not(.group-row):not(.inventory-group-row):not(.deal-group-row)')
      if (table && header && dataRow) {
        const threshold = header.getBoundingClientRect().height + 50
        if (distanceToTop <= threshold) {
          if (!isAlreadyAdded) {
            const children = headerRow.children
            for (let i = 0; i < children.length; i++) {
              const clonedChild = header.firstChild.children[i]
              const clonedDataChild = dataRow.children[i]
              if (clonedChild && clonedChild.nodeType === 1) {
                clonedChild.style.width = children[i].clientWidth + 1 + 'px'
              }
              if (clonedDataChild && clonedDataChild.nodeType === 1) {
                clonedDataChild.style.width = children[i].clientWidth + 1 + 'px'
              }
            }
            header.classList.add('sticky')
          }
        } else {
          const existingStickyItems = document.getElementsByClassName('sticky')
          const existingHeader = existingStickyItems.length > 0 ? existingStickyItems[0] : undefined
          if (existingHeader) {
            existingHeader.classList.remove('sticky')
          }
        }
      }
    }
  }, 100)
}

function globalInit (Vue) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })

  Vue.prototype.$isAppDeployed = !window.location.origin.includes('localhost')
  Vue.prototype.$isAppStagingDeployed = window.location.origin.includes('devcrm.livesaleslog.com')
  Vue.prototype.$isAppProductionDeployed = window.location.origin.includes('crm.livesaleslog.com')
  Vue.prototype.$supportsGlobalEvent = 'event' in window
  Vue.prototype.$handleScroll = scrollHandler
  Vue.prototype.$globalMoneyFormat = {
    decimal: '.',
    thousands: ',',
    prefix: '',
    suffix: '',
    precision: 2,
    masked: false,
    allowBlank: true
  }
  Vue.prototype.$defaultScrollIntoViewOptions = {
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest'
  }

  Vue.prototype.$goToValidationError = function (el) {
    const offensiveElement = this.$el.querySelector('[data-vv-id="' + el + '"]')
    const closest = closestAncestor(offensiveElement, byClassName, 'field')
    if (closest) {
      closest.scrollIntoView(this.$defaultScrollIntoViewOptions)
    } else {
      offensiveElement.parentElement.scrollIntoView(this.$defaultScrollIntoViewOptions)
    }
  }
  Vue.prototype.$goToFirstValidationError = function (errors) {
    const sorted = sortByDocumentAppearence(errors.filter((x) => x.id).map((x) => x.id))
    const el = sorted[0].getAttribute('data-vv-id')
    let offensiveElement = this.$el.querySelector('[data-vv-id="' + el + '"]')
    if (!offensiveElement) {
      offensiveElement = document.querySelector('[data-vv-id="' + el + '"]')
    }
    const closest = closestAncestor(offensiveElement, byClassName, 'field')
    if (closest) {
      closest.scrollIntoView(this.$defaultScrollIntoViewOptions)
    } else {
      if (offensiveElement && offensiveElement.parentElement) {
        offensiveElement.parentElement.scrollIntoView(this.$defaultScrollIntoViewOptions)
      }
    }
  }
  Vue.prototype.$goToErrorBlock = function (el) {
    if (el) {
      el.scrollIntoView(this.$defaultScrollIntoViewOptions)
    }
  }
  Vue.prototype.$goToBlock = function (el, scrollOptions) {
    if (el) {
      el.scrollIntoView(scrollOptions || this.$defaultScrollIntoViewOptions)
    }
  }
  Vue.prototype.$markDirty = function () {
    if (this.$el) {
      const form = this.$el.closest('form')
      if (form) {
        const event = new Event('change')
        form.dispatchEvent(event)
      }
    }
  }
  Vue.prototype.$toTitleCase = function (str) {
    return str.toLowerCase().split(' ').map(function (word) {
      if (word.length > 0) {
        if (word.length === 1) {
          return word
        } else {
          return word.replace(word[0], word[0].toUpperCase())
        }
      } else {
        return ''
      }
    }).join(' ')
  }
  Vue.prototype.$formatCurrency = function (value) {
    if (value && value !== '') {
      return formatter.format(value)
    }
    return '-.--'
  }

  Vue.filter('number', function (value) {
    return new Intl.NumberFormat('en-US', {
      style: 'decimal'
    }).format(value)
  })
  Vue.filter('striphtml', function (value) {
    if (value) {
      const div = document.createElement('div')
      div.innerHTML = value.replace('</p>', ' </p>')
      const text = div.textContent || div.innerHTML || ''
      return text.substring(0, 40)
    } else {
      return ''
    }
  })
  Vue.filter('formatDate', function (value) {
    // if (value) {
    //   if (moment.defaultZone && moment.defaultZone.name) {
    //     return moment.utc(String(value)).tz(moment.defaultZone.name).format('MM/DD/YYYY h:mm a')
    //   } else {
    //     return moment.utc(String(value)).format('MM/DD/YYYY h:mm a')
    //   }
    // }
    if (value) {
      return format(new Date(value), 'MM/dd/yyyy h:mm aaa')
    }
  })
  Vue.filter('formatDateOnly', function (value) {
    // if (value) {
    //   if (moment.defaultZone && moment.defaultZone.name) {
    //     return moment.utc(String(value)).tz(moment.defaultZone.name).format('MM/DD/YYYY')
    //   } else {
    //     return moment.utc(String(value)).format('MM/DD/YYYY')
    //   }
    // }
    if (value) {
      return format(new Date(value), 'MM/dd/yyyy')
    }
  })
  Vue.filter('formatDateForAt', function (value) {
    // if (value) {
    //   if (moment.defaultZone && moment.defaultZone.name) {
    //     return moment.utc(String(value)).tz(moment.defaultZone.name).local().format('DD MMMM YYYY @ HH:mm')
    //   } else {
    //     return moment.utc(String(value)).local().format('DD MMMM YYYY @ HH:mm')
    //   }
    // }
    if (value) {
      return format(new Date(value), 'MM/dd/yyyy @ HH:mm')
    }
  })
  Vue.filter('formatHumanDate', function (value) {
    // if (value) {
    //   if (moment.defaultZone && moment.defaultZone.name) {
    //     return moment.utc(String(value)).tz(moment.defaultZone.name).fromNow()
    //   } else {
    //     return moment.utc(String(value)).fromNow()
    //   }
    // }
    if (value) {
      return formatDistanceToNow(new Date(value))
    }
  })
  Vue.filter('formatDateIgnoreZone', function (value) {
    // if (value) {
    //   return moment.utc(String(value)).format('MM/DD/YYYY h:mm a')
    // }
    if (value) {
      return format(new Date(value), 'MM/dd/yyyy h:mm aaa')
    }
  })
  Vue.filter('formatDateOnlyIgnoreZone', function (value) {
    // if (value) {
    //   return moment.utc(String(value)).format('MM/DD/YYYY')
    // }
    if (value) {
      return format(new Date(value), 'MM/dd/yyyy')
    }
  })

  Vue.directive('focus', {
    inserted: function (el) {
      el.focus()
    }
  })
  // Vue.directive('mask', VueMaskDirective)

  Vue.component('b-field-addon', {
    template: '<div class="control"><slot/></div>'
  })
  Vue.component('hyperlink', {
    template: '<a style="color:inherit;"><slot/></a>'
  })
  Vue.component('dynamic-html', {
    props: ['html'],
    computed: {
      template () {
        if (this.html) { return Vue.compile(this.html).render }
        return null
      }
    },
    render () {
      if (this.template) { return this.template() }
      return null
    }
  })

  addClosestSupport()
  addPathSupport()
}

export default globalInit
