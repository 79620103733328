import Vue from 'vue'
import Quill from 'quill/dist/quill'
import {
  DraftMessageTypeEnum
} from '@/enums'
import {
  InternalProcessMessagesMutationInterface,
  ProcessMessagesActionInterface,
  UsersGetterInterface
} from '../storeInterfaces'
import rtApiService from '../../services/rtApiService'

const htmlRegEx = /<img\s|<span\s/

const state = {
  draftMessages: new Map(), // {}
  draftMessage: undefined
}

const getters = {
  hasMessages: state => state.draftMessages.size > 0,
  messages: state => state.draftMessages,
  hasLeadMessages: (state, getters, rootState, rootGetters) => (leadId) => {
    return state.draftMessages.size > 0 && typeof leadId === 'string' && leadId.length === 24 && [...state.draftMessages].some(([key, value]) => key.includes(leadId))
  },
  leadId: state => state.draftMessage ? state.draftMessage.leadId : '',
  hasLeadTypeMessage: (state, getters, rootState, rootGetters) => (leadId, messageType) => {
    if (state.draftMessages.size === 0 || typeof leadId !== 'string' || leadId.length !== 24 || typeof messageType !== 'number') return false
    const draftMessageKey = `${leadId}-${DraftMessageTypeEnum.keyOf(messageType)}`
    return state.draftMessages.has(draftMessageKey)
  },
  hasMessage: state => !!state.draftMessage,
  isDelaerPeakMessage: state => !!state.draftMessage && state.draftMessage.messageType === DraftMessageTypeEnum.Note && state.draftMessage.isDealerPeakNote,
  message: state => state.draftMessage,
  messageType: state => state.draftMessage.messageType,
  subject: state => state.draftMessage.subject,
  content: state => state.draftMessage.content,
  quillContent: state => state.draftMessage.quillContent,
  messageKey: (state, getters, rootState, rootGetters) => (leadId, messageType) => `${leadId}-${DraftMessageTypeEnum.keyOf(messageType)}`,
  isMessageValid: (state, getters, rootState, rootGetters) => (leadId, messageType) => {
    if (state.draftMessages.size === 0 || typeof leadId !== 'string' || leadId.length !== 24 || typeof messageType !== 'number') return false
    const draftMessageKey = `${leadId}-${DraftMessageTypeEnum.keyOf(messageType)}`
    if (!state.draftMessages.has(draftMessageKey)) return false
    const draftMessage = state.draftMessages.get(draftMessageKey)
    if ((!draftMessage.hasText && !htmlRegEx.test(draftMessage.content)) || (messageType === DraftMessageTypeEnum.Email && draftMessage.subject.trim().length === 0) || !state.draftMessage.attachments.some(attachment => attachment.isPublic && !attachment.isDeleted)) return false
    return true
  },
  hasAttachments: state => state.draftMessage.attachments.some(attachment => attachment.isPublic && !attachment.isDeleted),
  attachments: state => state.draftMessage.attachments,
  hasMentions: state => state.draftMessage.mentionedUserIds.length > 0,
  mentionedUserIds: state => state.draftMessage.mentionedUserIds,
  mentionedTeamIds: state => state.draftMessage.mentionedTeamIds,
  onBehalfOf: state => state.draftMessage.onBehalfOf
}

const mutations = {
  [InternalProcessMessagesMutationInterface.Reset]: function (state) {
    state.draftMessages = new Map()
    state.draftMessage = undefined
  },
  [InternalProcessMessagesMutationInterface.SetMessages]: function (state, draftMessages) {
    // Vue.$log.debug(`[DEV]: ${'processMessages'} >> SetMessages`, typeof draftMessages)
    if (typeof draftMessages !== 'object') return
    state.draftMessages = new Map(draftMessages)
  },
  [InternalProcessMessagesMutationInterface.SetMessage]: function (state, draftMessage) {
    // Vue.$log.debug(`[DEV]: ${'processMessages'} >> SetMessage`, typeof draftMessage)
    if (typeof draftMessage !== 'object') return
    state.draftMessage = { ...draftMessage }
  },
  [InternalProcessMessagesMutationInterface.SetAttachments]: function (state, attachments) {
    if (!state.draftMessage || !Array.isArray(attachments)) return
    const draftMessage = { ...state.draftMessage }
    draftMessage.attachments = [...attachments]
    state.draftMessage = draftMessage
    const draftMessageKey = `${state.draftMessage.leadId}-${DraftMessageTypeEnum.keyOf(state.draftMessage.messageType)}`
    const draftMessages = new Map(state.draftMessages)
    draftMessages.set(draftMessageKey, state.draftMessage)
    state.draftMessages = new Map(draftMessages)
  },
  [InternalProcessMessagesMutationInterface.SetMentionedUserIds]: function (state, mentionedUserIds) {
    if (!state.draftMessage || !Array.isArray(mentionedUserIds)) return
    const draftMessage = { ...state.draftMessage }
    draftMessage.mentionedUserIds = [...mentionedUserIds]
    state.draftMessage = draftMessage
    const draftMessageKey = `${state.draftMessage.leadId}-${DraftMessageTypeEnum.keyOf(state.draftMessage.messageType)}`
    const draftMessages = new Map(state.draftMessages)
    draftMessages.set(draftMessageKey, state.draftMessage)
    state.draftMessages = new Map(draftMessages)
  },
  [InternalProcessMessagesMutationInterface.SetMentionedTeamIds]: function (state, mentionedTeamIds) {
    if (!state.draftMessage || !Array.isArray(mentionedTeamIds)) return
    const draftMessage = { ...state.draftMessage }
    draftMessage.mentionedTeamIds = [...mentionedTeamIds]
    state.draftMessage = draftMessage
    const draftMessageKey = `${state.draftMessage.leadId}-${DraftMessageTypeEnum.keyOf(state.draftMessage.messageType)}`
    const draftMessages = new Map(state.draftMessages)
    draftMessages.set(draftMessageKey, state.draftMessage)
    state.draftMessages = new Map(draftMessages)
  }
}

const actions = {
  reset: function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    commit(InternalProcessMessagesMutationInterface.Reset)
  },
  selectMessage: function ({ state, rootState, commit, dispatch, getters, rootGetters }, { leadId, messageType }) {
    if (typeof leadId !== 'string' || leadId.length !== 24 || typeof messageType !== 'number') return
    // const draftMessageKey = `${leadId}-${DraftMessageTypeEnum.keyOf(messageType)}`
    const draftMessageKey = getters.messageKey(leadId, messageType)
    // Vue.$log.debug(`[DEV]: ${'processMessages'}.selectMessage({ leadId, messageType }) >> draftMessageKey`, leadId, messageType, getters.hasLeadMessages(leadId), getters.messages.has(draftMessageKey))
    if (!getters.hasLeadMessages(leadId) || !getters.messages.has(draftMessageKey)) {
      const draftMessages = new Map(getters.messages)
      const newDaftMessage = {
        leadId,
        onBehalfOf: rootGetters[UsersGetterInterface.userReference](rootGetters[UsersGetterInterface.profileUser].id),
        messageType, // DraftMessageTypeEnum
        subject: '',
        content: '',
        quillContent: [],
        hasText: false,
        isDealerPeakNote: false,
        attachments: [],
        mentionedUserIds: [],
        mentionedTeamIds: []
      }
      draftMessages.set(draftMessageKey, newDaftMessage)
      commit(InternalProcessMessagesMutationInterface.SetMessages, draftMessages)
      // Vue.$log.debug(`[DEV]: ${'processMessages'}.selectMessage(leadId) >> ADDED`, draftMessageKey, newDaftMessage)
    }
    commit(InternalProcessMessagesMutationInterface.SetMessage, getters.messages.get(draftMessageKey))
    // Vue.$log.debug(`[DEV]: ${'processMessages'}.selectMessage(leadId) >> SELECTED`, getters.messageType)
  },
  setDealerPeakMessageType: function ({ state, rootState, commit, dispatch, getters, rootGetters }, { leadId, messageType, isDealerPeakNote }) {
    if (typeof leadId !== 'string' || leadId.length !== 24 || !getters.hasLeadMessages(leadId) || typeof messageType !== 'number' || messageType !== DraftMessageTypeEnum.Note) return
    const draftMessageKey = getters.messageKey(leadId, messageType)
    const draftMessage = getters.messages.get(draftMessageKey)
    draftMessage.isDealerPeakNote = isDealerPeakNote
    const draftMessages = new Map(getters.messages)
    draftMessages.set(draftMessageKey, draftMessage)
    commit(InternalProcessMessagesMutationInterface.SetMessages, draftMessages)
    commit(InternalProcessMessagesMutationInterface.SetMessage, draftMessage)
  },
  removeLeadMessages: function ({ state, rootState, commit, dispatch, getters, rootGetters }, leadId) {
    if (typeof leadId !== 'string' || leadId.length !== 24 || !getters.hasLeadMessages(leadId)) return
    const draftMessages = new Map([...getters.messages].filter(([key, value]) => !key.includes(leadId)))
    commit(InternalProcessMessagesMutationInterface.SetMessages, draftMessages)
  },
  removeLeadMessage: function ({ state, rootState, commit, dispatch, getters, rootGetters }, { leadId, messageType }) {
    if (typeof leadId !== 'string' || leadId.length !== 24 || !getters.hasLeadMessages(leadId) || typeof messageType !== 'number' || messageType <= DraftMessageTypeEnum.NotSet) return
    // const draftMessages = new Map([...getters.messages].filter(([key, value]) => !key.includes(leadId)))
    const draftMessageKey = getters.messageKey(leadId, messageType)
    const draftMessages = new Map(getters.messages)
    draftMessages.delete(draftMessageKey)
    commit(InternalProcessMessagesMutationInterface.SetMessages, draftMessages)
  },
  resetLeadMessage: function ({ state, rootState, commit, dispatch, getters, rootGetters }, { leadId, messageType }) {
    // Vue.$log.debug(`[DEV]: ${'processMessages'}.resetLeadMessage({ leadId, messageType })`, leadId, messageType, getters.messages.size > 0 ? [...getters.messages] : [])
    if (typeof leadId !== 'string' || leadId.length !== 24 || !getters.hasLeadMessages(leadId) || typeof messageType !== 'number' || messageType <= DraftMessageTypeEnum.NotSet) return
    if (getters.messages.size > 0) {
      const draftMessages = new Map([...getters.messages])
      const draftMessageKey = getters.messageKey(leadId, messageType)
      // Vue.$log.debug(`[DEV]: ${'processMessages'}.resetLeadMessage({ leadId, messageType }) >> BEFORE: draftMessages.has(draftMessageKey)`, draftMessageKey, draftMessages.has(draftMessageKey))
      draftMessages.delete(draftMessageKey)
      // Vue.$log.debug(`[DEV]: ${'processMessages'}.resetLeadMessage({ leadId, messageType }) >> AFTER: draftMessages.has(draftMessageKey)`, draftMessageKey, draftMessages.has(draftMessageKey))
      commit(InternalProcessMessagesMutationInterface.SetMessages, draftMessages)
    }
    dispatch(ProcessMessagesActionInterface.selectMessage, { leadId, messageType }, { root: true })
  },
  setOnBehalfOf: function ({ state, rootState, commit, dispatch, getters, rootGetters }, userRef) {
    if (!getters.hasMessage || typeof userRef !== 'object') return
    const draftMessage = { ...getters.message }
    draftMessage.onBehalfOf = userRef
    commit(InternalProcessMessagesMutationInterface.SetMessage, draftMessage)
    const draftMessageKey = `${draftMessage.leadId}-${DraftMessageTypeEnum.keyOf(draftMessage.messageType)}`
    const draftMessages = new Map(state.draftMessages)
    draftMessages.set(draftMessageKey, state.draftMessage)
    commit(InternalProcessMessagesMutationInterface.SetMessages, draftMessages)
  },
  setSubject: function ({ state, rootState, commit, dispatch, getters, rootGetters }, subject) {
    if (!getters.hasMessage || typeof subject !== 'string' || getters.messageType !== DraftMessageTypeEnum.Email) return
    const draftMessage = { ...getters.message }
    draftMessage.subject = subject
    commit(InternalProcessMessagesMutationInterface.SetMessage, draftMessage)
    const draftMessageKey = `${draftMessage.leadId}-${DraftMessageTypeEnum.keyOf(draftMessage.messageType)}`
    const draftMessages = new Map(state.draftMessages)
    draftMessages.set(draftMessageKey, draftMessage)
    commit(InternalProcessMessagesMutationInterface.SetMessages, draftMessages)
  },
  // setContent: function ({ state, rootState, commit, dispatch, getters, rootGetters }, content) {
  //   if (typeof content !== 'string' || !getters.hasMessage) return
  //   const draftMessage = { ...getters.message }
  //   draftMessage.content = content
  //   commit(InternalProcessMessagesMutationInterface.SetMessage, draftMessage)
  //   const draftMessageKey = `${draftMessage.leadId}-${DraftMessageTypeEnum.keyOf(draftMessage.messageType)}`
  //   const draftMessages = new Map(state.draftMessages)
  //   draftMessages.set(draftMessageKey, state.draftMessage)
  //   commit(InternalProcessMessagesMutationInterface.SetMessages, draftMessages)
  // },
  setDraftContent: function ({ state, rootState, commit, dispatch, getters, rootGetters }, quill) {
    if (!getters.hasMessage || !(quill instanceof Quill)) return
    const draftMessage = { ...getters.message }
    draftMessage.quillContent = quill.getContents()
    draftMessage.content = quill.root.innerHTML
    draftMessage.hasText = quill.getText().trim().length > 0
    commit(InternalProcessMessagesMutationInterface.SetMessage, draftMessage)
    const draftMessageKey = `${draftMessage.leadId}-${DraftMessageTypeEnum.keyOf(draftMessage.messageType)}`
    const draftMessages = new Map(state.draftMessages)
    draftMessages.set(draftMessageKey, state.draftMessage)
    commit(InternalProcessMessagesMutationInterface.SetMessages, draftMessages)
  },
  processMessageContent: function ({ state, rootState, commit, dispatch, getters, rootGetters }, quill) {
    // Vue.$log.debug(`[DEV]: ${'processMessages'} >> processMessageContent(quill)`, quill.getText().trim().length === 0, !htmlRegEx.test(quill.root.innerHTML), quill.root.innerHTML)
    if (!getters.hasMessage || !(quill instanceof Quill) || (quill.getText().trim().length === 0 && !htmlRegEx.test(quill.root.innerHTML))) return

    // Sanitize content
    // const htmlContent = quill.root.innerHTML.replace(/ contenteditable=".*"|\ufeff/g, '')
    const htmlContent = quill.root.innerHTML.replace(/ contenteditable=".*"/g, '')

    // User Mentions
    const userMentionedRegEx = /<span class="mention" data-index="\d+" data-denotation-char="." data-id="U-(\w+)" data-value=".+">/g
    if (userMentionedRegEx.test(htmlContent)) {
      userMentionedRegEx.lastIndex = 0
      // const resultRegExArray = [...htmlContent.matchAll(userMentionedRegEx)]
      // Vue.$log.debug(`[DEV]: ${'processMessages'} >> processMessageContent(quill)`, resultRegExArray)
      let resultRegEx
      while ((resultRegEx = userMentionedRegEx.exec(htmlContent)) !== null) {
        if (resultRegEx.index === userMentionedRegEx.lastIndex) userMentionedRegEx.lastIndex++
        // Vue.$log.debug(`[DEV]: ${'processMessages'} >> processMessageContent(quill)`, resultRegEx)
        resultRegEx.forEach((match, groupIndex) => {
          if (groupIndex === 1) {
            dispatch(ProcessMessagesActionInterface.addMentionedUserId, match, { root: true })
          }
        })
      }
    }
    // Vue.$log.debug(`[DEV]: ${'processMessages'} >> processMessageContent(quill)`, htmlContent, userMentionedRegEx.test(htmlContent), getters.mentionedUserIds)

    // Team Mentions
    // const userTeamRegEx = /data-mentioned-team-id="(.*)"/g
    // if (userTeamRegEx.test(quill.root.innerHTML)) {
    //   let resultRegEx
    //   while ((resultRegEx = userTeamRegEx.exec(htmlContent)) !== null) {
    //     if (resultRegEx.index === userTeamRegEx.lastIndex) userTeamRegEx.lastIndex++
    //     dispatch(ProcessMessagesActionInterface.addMentionedTeamId, resultRegEx[1], { root: true })
    //   }
    // }

    // TODO: URL Links, Validate URL Media Link - /\.(jpg|png|jpeg|gif|jfif|bmp|tif|tiff|mp4|webm|mpg|mov|wmv|avi|mkv|flv|blob)(\?|\/|$)/g
    const urlLinks = [...new Set(htmlContent.match(/(?<!href=")(((http|https):\/\/(?:www\.|(?!www))\w[\w-:]+\w\.[^\s<)"']{2,})|((http|https):\/\/(?:www\.|(?!www))\w+\.[^\s]{2,})|www\.\w+\.[^\s<)"']{2,}|((http|https):\/\/localhost:\d{2,5}[^\s<)"']{2,})|(www\.\w[\w-]+\w\.[^\s<)"']{2,}))/g))]
    // TODO: Email Addresses
    const emailAddresses = [...new Set(htmlContent.match(/([\w._-]+@[\w._-]+\.[\w._-]+)/g))]
    // TODO: Phone Numbers
    const phoneNumbers = [...new Set(htmlContent.match(/(\(?\d{3}\)?[-\s.]\d{3}[-\s.]\d{4})(?!\w)/g))]
    // TODO: VIN Numbers
    const vinNumbers = [...new Set(htmlContent.toUpperCase().match(/\b[A-Z\d]{17}\b/g))]

    const draftMessage = { ...getters.message }
    draftMessage.content = htmlContent
    draftMessage.quillContent = quill.getContents()
    draftMessage.hasText = quill.getText().trim().length > 0

    // Vue.$log.debug(`[DEV]: ${'processMessages'} >> processMessageContent(quill) >> draftMessage BEFORE`, draftMessage)
    commit(InternalProcessMessagesMutationInterface.SetMessage, draftMessage)
    // Vue.$log.debug(`[DEV]: ${'processMessages'} >> processMessageContent(quill) >> draftMessage AFTER`, getters.message)
    const draftMessageKey = `${draftMessage.leadId}-${DraftMessageTypeEnum.keyOf(draftMessage.messageType)}`
    const draftMessages = new Map(state.draftMessages)
    draftMessages.set(draftMessageKey, state.draftMessage)
    commit(InternalProcessMessagesMutationInterface.SetMessages, draftMessages)
  },
  addAttachment: function ({ state, rootState, commit, dispatch, getters, rootGetters }, attachment) {
    if (!getters.hasMessage || typeof attachment !== 'object' || getters.attachments.some(attachmentItem => attachmentItem.fileLocation === attachment.fileLocation)) return
    const attachments = [...getters.attachments]
    attachments.push(attachment)
    commit(InternalProcessMessagesMutationInterface.SetAttachments, attachments)
  },
  removeAttachmentAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }, attachment) {
    if (!getters.hasMessage || typeof attachment !== 'object' || !getters.attachments.some(attachmentItem => attachmentItem.fileLocation === attachment.fileLocation)) return
    const foundAttachment = getters.attachments.find(attachmentItem => attachmentItem.fileLocation === attachment.fileLocation)
    const response = await rtApiService.deleteMessageAttachmentAsync(foundAttachment)
    if (response) {
      const attachments = getters.attachments.filter(attachmentItem => attachmentItem.fileLocation !== foundAttachment.fileLocation)
      commit(InternalProcessMessagesMutationInterface.SetAttachments, [...attachments])
    }
  },
  markAttachmentAsDeleted: function ({ state, rootState, commit, dispatch, getters, rootGetters }, fileLocation) {
    if (!getters.hasMessage || typeof fileLocation !== 'string' || fileLocation.trim() === '' || !getters.attachments.some(attachmentItem => attachmentItem.fileLocation === fileLocation.trim())) return
    // Vue.$log.debug(`[DEV]: ${'processMessages'}.markAttachmentAsDeleted(fileLocation)`, fileLocation)
    const attachment = getters.attachments.find(attachmentItem => attachmentItem.fileLocation === fileLocation.trim())
    const attachments = getters.attachments.filter(attachmentItem => attachmentItem.fileLocation !== fileLocation.trim())
    attachment.isDeleted = true
    // Vue.$log.debug(`[DEV]: ${'processMessages'}.markAttachmentAsDeleted(fileLocation)`, attachment)
    commit(InternalProcessMessagesMutationInterface.SetAttachments, [...attachments, attachment])
  },
  addMentionedUserId: function ({ state, rootState, commit, dispatch, getters, rootGetters }, userId) {
    if (!getters.hasMessage || typeof userId !== 'string' || userId.trim().length !== 24 || (getters.mentionedUserIds.length > 0 && getters.mentionedUserIds.some(mentionId => mentionId === userId.trim()))) return
    const mentionIdSet = new Set([...getters.mentionedUserIds])
    mentionIdSet.add(userId.trim())
    Vue.$log.debug(`[DEV]: ${'processMessages'} >> addMentionedUserId(userId) >> BEFORE`, getters.message, userId, [...getters.mentionedUserIds], Array.from(mentionIdSet), getters.messages.get(getters.messageKey(getters.leadId, getters.messageType)))
    commit(InternalProcessMessagesMutationInterface.SetMentionedUserIds, Array.from(mentionIdSet))
    Vue.$log.debug(`[DEV]: ${'processMessages'} >> addMentionedUserId(userId) >> AFTER`, getters.message, [...getters.mentionedUserIds], getters.messages.get(getters.messageKey(getters.leadId, getters.messageType)))
  },
  removeMentionedUserId: function ({ state, rootState, commit, dispatch, getters, rootGetters }, userId) {
    if (!getters.hasMessage || typeof userId !== 'string' || userId.trim().length !== 24 || (getters.mentionedUserIds.length > 0 && !getters.mentionedUserIds.some(mentionId => mentionId === userId.trim()))) return
    const mentionedUserIds = getters.mentionedUserIds.filter(mentionedId => mentionedId !== userId.trim())
    commit(InternalProcessMessagesMutationInterface.SetMentionedUserIds, mentionedUserIds)
  },
  addMentionedTeamId: function ({ state, rootState, commit, dispatch, getters, rootGetters }, teamId) {
    if (!getters.hasMessage || typeof teamId !== 'string' || teamId.trim().length !== 24 || (getters.mentionedTeamIds.length > 0 && getters.mentionedTeamIds.some(mentionId => mentionId === teamId.trim()))) return
    const mentionIdSet = new Set([...getters.mentionedTeamIds])
    mentionIdSet.add(teamId.trim())
    // Vue.$log.debug(`[DEV]: ${'processMessages'} >> addMentionedTeamId(teamId) >> BEFORE`, getters.message, teamId, [...getters.mentionedTeamIds], Array.from(mentionIdSet), getters.messages.get(getters.messageKey(getters.leadId, getters.messageType)))
    commit(InternalProcessMessagesMutationInterface.SetMentionedTeamIds, Array.from(mentionIdSet))
    // Vue.$log.debug(`[DEV]: ${'processMessages'} >> addMentionedTeamId(teamId) >> AFTER`, getters.message, [...getters.mentionedTeamIds], getters.messages.get(getters.messageKey(getters.leadId, getters.messageType)))
  },
  removeMentionedTeamId: function ({ state, rootState, commit, dispatch, getters, rootGetters }, teamId) {
    if (!getters.hasMessage || typeof teamId !== 'string' || teamId.trim().length !== 24 || (getters.mentionedTeamIds.length > 0 && !getters.mentionedTeamIds.some(mentionId => mentionId === teamId.trim()))) return
    const mentionedTeamIds = getters.mentionedTeamIds.filter(mentionedId => mentionedId !== teamId.trim())
    commit(InternalProcessMessagesMutationInterface.SetMentionedTeamIds, mentionedTeamIds)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
