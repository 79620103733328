const mixin = {
  methods: {
    // focusElement: function (elementName) {
    //   const that = this
    //   if (this.$refs && this.$refs[elementName]) {
    //     if (this.$refs[elementName].$el) {
    //       this.$nextTick(() => {
    //         that.$refs[elementName].$el.focus()
    //       })
    //     } else {
    //       this.$nextTick(() => {
    //         that.$refs[elementName].focus()
    //       })
    //     }
    //   }
    // },
    // closeToast (toast) {
    //   if (toast) { toast.close() }
    // },
    // getFileType: function (fileName) {
    //   if (!fileName) return ''
    //   if (/^.+\.(jpg|png|jpeg|gif)$/.test(fileName.trim().toLowerCase())) {
    //     return 'image/' + fileName.trim().toLowerCase().match(/^.+\.(jpg|png|jpeg|gif)$/)[0]
    //   }
    //   if (/^.+\.(pdf|doc|txt)$/.test(fileName.trim().toLowerCase())) {
    //     return 'application/' + fileName.trim().toLowerCase().match(/^.+\.(pdf|doc|txt)$/)[0]
    //   }
    //   return 'unknown'
    // },
    isMediaFile: function (fileName) {
      if (!fileName) return false
      return this.isImageFile(fileName) || /^.+\.(mp4|webm|mpg|mov|wmv|avi|mkv|flv|blob)$/.test(fileName.trim().toLowerCase())
    },
    isImageFile: function (fileName) {
      if (!fileName) return false
      return /^.+\.(jpg|png|jpeg|gif|jfif|bmp|tif|tiff)$/.test(fileName.trim().toLowerCase())
    },
    showToast (type, message, timeout) {
      return this.$buefy.toast.open({
        message,
        type,
        duration: timeout || 5000,
        position: 'is-top'
      })
    },
    showSuccessToast (message, timout) {
      this.$buefy.toast.open({
        message,
        type: 'is-info',
        duration: timout || 5000,
        position: 'is-top'
      })
    },
    showFailedToast (message, timout) {
      this.$buefy.toast.open({
        duration: timout || 5000,
        message,
        type: 'is-danger',
        position: 'is-bottom'
      })
    }
  }
}

export default mixin
