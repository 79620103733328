import Vue from 'vue'
import {
  InternalCustomersMutationInterface
} from '../storeInterfaces'
import {
  DefaultCustomerCriteriaRequest
} from '@/definitions'
import rtApiService from '../../services/rtApiService'

const state = {
  criteria: undefined,
  // Customers
  customers: [],
  customersTotal: 0,
  isAllCustomersLoaded: false,
  customer: undefined,
  isAllCustomersMarked: false,
  // Customer Model
  customerModel: undefined,
  // Duplicates
  customerDuplicates: [],
  customerDuplicatesTotal: 0
}

const getters = {
  isInitialized: state => !!state.criteria,
  criteria: state => state.criteria,
  // Customers
  isAllCustomersLoaded: state => state.isAllCustomersLoaded,
  hasCustomers: state => Array.isArray(state.customers) && state.customers.length > 0,
  customersTotal: state => state.customersTotal,
  customers: state => state.customers,
  hasCustomer: state => !!state.customer,
  customer: state => state.customer,
  hasAdditions: state => !!state.customer && (state.customer.contactNumbers.length > 1 || state.customer.emailAddresses.length > 1),
  hasContactNumbers: state => !!state.customer && state.customer.contactNumbers.length > 0,
  hasEmailAddresses: state => !!state.customer && state.customer.emailAddresses.length > 0,
  // Duplicates
  hasCustomerDuplicates: state => Array.isArray(state.customerDuplicates) && state.customerDuplicates.length > 0,
  customerDuplicatesTotal: state => state.customerDuplicatesTotal,
  customerDuplicates: state => state.customerDuplicates
}

const mutations = {
  [InternalCustomersMutationInterface.Reset] (state) {
    state.criteria = undefined
    state.customer = undefined
    state.customersTotal = 0
    state.isAllCustomersLoaded = false
    state.customers = []
    state.customerDuplicatesTotal = 0
    state.customerDuplicates = []
  },
  // Customers
  [InternalCustomersMutationInterface.CustomersReset] (state) {
    state.customersTotal = 0
    state.isAllCustomersLoaded = false
    state.customers = []
    state.customerDuplicatesTotal = 0
    state.customerDuplicates = []
  },
  [InternalCustomersMutationInterface.CustomerReset] (state) {
    state.customer = undefined
  },
  [InternalCustomersMutationInterface.CustomersLoaded] (state, loadedCustomersModel) {
    if (!loadedCustomersModel) return
    state.criteria = { ...loadedCustomersModel.criteria }
    state.customersTotal = loadedCustomersModel.customersTotal
    state.isAllCustomersLoaded = loadedCustomersModel.isAllCustomersLoaded
    state.customers = [...loadedCustomersModel.customers]
  },
  [InternalCustomersMutationInterface.CustomerLoaded] (state, loadedCustomer) {
    if (typeof loadedCustomer !== 'object') return
    state.customer = { ...loadedCustomer }
  },
  // Duplicates
  [InternalCustomersMutationInterface.CustomerDuplicatesLoaded] (state, loadedCustomerDuplicatesModel) {
    if (!loadedCustomerDuplicatesModel) return
    state.customerDuplicates = [...loadedCustomerDuplicatesModel.customers]
    state.customerDuplicatesTotal = loadedCustomerDuplicatesModel.total
  }
}

const actions = {
  reset: function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    commit(InternalCustomersMutationInterface.Reset)
  },
  // Customers
  loadCustomersCompositionAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }, criteria) {
    if (!criteria) return

    commit(InternalCustomersMutationInterface.CustomersReset)

    const customersResponse = await rtApiService.loadCustomersDynamicAsync(criteria)
    Vue.$log.debug(`[DEV]: ${'customers'}.loadCustomersCompositionAsync(criteria)`, customersResponse)

    // Initialize Customers
    const customersModel = {
      criteria,
      isNextPage: false,
      customersTotal: customersResponse.total,
      isAllCustomersLoaded: customersResponse.isLastPage,
      customers: customersResponse.customers
    }

    commit(InternalCustomersMutationInterface.CustomersLoaded, customersModel)
  },
  loadCustomersCompositionNextPageAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    if (!getters.criteria || !getters.hasCustomers || getters.isAllCustomersLoaded) return

    const criteria = { ...getters.criteria }

    if (getters.hasCustomers) {
      criteria.excludeIds = getters.customers.map(customer => customer.id)
    }

    // Load Customers Next Page
    const customersResponse = await rtApiService.loadCustomersDynamicAsync(criteria)
    Vue.$log.debug('[DEV]: customers.loadCustomersCompositionNextPageAsync()', customersResponse)

    // Append Customers
    const customersModel = {
      criteria,
      isNextPage: true,
      customersTotal: customersResponse.total,
      isAllCustomersLoaded: customersResponse.isLastPage,
      customers: getters.customers.concat(customersResponse.customers)
    }

    commit(InternalCustomersMutationInterface.CustomersLoaded, customersModel)
  },
  loadCustomerAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }, customerPayload) {
    if (typeof customerPayload !== 'string' && typeof customerPayload !== 'object') return // Customer Id | Customer

    commit(InternalCustomersMutationInterface.CustomerReset, true)

    const customerCriteriaRequest = JSON.parse(JSON.stringify(DefaultCustomerCriteriaRequest))
    customerCriteriaRequest.customerId = typeof customerPayload === 'string' ? customerPayload : customerPayload.id // Is Customer Id?
    const customerModel = await rtApiService.loadCustomerAsync(customerCriteriaRequest)
    Vue.$log.debug(`[DEV]: ${'customers'}.loadCustomerAsync(customerPayload)`, customerModel)

    commit(InternalCustomersMutationInterface.CustomerLoaded, customerModel)
  },
  // Duplicates
  loadCustomerDuplicatesAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    const customersResponse = await rtApiService.loadCustomersDynamicAsync(true, true)
    Vue.$log.debug(`[DEV]: ${'customers'}.loadCustomerDuplicatesAsync()`, customersResponse)

    commit(InternalCustomersMutationInterface.CustomerDuplicatesLoaded, customersResponse)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
