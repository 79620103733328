<template>
  <div style="display:grid;grid-template-rows:1fr;grid-template-columns:auto auto 1fr auto;gap:10px;">
    <!-- INFO: Main Menu -->
    <b-dropdown aria-role="menu" style="grid-row:1;grid-column:1;margin:auto;" :trap-focus="false">

      <template #trigger>
        <a style="color:#4b6271;margin:auto;">
          <b-icon pack="fas" icon="bars" size="is-large" v-tooltip="'Menu'" />
        </a>
      </template>

      <b-dropdown-item v-if="hasRouteFilter && routeFilter === CrmRouteEnum.Administration" has-link aria-role="menuitem" :focusable="false">
        <router-link :to="CrmClientsRouteEnum.You">Clients</router-link>
      </b-dropdown-item>
      <hr v-if="hasRouteFilter && routeFilter === CrmRouteEnum.Administration" class="dropdown-divider">
      <b-dropdown-item aria-role="menuitem" has-link :focusable="false">
        <router-link :to="CrmAdminsRouteEnum.Users">User Profiles</router-link>
      </b-dropdown-item>
      <b-dropdown-item aria-role="menuitem" has-link :focusable="false">
        <router-link :to="CrmAdminsRouteEnum.Teams">Team Profiles</router-link>
      </b-dropdown-item>
      <b-dropdown-item v-if="false" aria-role="menuitem" has-link :focusable="false">
        <router-link :to="CrmAdminsRouteEnum.TeamMembers">Team Member Profiles</router-link>
      </b-dropdown-item>
      <b-dropdown-item v-if="isLocal" aria-role="menuitem" has-link :focusable="false">
        <router-link :to="CrmAdminsRouteEnum.CustomerProfiles">Customer Profiles</router-link>
      </b-dropdown-item>
      <b-dropdown-item v-if="isLocal" aria-role="menuitem" has-link :focusable="false">
        <router-link :to="CrmAdminsRouteEnum.ClientProfiles">Client Profiles</router-link>
      </b-dropdown-item>
      <b-dropdown-item v-if="isLocal" aria-role="menuitem" has-link :focusable="false">
        <router-link :to="CrmAdminsRouteEnum.InboundEmails">Inbound Emails</router-link>
      </b-dropdown-item>
      <b-dropdown-item v-if="isLocal" aria-role="menuitem" has-link :focusable="false">
        <router-link :to="CrmAdminsRouteEnum.Subjects">Subjects</router-link>
      </b-dropdown-item>
      <b-dropdown-item v-if="isLocal" aria-role="menuitem" has-link :focusable="false">
        <router-link :to="CrmAdminsRouteEnum.Tags">Tags</router-link>
      </b-dropdown-item>
      <b-dropdown-item v-if="isLocal" aria-role="menuitem" has-link :focusable="false">
        <router-link :to="CrmAdminsRouteEnum.AssignmentRules">Assignment Rules</router-link>
      </b-dropdown-item>
      <b-dropdown-item v-if="isLocal" aria-role="menuitem" has-link :focusable="false">
        <router-link :to="CrmAdminsRouteEnum.BlockedSources">Blockings</router-link>
      </b-dropdown-item>
      <hr v-if="isLocal" class="dropdown-divider">
      <b-dropdown-item v-if="isLocal" aria-role="menuitem" has-link :focusable="false">
        <router-link :to="CrmAdminsRouteEnum.Internal">Development (Internal)</router-link>
      </b-dropdown-item>

    </b-dropdown>
    <!-- INFO: Subscriber Logo -->
    <img :src="subscriberLogo" :alt="subscriberName" style="grid-row:1;grid-column:2;height:48px;margin:auto;" />
    <!-- INFO: Global Search Portal -->
    <portal-target name="global-search-portal" slim class="is-expanded" style="grid-row:1;grid-column:3;margin-top:auto;margin-bottom:auto;" />
    <!-- INFO: User Profile -->
    <app-user-profile-component style="grid-row:1;grid-column:4;" />
  </div>
</template>

<script>
import {
  CrmRouteEnum,
  CrmClientsRouteEnum,
  CrmAdminsRouteEnum
} from '@/enums'
import {
  SystemGetterInterface
} from '@/store/storeInterfaces'
import {
  mapGetters,
  createNamespacedHelpers
} from 'vuex'
import AppUserProfileComponent from '@/components/AppUserProfileComponent'

const mapDealerState = createNamespacedHelpers('dealers').mapState
const mapSubscriberState = createNamespacedHelpers('subscriber').mapState

export default {
  name: 'AppHeaderComponent',
  components: {
    'app-user-profile-component': AppUserProfileComponent
  },
  data () {
    return {
      CrmRouteEnum,
      CrmClientsRouteEnum,
      CrmAdminsRouteEnum
    }
  },
  methods: {},
  computed: {
    ...mapDealerState(['currentDealer']),
    ...mapSubscriberState(['currentSubscriber']),
    ...mapGetters({
      isLocal: SystemGetterInterface.isLocal,
      isDevelopment: SystemGetterInterface.isDevelopment,
      hasRouteFilter: SystemGetterInterface.hasRouteFilter,
      routeFilter: SystemGetterInterface.routeFilter
    }),
    dealerLogo () {
      const defaultLogo = require('@/assets/logo.png')
      if (this.currentDealer) {
        return this.currentDealer.fileLocation !== '' && this.currentDealer.fileLocation !== 'Copy Failed!' ? this.currentDealer.fileLocation : defaultLogo
      }
      return defaultLogo
    },
    dealerName () {
      const defaultName = 'Experience Auto Group'
      if (this.currentDealer) {
        return this.currentDealer.name
      }
      return defaultName
    },
    subscriberLogo () {
      const defaultLogo = require('@/assets/eag_logo.png')
      if (this.currentSubscriber) {
        return this.currentSubscriber.fileLocation !== '' && this.currentSubscriber.fileLocation !== 'Copy Failed!' ? this.currentSubscriber.fileLocation : defaultLogo
      }
      return defaultLogo
    },
    subscriberName () {
      const defaultName = 'Experience Auto Group'
      if (this.currentSubscriber) {
        return this.currentSubscriber.name
      }
      return defaultName
    }
  },
  mounted: function () {
    this.$log.info(`${this.$options.name} Mounted`)
    // this.$log.debug(`[DEV]: ${this.$options.name} >> routeFilter`, this.hasRouteFilter, this.routeFilter)
  }
}
</script>
