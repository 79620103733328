import Vue from 'vue'

const PartialEnum = {
  indexOf: function (val) { return Object.values(this).findIndex(item => typeof item === 'number' && item === val) },
  isValid: function (val) { return this.indexOf(val) >= 0 },
  keyOf: function (val) { return Object.keys(this)[this.indexOf(val)] },
  keys: function () { return Array.from(Object.values(this).filter(item => typeof item === 'number'), value => this.keyOf(value)) }
}

export const NotificationEventEnum = {
  // General
  get Notifications () { return 'NotificationEvent-Notifications' },
  get TotalsUpdate () { return 'NotificationEvent-TotalsUpdate' },
  // Clients
  get ClientAdded () { return 'NotificationEvent-ClientAdded' },
  get ClientTagAdded () { return 'NotificationEvent-ClientTagAdded' },
  get ClientTagRemoved () { return 'NotificationEvent-ClientTagRemoved' },
  get ClientTagUpdated () { return 'NotificationEvent-ClientTagUpdated' },
  get ClientTagsUpdated () { return 'NotificationEvent-ClientTagsUpdated' },
  get ClientParticipantAdded () { return 'NotificationEvent-ClientParticipantAdded' },
  get ClientParticipantUpdated () { return 'NotificationEvent-ClientParticipantUpdated' },
  get ClientParticipantRemoved () { return 'NotificationEvent-ClientParticipantRemoved' },
  get ClientWatchListAdded () { return 'NotificationEvent-ClientWatchListAdded' },
  get ClientWatchListUpdated () { return 'NotificationEvent-ClientWatchListUpdated' },
  get ClientWatchListRemoved () { return 'NotificationEvent-ClientWatchListRemoved' },
  get ClientPriorityUpdated () { return 'NotificationEvent-ClientPriorityUpdated' },
  get ClientStatusUpdated () { return 'NotificationEvent-ClientStatusUpdated' },
  get ClientAssignmentUpdated () { return 'NotificationEvent-ClientAssignmentUpdated' },
  get ClientAssignmentRemoved () { return 'NotificationEvent-ClientAssignmentRemoved' },
  get ClientsCustomersUpdated () { return 'NotificationEvent-ClientsCustomersUpdated' },
  get ClientsStatusesUpdated () { return 'NotificationEvent-ClientsStatusesUpdated' },
  get ClientsPrioritiesUpdated () { return 'NotificationEvent-ClientsPrioritiesUpdated' },
  get ClientsMerged () { return 'NotificationEvent-ClientsMerged' },
  // Leads
  get LeadAdded () { return 'NotificationEvent-LeadAdded' },
  get LeadTagAdded () { return 'NotificationEvent-LeadTagAdded' },
  get LeadTagUpdated () { return 'NotificationEvent-LeadTagUpdated' },
  get LeadTagRemoved () { return 'NotificationEvent-LeadTagRemoved' },
  get LeadVoiAdded () { return 'NotificationEvent-LeadVoiAdded' },
  get LeadVoiUpdated () { return 'NotificationEvent-LeadVoiUpdated' },
  get LeadVoiRemoved () { return 'NotificationEvent-LeadVoiRemoved' },
  get LeadInvolvedUserAdded () { return 'NotificationEvent-LeadInvolvedUserAdded' },
  get LeadStatusUpdated () { return 'NotificationEvent-LeadStatusUpdated' },
  // Messages
  get MessageNotification () { return 'NotificationEvent-MessageNotification' },
  get MessageStatusUpdated () { return 'NotificationEvent-MessageStatusUpdated' },
  get MessageTransactionUpdated () { return 'NotificationEvent-MessageTransactionUpdated' },
  get MessageAdded () { return 'NotificationEvent-MessageAdded' },
  get MessageRemoved () { return 'NotificationEvent-MessageRemoved' },
  get MessageTagAdded () { return 'NotificationEvent-MessageTagAdded' },
  get MessageTagUpdated () { return 'NotificationEvent-MessageTagUpdated' },
  get MessageTagRemoved () { return 'NotificationEvent-MessageTagRemoved' },
  get MessageRead () { return 'NotificationEvent-MessageRead' },
  get MessageReacted () { return 'NotificationEvent-MessageReacted' },
  // Teams/TeamMembers
  get TeamAdded () { return 'NotificationEvent-TeamAdded' },
  get TeamUpdated () { return 'NotificationEvent-TeamUpdated' },
  get TeamRemoved () { return 'NotificationEvent-TeamRemoved' },
  get TeamMemberAdded () { return 'NotificationEvent-TeamMemberAdded' },
  get TeamMemberUpdated () { return 'NotificationEvent-TeamMemberUpdated' },
  get TeamMemberRemoved () { return 'NotificationEvent-TeamMemberRemoved' },
  // Customers
  get CustomerUpdated () { return 'NotificationEvent-CustomerUpdated' },
  // Tests
  get TestNotification () { return 'NotificationEvent-TestNotification' },
  ...PartialEnum
}

export const AuthorizationStatusEnum = {
  get None () { return '' },
  get Loading () { return 'loading' },
  get Verifying () { return 'verifying' },
  get Success () { return 'success' },
  get Error () { return 'error' }
}

export const CrmRouteEnum = {
  get LoaderRoute () { return '/' },
  get LoginRoute () { return '/auth/login' },
  get DefaultClientsRoute () { return '/clients/you' },
  get Clients () { return 'clients' },
  get Administration () { return 'admin' },
  get titles () { return [{ id: this.Clients, title: 'Clients' }, { id: this.Administration, title: 'Administration' }] },
  titleOf: function (routeFilter) { return this.titles.find(title => title.id === routeFilter).title }
}

export const CrmClientsRouteEnum = {
  get You () { return `/${CrmRouteEnum.Clients}/you` },
  get Snoozed () { return `/${CrmRouteEnum.Clients}/snoozed` },
  get WatchList () { return `/${CrmRouteEnum.Clients}/watchlist` },
  get Mentions () { return `/${CrmRouteEnum.Clients}/mentions` },
  get Critical () { return `/${CrmRouteEnum.Clients}/critical` },
  get All () { return `/${CrmRouteEnum.Clients}/all` },
  get Unassigned () { return `/${CrmRouteEnum.Clients}/unassigned` },
  get Team () { return `/${CrmRouteEnum.Clients}/team` },
  get TeamMember () { return `/${CrmRouteEnum.Clients}/teammember` },
  get ByUser () { return `/${CrmRouteEnum.Clients}/byuser` },
  get PinnedUser () { return `/${CrmRouteEnum.Clients}/pinneduser` },
  get Search () { return `/${CrmRouteEnum.Clients}/search` },
  get Internal () { return `/${CrmRouteEnum.Clients}/internal` },
  get titles () {
    return [
      { routePath: this.You, title: 'You' },
      { routePath: this.Snoozed, title: 'Snoozed' },
      { routePath: this.WatchList, title: 'Watch List' },
      { routePath: this.Mentions, title: 'Mentions' },
      { routePath: this.Critical, title: 'Critical' },
      { routePath: this.All, title: 'All' },
      { routePath: this.Unassigned, title: 'Unassigned' },
      { routePath: this.Team, title: 'Team' },
      { routePath: this.TeamMember, title: 'Team Member' },
      { routePath: this.ByUser, title: 'User' },
      { routePath: this.PinnedUser, title: 'User' },
      { routePath: this.Search, title: 'Search Result' },
      { routePath: this.Search, title: 'Internal (Dev)' }
    ]
  },
  indexOf: function (val) { return Object.values(this).findIndex(item => typeof item === 'string' && item === val) },
  isValid: function (val) { return this.indexOf(val) >= 0 },
  keyOf: function (val) { return Object.keys(this)[this.indexOf(val)] },
  keys: function () { return Array.from(Object.values(this).filter(item => typeof item === 'string' && item.startsWith(`/${CrmRouteEnum.Clients}/`)), value => this.keyOf(value)) },
  titleOf: function (routePath) { return this.titles.find(title => title.routePath === routePath).title }
}

export const CrmAdminsRouteEnum = {
  get Users () { return `/${CrmRouteEnum.Administration}/users` },
  get Teams () { return `/${CrmRouteEnum.Administration}/teams` },
  get TeamMembers () { return `/${CrmRouteEnum.Administration}/teammembers` },
  get Subjects () { return `/${CrmRouteEnum.Administration}/subjects` },
  get Tags () { return `/${CrmRouteEnum.Administration}/tags` },
  get InboundEmails () { return `/${CrmRouteEnum.Administration}/inbound-emails` },
  get CustomerProfiles () { return `/${CrmRouteEnum.Administration}/customer-profiles` },
  get ClientProfiles () { return `/${CrmRouteEnum.Administration}/client-profiles` },
  get AssignmentRules () { return `/${CrmRouteEnum.Administration}/assignment-rules` },
  get BlockedSources () { return `/${CrmRouteEnum.Administration}/blocked-sources` },
  get Internal () { return `/${CrmRouteEnum.Administration}/internal` },
  get titles () {
    return [
      { routePath: this.Users, title: 'Users' },
      { routePath: this.Teams, title: 'Teams' },
      { routePath: this.TeamMembers, title: 'Team Members' },
      { routePath: this.Subjects, title: 'Subjects' },
      { routePath: this.Tags, title: 'Tags' },
      { routePath: this.InboundEmails, title: 'Inbound Emails' },
      { routePath: this.CustomerProfiles, title: 'Customer Profiles' },
      { routePath: this.ClientProfiles, title: 'Client Profiles' },
      { routePath: this.AssignmentRules, title: 'Assignment Rules' },
      { routePath: this.BlockedSources, title: 'Blocked Sources' },
      { routePath: this.Internal, title: 'Internal (Dev)' }
    ]
  },
  titleOf: function (routePath) { return this.titles.find(title => title.routePath === routePath).title }
}

export const ClientsCriteriaTargetTypeEnum = {
  get Full () { return 0 },
  get Grid () { return 1 },
  get Search () { return 2 },
  ...PartialEnum
}

export const ClientsFilterTypeEnum = {
  get You () { return 0 },
  get Snoozed () { return 10 },
  get WatchList () { return 20 },
  get Mentions () { return 30 },
  get Critical () { return 40 },
  get All () { return 50 },
  get Unassigned () { return 60 },
  get Team () { return 70 },
  get TeamMember () { return 80 },
  get ByUser () { return 90 },
  get PinnedUser () { return 100 },
  get Search () { return 110 },
  get titles () {
    return [
      { id: this.You, title: 'You' },
      { id: this.Snoozed, title: 'Snoozed' },
      { id: this.WatchList, title: 'Watch List' },
      { id: this.Mentions, title: 'My Mentions' },
      { id: this.Critical, title: 'Critical' },
      { id: this.All, title: 'All' },
      { id: this.Unassigned, title: 'Unassigned' },
      { id: this.Team, title: 'Team' },
      { id: this.TeamMember, title: 'Team Member' },
      { id: this.ByUser, title: 'User' },
      { id: this.PinnedUser, title: 'Pinned' },
      { id: this.Search, title: 'Search' }
    ]
  },
  titleOf: function (clientsFilterType) { return this.titles.find(title => title.id === clientsFilterType).title },
  ...PartialEnum
}

export const ClientStatusEnum = {
  get All () { return -1 },
  get Open () { return 0 },
  get Closed () { return 1 },
  get Snoozed () { return 2 },
  get Merged () { return 3 },
  // get OpenLeads () { return 4 },
  get options () {
    return [
      { id: this.All, option: 'All' },
      { id: this.Open, option: 'Open' },
      { id: this.Closed, option: 'Closed' },
      { id: this.Snoozed, option: 'Snoozed' },
      { id: this.Merged, option: 'Archived' }
    ]
  },
  ...PartialEnum
}

export const MentionStatusTypeEnum = {
  get All () { return -1 },
  get Unread () { return 1 },
  get options () { return [{ id: 0, option: 'All' }, { id: 1, option: 'Unread' }] },
  ...PartialEnum
}

export const WatchListStatusEnum = {
  get Active () { return -1 },
  get Removed () { return 0 },
  get options () { return [{ id: -1, option: 'Active' }, { id: 0, option: 'Removed' }] },
  ...PartialEnum
}

export const ClientsCriteriaSortByEnum = {
  get DealerName () { return 'Client.DealerName' },
  get DealerCode () { return 'Client.DealerCode' },
  get ClientType () { return 'Client.Type' },
  get ClientFullName () { return 'Client.FullName' },
  get ClientEmailAddress () { return 'Client.EmailAddress' },
  get ClientMobileNumber () { return 'Client.MobileNumber' },
  get Status () { return 'Client.Status' },
  get Priority () { return 'Client.Priority' },
  get CreatedAt () { return 'Client.CreatedAt' },
  get CreateBy () { return 'Client.CreateBy' },
  get ModifiedAt () { return 'Client.ModifiedAt' },
  get ModifiedBy () { return 'Client.ModifiedBy' },
  get UpdatedAt () { return 'Client.UpdatedAt' }
}

export const ClientPriorityEnum = {
  get None () { return -1 },
  get Medium () { return 1 },
  get High () { return 2 },
  ...PartialEnum
}

export const ClientTypeEnum = {
  get Customer () { return 0 },
  get Supplier () { return 1 },
  ...PartialEnum
}

export const CloseStatusEnum = {
  get NotSet () { return -1 },
  get ReadyToClose () { return 0 },
  get RequireReason () { return 1 },
  ...PartialEnum
}

export const CustomerTypeEnum = {
  get Individual () { return 0 },
  get Company () { return 1 },
  get Spouse () { return 2 },
  get Lead () { return 3 },
  ...PartialEnum
}

export const CustomerSortByEnum = {
  get DealerName () { return 'Customer.DealerName' },
  get DealerCode () { return 'Customer.DealerCode' },
  get CustomerType () { return 'Customer.Type' },
  get CustomerFullName () { return 'Customer.FullName' },
  get CustomerEmailAddress () { return 'Customer.EmailAddress' },
  get CustomerMobileNumber () { return 'Customer.MobileNumber' },
  get CustomerTelephoneNumber () { return 'Customer.TelephoneNumber' },
  get Status () { return 'Customer.Status' },
  ...PartialEnum
}

export const FilterSubTypeEnum = {
  get Customer () { return 1 },
  // get Deal () { return 2 },
  get Inventory () { return 3 },
  get options () {
    return [
      { id: 1, option: 'Customer' },
      // { id: 2, option: 'Deal' },
      { id: 3, option: 'Inventory' }
    ]
  },
  ...PartialEnum
}

export const WaitingCriteriaSortEnum = {
  get NotSet () { return -1 },
  get Oldest () { return 0 },
  get Newest () { return 1 },
  get WaitingLongest () { return 2 },
  get Priority () { return 3 },
  get options () {
    return [
      { id: 0, option: 'Oldest' },
      { id: 1, option: 'Newest' },
      { id: 2, option: 'Waiting Longest' },
      { id: 3, option: 'Priority' }
    ]
  },
  ...PartialEnum
}

export const WaitingTypeEnum = {
  get All () { return -1 },
  get Waiting () { return 0 },
  get MoreThanThrityMinutes () { return 1 },
  get MoreThanAnHour () { return 2 },
  get MoreThanTwoHours () { return 3 },
  get options () {
    return [
      { id: this.Waiting, option: 'Waiting' },
      { id: this.MoreThanThrityMinutes, option: 'Waiting longer than 30 minutes' },
      { id: this.MoreThanAnHour, option: 'Waiting longer than an hour' },
      { id: this.MoreThanTwoHours, option: 'Waiting longer than 2 hours' }
    ]
  },
  get shortOptions () {
    return [
      { id: this.Waiting, option: 'Waiting' },
      { id: this.MoreThanThrityMinutes, option: '>30m' },
      { id: this.MoreThanAnHour, option: '>1h' },
      { id: this.MoreThanTwoHours, option: '>2h' }
    ]
  },
  ...PartialEnum
}

export const WatchListWaitingCriteriaSortEnum = {
  get NotSet () { return -1 },
  get Oldest () { return 0 },
  get Newest () { return 1 },
  get WaitingLongest () { return 2 },
  get Priority () { return 3 },
  // TODO: Change Implementation.Models.Requests.ClientCriteriaRequestModel.WaitingSortOrder type to WatchListWaitingSortEnum
  // get LastAdded () { return 4 },
  get LastAdded () { return -1 },
  get options () {
    return [
      { id: this.Oldest, option: 'Oldest' },
      { id: this.Newest, option: 'Newest' },
      { id: this.WaitingLongest, option: 'Waiting Longest' },
      { id: this.Priority, option: 'Priority' },
      { id: this.LastAdded, option: 'Last Added' }
    ]
  },
  ...PartialEnum
}

export const InboundEmailStatusTypeEnum = {
  get Failed () { return 0 },
  get Success () { return 1 },
  ...PartialEnum
}

export const LeadsCriteriaSortByEnum = {
  get DealerName () { return 'Lead.DealerName' },
  get DealerCode () { return 'Lead.DealerCode' },
  get Status () { return 'Lead.Status' },
  get CreatedAt () { return 'Lead.CreatedAt' },
  get CreateBy () { return 'Lead.CreateBy' },
  get ModifiedAt () { return 'Lead.ModifiedAt' },
  get ModifiedBy () { return 'Lead.ModifiedBy' },
  get UpdatedAt () { return 'Lead.UpdatedAt' }
}

export const LeadResponseEnum = {
  get NotSet () { return -1 },
  get FirstInbound () { return 0 },
  get FirstOutbound () { return 1 },
  get InCommunication () { return 2 },
  get FirstClose () { return 3 },
  get Closed () { return 4 },
  ...PartialEnum
}

export const LeadSourceEnum = {
  get NotSet () { return -1 },
  get Manual () { return 0 },
  get Message () { return 1 },
  ...PartialEnum
}

export const LeadStatusEnum = {
  get NotSet () { return -1 },
  get Open () { return 0 },
  get Closed () { return 1 },
  get Merged () { return 50 },
  get Duplicate () { return 100 },
  get options () {
    return [
      { id: this.Open, option: 'Open' },
      { id: this.Closed, option: 'Closed' },
      { id: this.Merged, option: 'Merged' },
      { id: this.Duplicate, option: 'Duplicate' }
    ]
  },
  ...PartialEnum,
  values: function () { return Object.values(this).filter(item => typeof item === 'number' && item !== this.NotSet) }
}

export const LeadTypeEnum = {
  get NotSet () { return -1 },
  get Default () { return 0 },
  get ThirdParty () { return 1 },
  get Phone () { return 2 },
  get Internet () { return 3 },
  get Showroom () { return 4 },
  get options () {
    return [
      { id: this.Default, option: 'Default' },
      { id: this.ThirdParty, option: 'Third Party' },
      { id: this.Phone, option: 'Phone' },
      { id: this.Internet, option: 'Internet' },
      { id: this.Showroom, option: 'Showroom' }
    ]
  },
  get icons () {
    return [
      { id: this.Default, icon: 'globe' },
      { id: this.ThirdParty, icon: 'globe' },
      { id: this.Phone, icon: 'phone' },
      { id: this.Internet, icon: 'globe' },
      { id: this.Showroom, icon: 'walking' }
    ]
  },
  ...PartialEnum
}

export const MediaTypeEnum = {
  get Image () { return 0 },
  get Pdf () { return 1 },
  get Video () { return 2 },
  get Other () { return 3 },
  ...PartialEnum
}

export const MessagesCriteriaSortByEnum = {
  get DealerName () { return 'Message.DealerName' },
  get DealerCode () { return 'Message.DealerCode' },
  get Status () { return 'Message.Status' },
  get CreatedAt () { return 'Message.CreatedAt' },
  get CreateBy () { return 'Message.CreateBy' },
  get ModifiedAt () { return 'Message.ModifiedAt' },
  get ModifiedBy () { return 'Message.ModifiedBy' },
  get UpdatedAt () { return 'Message.UpdatedAt' }
}

export const MessageTypeEnum = {
  get NotSet () { return -1 },
  get Email () { return 0 },
  get Sms () { return 1 },
  get Call () { return 2 },
  get DealerPeakNote () { return 3 },
  get Note () { return 4 },
  get Notification () { return 5 },
  get Lead () { return 6 },
  get options () {
    return [
      { id: this.Email, option: 'Email' },
      { id: this.Sms, option: 'Sms' },
      { id: this.Call, option: 'Call' },
      { id: this.DealerPeakNote, option: 'DealerPeak Note' },
      { id: this.Note, option: 'Note' },
      { id: this.Notification, option: 'Notification' }
    ]
  },
  ...PartialEnum,
  values: function () { return Object.values(this).filter(item => typeof item === 'number' && item !== this.NotSet && item !== this.Lead) },
  optionsFrom: function (values) { return this.options.filter(option => values.includes(option.id)) }
}

export const DraftMessageTypeEnum = {
  get NotSet () { return -1 },
  get Email () { return 0 },
  get Sms () { return 1 },
  get DealerPeakNote () { return 3 },
  get Note () { return 4 },
  get EmailSms () { return 10 },
  get labels () {
    return [
      { id: this.Email, title: 'Email' },
      { id: this.Sms, title: 'Sms' },
      { id: this.Call, title: 'Call' },
      { id: this.DealerPeakNote, title: 'DealerPeak Note' },
      { id: this.Note, title: 'Note' },
      { id: this.EmailSms, title: 'Email & Sms' }
    ]
  },
  ...PartialEnum,
  values: function () { return Object.values(this).filter(item => typeof item === 'number' && item !== this.NotSet) },
  labelByType: function (type) { return type !== this.NotSet ? this.labels.find(label => label.id === type).title : '' }
}

export const ReactionTypeEnum = {
  get ThumbsUp () { return 0 },
  ...PartialEnum
}

export const SmsDelayTypeEnum = {
  get SendImmediately () { return 0 },
  get SendWhenOpensTheEmail () { return 1 },
  get TimeDelayToday () { return 2 },
  get TimeDelayTomorrow () { return 3 },
  get TimeDelayMonday () { return 4 },
  get TimeDelayWeek () { return 5 },
  get TimeDelayMonth () { return 6 },
  get UnopenToday () { return 7 },
  get UnopenTomorrow () { return 8 },
  get UnopenMonday () { return 9 },
  get UnopenWeek () { return 10 },
  get UnopenMonth () { return 11 },
  ...PartialEnum
}

export const SmsGatewayEnum = {
  get InteractiveTel () { return 0 },
  ...PartialEnum
}

export const SmsTypeEnum = {
  get ScheduledAppointment () { return 0 },
  get CancelAppointment () { return 1 },
  get PickupLocation () { return 2 },
  get WireDetails () { return 3 },
  get WindowSticker () { return 4 },
  get Customer () { return 5 },
  get Generic () { return 6 },
  get BillOfSale () { return 7 },
  ...PartialEnum
}

export const StatusEnum = {
  get Active () { return 0 },
  get InActive () { return 1 },
  get Deleted () { return 2 },
  get Archived () { return 3 },
  get Cancelled () { return 4 },
  get Suspended () { return 5 },
  get Requested () { return 6 },
  get Imported () { return 7 },
  ...PartialEnum
}
export const InventoryTypeEnum = {
  get All () { return -1 },
  get New () { return 0 },
  get Purchased () { return 1 },
  get PpaClosed () { return 2 },
  get Consigned () { return 3 },
  get TradeIn () { return 4 },
  get ForPpaAppraisal () { return 5 },
  get LeaseReturn () { return 6 },
  get Used () { return 10 },
  get options () {
    return [
      { id: this.All, option: 'All' },
      { id: this.New, option: 'New' },
      { id: this.Purchased, option: 'Purchased' },
      { id: this.PpaClosed, option: 'PPA Closed' },
      { id: this.Consigned, option: 'Consigned' },
      { id: this.TradeIn, option: 'Trade In' },
      { id: this.ForPpaAppraisal, option: 'For PPA Appraisal' },
      { id: this.LeaseReturn, option: 'Lease Return' },
      { id: this.Used, option: 'Used' }
    ]
  },
  ...PartialEnum
}

export const ParticipantsCriteriaSortEnum = {
  get NotSet () { return -1 },
  get Oldest () { return 0 },
  get Latest () { return 1 },
  get Name () { return 3 },
  get options () {
    return [
      { id: this.Oldest, option: 'Oldest' },
      { id: this.Latest, option: 'Latest' },
      { id: this.Name, option: 'Names' }
    ]
  },
  ...PartialEnum
}

export const MentionsCriteriaSortEnum = {
  get NotSet () { return -1 },
  get Oldest () { return 0 },
  get Latest () { return 1 },
  get Name () { return 3 },
  get options () {
    return [
      { id: this.Oldest, option: 'Oldest' },
      { id: this.Latest, option: 'Latest' },
      { id: this.Name, option: 'Names' }
    ]
  },
  ...PartialEnum
}

export const PanelInfoEnum = {
  get Info () { return 0 },
  get VehiclesOfInterest () { return 1 },
  get Mentions () { return 2 },
  get Tags () { return 3 },
  ...PartialEnum
}

export const UserTeamSelectorOptionEnum = {
  get NotSet () { return -1 },
  get UserOnly () { return 1 },
  get TeamOnly () { return 2 },
  ...PartialEnum
}

export const SearchTagEnum = {
  get NotSet () { return -1 },
  get Text () { return 0 },
  get Tag () { return 1 },
  get Date () { return 2 },
  get Mobile () { return 3 },
  get Email () { return 4 },
  get VIN () { return 5 },
  get User () { return 6 },
  get AssignedTo () { return 7 },
  get Client () { return 8 },
  // get Supplier () { return 9 },
  ...PartialEnum,
  get searchTags () {
    return [
      { val: this.Text, key: 'Text', title: 'Message Text', iconPack: 'fas', icon: 'search', description: 'Subject or Content' }, // Message
      { val: this.Tag, key: 'Tag', title: 'Tag', iconPack: 'fas', icon: 'tag', description: 'Client or Lead or Message' }, // Client/Lead/Message
      { val: this.Date, key: 'Date', title: 'Date', iconPack: 'fas', icon: 'calendar-alt', description: 'Client or Lead or Message' }, // Client/Lead/Message
      { val: this.Mobile, key: 'Mobile', title: 'Phone Number', iconPack: 'fas', icon: 'phone', description: 'Customer or Message' }, // Client/Message
      { val: this.Email, key: 'Email', title: 'Email Address', iconPack: 'fas', icon: 'at', description: 'Customer or Message' }, // Client/Message
      { val: this.VIN, key: 'VIN', title: 'VIN Number', iconPack: 'fas', icon: 'car', description: 'Vehicle Of Interest or Message' }, // VehicleOfInterest/Message
      { val: this.User, key: 'User', title: 'Participant or Mentioned', iconPack: 'fas', icon: 'user', description: 'Participant or Mention' }, // Participant/Mention
      { val: this.AssignedTo, key: 'AssignedTo', title: 'Assigned To', iconPack: 'fas', icon: 'user', description: 'Client Assigned To' }, // Client
      { val: this.Client, key: 'Client', title: 'Customer', iconPack: 'fas', icon: 'user', description: 'Customer' } // Customer
      // { val: this.Supplier, key: 'Supplier', title: 'Supplier', iconPack: 'fas', icon: 'building', description: 'Supplier' } // Supplier
    ]
  },
  searchTagOf: function (identity) {
    // Vue.$log.debug('[DEV]: SearchTagEnum.searchTagOf(identity)', typeof identity, identity, this.searchTags)
    if (typeof identity === 'number') {
      const searchTag = this.searchTags.find(tag => tag.val === identity)
      return searchTag
    } else if (typeof identity === 'string') {
      const searchTag = this.searchTags.find(tag => tag.key === identity)
      return searchTag
    }
    return undefined
  }
}

export const SearchTagTargetEnum = {
  get NotSet () { return -1 },
  get All () { return 0 },
  get Clients () { return 1 },
  get Leads () { return 2 },
  get Messages () { return 3 },
  ...PartialEnum,
  get titles () {
    return [
      { target: this.All, title: 'All' },
      { target: this.Clients, title: 'Clients' },
      { target: this.Leads, title: 'Leads' },
      { target: this.Messages, title: 'Messages' }
    ]
  }
}

export const SearchTagDateRangeEnum = {
  get NotSet () { return -1 },
  // get Fixed () { return 0 },
  get From () { return 1 },
  // get To () { return 2 },
  get Today () { return 10 },
  get Yesterday () { return 11 },
  get Last30Days () { return 12 },
  get Last90Days () { return 13 },
  ...PartialEnum,
  get titles () {
    return [
      // { range: this.NotSet, title: 'None' },
      // { range: this.Fixed, title: 'Fixed' },
      { range: this.From, title: 'From' },
      // { range: this.To, title: 'To' },
      { range: this.Today, title: 'Today' },
      { range: this.Yesterday, title: 'Yesterday' },
      { range: this.Last30Days, title: 'Last 30 Days' },
      { range: this.Last90Days, title: 'Last 90 Days' }
    ]
  },
  titleOf: function (range) { return this.titles.find(title => title.range === range).title }
}

export const MergeRecordColumnEnum = {
  get Meta () { return 0 },
  get Column () { return 1 },
  get keys () {
    return [
      'meta',
      'column'
    ]
  }
}

export const MergeRecordRowEnum = {
  get FirstName () { return 0 },
  get LastName () { return 1 },
  get PhoneNumber () { return 2 },
  get AdditionalPhoneNumbers () { return 3 },
  get EmailAddress () { return 4 },
  get AdditionalEmailAddresses () { return 5 },
  get Status () { return 6 },
  get AssignedTo () { return 7 }
}
