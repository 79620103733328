<template>
  <div  class="is-full-height has-text-weight-semibold" style="display:grid;place-items:center;">
    <div style="display:grid;">
      <img src="@/assets/ajax-loader.gif" height="100" width="100" style="margin:auto;">
      <div v-if="isLoadingConfiguration">Loading configuration data</div>
    </div>
  </div>
</template>

<script>
import { CrmRouteEnum } from '@/enums'
import {
  AuthorizationGetterInterface,
  AuthorizationActionInterface,
  SystemGetterInterface,
  SystemActionInterface
} from '@/store/storeInterfaces'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AppLoaderPage',
  data () {
    return {
      CrmRouteEnum,
      AuthorizationGetterInterface,
      AuthorizationActionInterface,
      SystemGetterInterface,
      SystemActionInterface,
      isVerifyingAuthentication: false,
      isLoadingConfiguration: false
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: AuthorizationGetterInterface.isAuthenticated,
      isSystemInitialized: SystemGetterInterface.isSystemInitialized
    })
  },
  methods: {
    ...mapActions({
      verifyAsync: AuthorizationActionInterface.verifyAsync,
      logoutAsync: AuthorizationActionInterface.logoutAsync,
      systemInitializeAsync: SystemActionInterface.systemInitializeAsync
    }),
    initializeAsync: async function () {
      if (!this.isAuthenticated) {
        this.isVerifyingAuthentication = true
        try {
          this.$log.debug(`[DEV]: ${this.$options.name}.initializeAsync() >> VERIFY AUTHENTICATION`)
          await this.verifyAsync()
          if (!this.isAuthenticated) {
            this.$log.debug(`[DEV]: ${this.$options.name}.initializeAsync() >> VERIFY AUTHENTICATION FAILED`)
          }
        } catch (error) {
          this.$log.debug(`[DEV]: ${this.$options.name}.initializeAsync() >> VERIFY AUTHENTICATION >> ERROR`, error)
        }
        this.isVerifyingAuthentication = false
      }

      if (this.isAuthenticated) {
        this.$log.debug(`[DEV]: ${this.$options.name}.initializeAsync() >> AUTHENTICATED`)
        if (!this.isSystemInitialized) {
          this.$log.debug(`[DEV]: ${this.$options.name}.initializeAsync() >> INITIALIZE SYSTEM`)
          this.isLoadingConfiguration = true
          await this.systemInitializeAsync()
          this.isLoadingConfiguration = false
        }
        if (this.isSystemInitialized) {
          this.$log.debug(`[DEV]: ${this.$options.name}.initializeAsync() >> SYSTEM INITIALIZATION SUCCESS`)
          if (this.$route.fullPath !== CrmRouteEnum.DefaultClientsRoute) {
            this.$router.push(CrmRouteEnum.DefaultClientsRoute)
          }
          return
        }
        this.$log.debug(`[DEV]: ${this.$options.name}.initializeAsync() >> SYSTEM INITIALIZATION FAILED`)
        await this.logoutAsync()
      }

      this.$router.push(CrmRouteEnum.LoginRoute)
    }
  },
  mounted: async function () {
    this.$log.info(`${this.$options.name} Mounted`)
    await this.initializeAsync()
  }
}
</script>

<style scoped>
</style>
