import lodash from 'lodash'

export const PhoneNumberUtil = {
  PhoneNumberRegex: /^(?:(?:\+1){0,1}[(\- ]{0,1})(\d{3})(?:[)\- ]{0,2})(\d{3})(?:[- ]{0,1})(\d{4})/,
  isValid: function (val) {
    return !!val && this.PhoneNumberRegex.test(val)
  },
  asPlain: function (val) { // Inline formating: "9999999999"
    if (!!val && !this.PhoneNumberRegex.test(val)) return ''
    return val.replace(this.PhoneNumberRegex, '$1$2$3')
  },
  asLocal: function (val) { // Inline formating: "(999) 999-9999"
    if (!!val && !this.PhoneNumberRegex.test(val)) return ''
    return val.replace(this.PhoneNumberRegex, '($1) $2-$3')
  },
  asInternational: function (val) { // Inline formating: "+1(999)-999-9999"
    if (!!val && !this.PhoneNumberRegex.test(val)) return ''
    return val.replace(this.PhoneNumberRegex, '+1($1)-$2-$3')
  },
  asEscapedRegExp: function (val) {
    if (!!val && !this.PhoneNumberRegex.test(val)) return ''
    const escapedRegExp = /[|\\{}()[\]^$+*?.]/g
    const internationalPhoneNumber = PhoneNumberUtil.asInternational(val).replace(escapedRegExp, '\\$&')
    const localPhoneNumber = PhoneNumberUtil.asLocal(val).replace(escapedRegExp, '\\$&')
    const plainPhoneNumber = PhoneNumberUtil.asPlain(val).replace(escapedRegExp, '\\$&')
    const phoneNumberRegExPattern = `${internationalPhoneNumber}|${localPhoneNumber}|${plainPhoneNumber}`
    return new RegExp(phoneNumberRegExPattern)
  }
}

// const EmailAddressRegexp = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/g
export const EmailAddressUtil = {
  EmailAddressRegexp: /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/,
  isValid: function (val) {
    return this.EmailAddressRegexp.test(val)
  }
}

export function objectKeysToCamelCase (entity) {
  if (!lodash.isObject(entity)) return entity
  const result = lodash.mapValues(lodash.mapKeys(entity, (value, key) => lodash.camelCase(key)), (value) => objectKeysToCamelCase(value))
  return result
}
