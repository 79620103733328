import validateAuthorization from './routeGuards'
import { CrmRouteEnum } from '../enums'
import AppDashboardPage from '@/components/AppDashboardPage'

export default [
  {
    path: `/${CrmRouteEnum.Clients}/:filter`,
    name: 'ClientsFilter',
    component: AppDashboardPage,
    beforeEnter: validateAuthorization
  },
  {
    path: `/${CrmRouteEnum.Clients}/:filter/t:team(\\w{24})`,
    name: 'Team',
    component: AppDashboardPage,
    beforeEnter: validateAuthorization
  },
  {
    path: `/${CrmRouteEnum.Clients}/:filter/t:team(\\w{24})/u:user(\\w{24})`,
    name: 'TeamMember',
    component: AppDashboardPage,
    beforeEnter: validateAuthorization
  },
  {
    path: `/${CrmRouteEnum.Clients}/:filter/u:user(\\w{24})`,
    name: 'User',
    component: AppDashboardPage,
    beforeEnter: validateAuthorization
  },
  {
    path: `/${CrmRouteEnum.Clients}/:filter/u:user(\\w{24})/c:client(\\w{24})`,
    name: 'Client',
    component: AppDashboardPage,
    beforeEnter: validateAuthorization
  },
  {
    path: `/${CrmRouteEnum.Clients}/:filter/u:user(\\w{24})/c:client(\\w{24})/l:lead(\\w{24})`,
    name: 'Lead',
    component: AppDashboardPage,
    beforeEnter: validateAuthorization
  },
  {
    path: `/${CrmRouteEnum.Clients}/:filter/u:user(\\w{24})/c:client(\\w{24})/l:lead(\\w{24})/m:message(\\w{24})`,
    name: 'Message',
    component: AppDashboardPage,
    beforeEnter: validateAuthorization
  },
  {
    path: `/${CrmRouteEnum.Clients}/:filter/t:team(\\w{24})/u:user(\\w{24})/c:client(\\w{24})`,
    name: 'TeamClient',
    component: AppDashboardPage,
    beforeEnter: validateAuthorization
  },
  {
    path: `/${CrmRouteEnum.Clients}/:filter/t:team(\\w{24})/c:client(\\w{24})/l:lead(\\w{24})`,
    name: 'TeamLead',
    component: AppDashboardPage,
    beforeEnter: validateAuthorization
  },
  {
    path: `/${CrmRouteEnum.Clients}/:filter/t:team(\\w{24})/c:client(\\w{24})/l:lead(\\w{24})/m:message(\\w{24})`,
    name: 'TeamMessage',
    component: AppDashboardPage,
    beforeEnter: validateAuthorization
  },
  {
    path: `/${CrmRouteEnum.Clients}`,
    name: 'RouteFilter',
    component: AppDashboardPage,
    beforeEnter: validateAuthorization
  }

]
