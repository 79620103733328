import Vue from 'vue'
import axios from 'axios'
import config from './config'

const usersProfileApiService = {
  loadCoreProfileUserAsync: async function () {
    try {
      Vue.$log.debug('[DEV]: usersProfileApiService.loadCoreProfileUserAsync()', `${config.profileApiUrl}users/me`)
      const response = await axios.post(config.profileApiUrl + 'users/me')
      return response.data
    } catch (error) {
      if (error.response) {
        Vue.$log.debug('[DEV]: usersProfileApiService.loadCoreProfileUserAsync() >> RESPONSE ERROR', error)
        throw new Error('The request was made and the server responded with a status code that falls out of the range of 2xx.')
      } else if (error.request) {
        // The request was made but no response was received.
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js.
        Vue.$log.debug('[DEV]: usersProfileApiService.loadCoreProfileUserAsync() >> REQUEST ERROR', error)
        throw new Error('Unable to reach authentication services. Please check network connection and try again.')
      } else {
        // Something happened in setting up the request that triggered an Error
        Vue.$log.debug('[DEV]: usersProfileApiService.loadCoreProfileUserAsync() >> ERROR', error)
        throw new Error('Something happened in setting up the request that triggered an Error.')
      }
    }
  },
  findAllByRoleAsync: async function (dealerId, role) {
    try {
      const response = await axios.get(config.profileApiUrl + 'users/findallbyroledealerid?dealerId=' + dealerId + '&role=' + role)
      return response.data
    } catch (error) {
      Vue.$log.debug('[DEV]: usersProfileApiService.findAllByRoleAsync() >> RESPONSE ERROR', error)
      throw error
    }
  }
}

export default usersProfileApiService
