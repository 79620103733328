import Vue from 'vue'
import rtApiService from '../../services/rtApiService'
import { InternalTeamsMutationInterface } from '../storeInterfaces.js'
import { StatusEnum } from '@/enums'

const state = {
  teams: [],
  teamRefs: [],
  filterTeamRef: undefined,
  filterTeamRefMember: undefined,
  expandedFilterTeamRef: undefined
}

const getters = {
  isInitialized: (state, getters, rootState, rootGetters) => Array.isArray(state.teamRefs),
  // Teams
  hasTeams: (state, getters, rootState, rootGetters) => Array.isArray(state.teams) && state.teams.length > 0,
  teams: (state, getters, rootState, rootGetters) => state.teams,
  // Team References
  hasTeamRefs: (state, getters, rootState, rootGetters) => state.teamRefs,
  teamRefs: (state, getters, rootState, rootGetters) => state.teamRefs,
  teamRef: (state, getters, rootState, rootGetters) => (teamRefId) => state.teamRefs.find(team => team.id === teamRefId),
  isAnyTeamRefMember: (state, getters, rootState, rootGetters) => (userRef) => state.teamRefs.some(team => team.members.some(teamMember => teamMember.id === userRef.id)),
  teamRefsByMember: (state, getters, rootState, rootGetters) => (userRef) => state.teamRefs.filter(team => team.members.some(teamMember => teamMember.id === userRef.id)),
  // Team Reference Filters
  hasFilterTeamRef: (state, getters, rootState, rootGetters) => !!state.filterTeamRef,
  filterTeamRef: (state, getters, rootState, rootGetters) => state.filterTeamRef,
  hasFilterTeamRefMember: (state, getters, rootState, rootGetters) => !!state.filterTeamRefMember,
  filterTeamRefMember: (state, getters, rootState, rootGetters) => state.filterTeamRefMember,
  hasExpandedFilterTeamRef: (state, getters, rootState, rootGetters) => !!state.expandedFilterTeamRef,
  expandedFilterTeamRef: (state, getters, rootState, rootGetters) => state.expandedFilterTeamRef,
  isExpandedFilterTeamRef: (state, getters, rootState, rootGetters) => (teamId) => {
    if (!getters.hasTeamRefs || !state.expandedFilterTeamRef) return false
    return state.expandedFilterTeamRef.id === teamId
  },
  hasFilterTeamRefsByTeamMember: (state, getters, rootState, rootGetters) => (teamMemberId, hasTeamMemberId) => {
    if (!getters.hasTeamRefs || !teamMemberId) return false
    const hasFilteredTeams = state.teamRefs.some(team => team.status === StatusEnum.Active && team.members.length > 0 && hasTeamMemberId === team.members.some(teamMember => teamMember.id === teamMemberId))
    return hasFilteredTeams
  },
  filterTeamRefsByTeamMember: (state, getters, rootState, rootGetters) => (teamMemberId, hasTeamMemberId) => {
    if (!getters.hasTeamRefs || !teamMemberId) return []
    const filteredTeams = state.teamRefs.filter(team => team.status === StatusEnum.Active && team.members.length > 0 && hasTeamMemberId === team.members.some(teamMember => teamMember.id === teamMemberId))
    return filteredTeams
  },
  test: (state, getters, rootState, rootGetters) => (teamMemberId, hasTeamMemberId) => {
    if (!getters.hasTeamRefs || !teamMemberId) return []
    const filteredTeams = state.teamRefs.filter(team => hasTeamMemberId === team.members.some(teamMember => teamMember.id === teamMemberId))
    return filteredTeams
  }
}

const mutations = {
  [InternalTeamsMutationInterface.Reset] (state) {
    state.teams = []
    state.teamRefs = []
    state.filterTeamRef = undefined
    state.expandedFilterTeamRef = undefined
  },
  [InternalTeamsMutationInterface.TeamRefs] (state, teamRefs) {
    state.teamRefs = [...teamRefs]
  },
  // Team Reference Filters
  [InternalTeamsMutationInterface.ClearRefFilters] (state) {
    state.filterTeamRef = undefined
    state.filterTeamRefMember = undefined
    state.expandedFilterTeamRef = undefined
  },
  [InternalTeamsMutationInterface.FilterTeamRef] (state, teamRef) {
    state.filterTeamRefMember = undefined
    state.expandedFilterTeamRef = undefined
    state.filterTeamRef = teamRef
  },
  [InternalTeamsMutationInterface.FilterTeamRefMember] (state, teamRefMember) {
    state.filterTeamRef = undefined
    state.filterTeamRefMember = teamRefMember
  },
  [InternalTeamsMutationInterface.ExpandedFilterTeamRef] (state, teamRef) {
    if (teamRef) {
      if (state.expandedFilterTeamRef === teamRef) {
        state.filterTeamRefMember = undefined
        state.expandedFilterTeamRef = undefined
      } else {
        state.filterTeamRefMember = undefined
        state.filterTeamRef = teamRef
        state.expandedFilterTeamRef = teamRef
      }
    } else {
      state.filterTeamRefMember = undefined
      state.expandedFilterTeamRef = undefined
    }
    state.teamRefs = [...state.teamRefs]
  }
}

const actions = {
  reset: async function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    commit(InternalTeamsMutationInterface.Reset)
  },
  // Teams
  loadAllTeams: async function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    try {
      const response = await rtApiService.getAllTeamReferencesAsync(true)
      // Vue.$log.debug('[DEV]: teams.loadAllTeamsWithMembersAsync()', response)
      commit(InternalTeamsMutationInterface.TeamRefs, response)
    } catch (error) {
      Vue.$log.debug('[DEV]: teams.loadAllTeamsWithMembersAsync() >> ERROR', error)
      commit(InternalTeamsMutationInterface.TeamRefs, [])
    }
  },
  // Team References
  loadAllTeamReferencesAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    try {
      const response = await rtApiService.getAllTeamReferencesAsync(true)
      // Vue.$log.debug('[DEV]: teams.loadAllTeamReferencesAsync()', response)
      commit(InternalTeamsMutationInterface.TeamRefs, response)
    } catch (error) {
      Vue.$log.debug('[DEV]: teams.loadAllTeamReferencesAsync() >> ERROR', error)
      commit(InternalTeamsMutationInterface.TeamRefs, [])
    }
  },
  // Team Reference Filters
  clearFilters: function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    commit(InternalTeamsMutationInterface.ClearRefFilters)
  },
  setFilterTeamRef: function ({ state, rootState, commit, dispatch, getters, rootGetters }, teamRefId) {
    if (!teamRefId || (getters.hasFilterTeamRef && state.filterTeamRef.id === teamRefId)) return
    const filterTeamRef = state.teamRefs.find(teamRef => teamRef.id === teamRefId)
    commit(InternalTeamsMutationInterface.FilterTeamRef, filterTeamRef)
  },
  setFilterTeamRefMember: function ({ state, rootState, commit, dispatch, getters, rootGetters }, teamRefMemberId) {
    if (!teamRefMemberId || (getters.hasFilterTeamRefMember && state.filterTeamRefMember.id === teamRefMemberId)) return
    const filterTeamRef = state.teamRefs.find(teamRef => !!teamRef.members.find(refMember => refMember.id === teamRefMemberId))
    const filterTeamRefMember = filterTeamRef.members.find(refMember => refMember.id === teamRefMemberId)
    commit(InternalTeamsMutationInterface.FilterTeamRefMember, filterTeamRefMember)
  },
  expandFilterTeamRef: function ({ state, rootState, commit, dispatch, getters, rootGetters }, teamRefId) {
    if (!teamRefId || (getters.hasExpandedFilterTeamRef && state.expandedFilterTeamRef.id === teamRefId)) return
    const expandedFilterTeamRef = state.teamRefs.find(teamRef => teamRef.id === teamRefId)
    commit(InternalTeamsMutationInterface.ExpandedFilterTeamRef, expandedFilterTeamRef)
  },
  collapseFilterTeamRef: function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    if (!getters.hasExpandedFilterTeamRef) return
    commit(InternalTeamsMutationInterface.ExpandedFilterTeamRef, undefined)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
