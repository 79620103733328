import axios from 'axios'
import baseService from './baseService'
import config from './config'

const dealersProfileApiService = {
  loadCoreDealersAsync: async function () {
    try {
      const response = await axios.get(config.profileApiUrl + 'dealers?', baseService.defaultConfig)
      return response.data
    } catch (error) {
      Vue.$log.debug('[DEV]: dealersProfileApiService.loadCoreDealersAsync()', error)
      return error.response.data
    }
  }
}

export default dealersProfileApiService
