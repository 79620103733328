import Vue from 'vue'
import subscribersProfileApiService from '../../services/subscribersProfileApiService'
import rtApiService from '../../services/rtApiService'
import { InternalSubscriberMutationInterface } from '../storeInterfaces'

const state = {
  coreSubscriber: undefined,
  subscriber: undefined
}

const getters = {
  isInitialized: state => !!state.coreSubscriber && !!state.subscriber,
  // Subscriber
  hasCoreSubscriber: state => !!state.coreSubscriber,
  coreSubscriber: state => state.coreSubscriber,
  hasSubscriber: state => !!state.subscriber,
  subscriber: state => state.subscriber,
  // Dealers
  hasDealers: state => !!state.subscriber && Array.isArray(state.subscriber.dealers) && state.subscriber.dealers.length > 0,
  dealers: state => state.subscriber.dealers,
  dealerCodes: state => state.subscriber.dealers.map(dealer => dealer.code),
  hasDealerByCode: (state, getters, rootState, rootGetters) => (dealerCode) => state.subscriber.dealers.some(dealer => dealer.code === dealerCode),
  dealerByCode: (state, getters, rootState, rootGetters) => (dealerCode) => state.subscriber.dealers.find(dealer => dealer.code === dealerCode),
  // Teams
  hasTeamRefs: state => state.subscriber.dealers.some(dealer => Array.isArray(dealer.teams) && dealer.teams.length > 0),
  teams: state => state.subscriber.dealers.map(dealer => dealer.teams).flat(),
  hasTeamsByDealerCode: (state, getters, rootState, rootGetters) => (dealerCode) => {
    return state.subscriber.dealers.some(dealer => dealer.code === dealerCode && Array.isArray(dealer.teams) && dealer.teams.length > 0)
  },
  teamsByDealerCode: (state, getters, rootState, rootGetters) => (dealerCode) => {
    return state.subscriber.dealers.find(dealer => dealer.code === dealerCode).teams
  },
  // Team Members
  hasTeamMembersByDealerCode: (state, getters, rootState, rootGetters) => (dealerCode) => {
    return state.subscriber.dealers.some(dealer => dealer.code === dealerCode &&
      Array.isArray(dealer.teams) && dealer.teams.length > 0 &&
      dealer.teams.some(team => Array.isArray(team.members) && team.members.length > 0))
  },
  teamMembersByDealerCode: (state, getters, rootState, rootGetters) => (dealerCode) => state.subscriber.dealers.find(dealer => dealer.code === dealerCode).teams.map(team => team.members).flat(),
  hasTeamMembersByTeamId: (state, getters, rootState, rootGetters) => (teamId) => {
    return state.subscriber.dealers.some(dealer => Array.isArray(dealer.teams) && dealer.teams.length > 0 && // Any Dealers containing Teams
      dealer.teams.some(team => team.id === teamId && Array.isArray(team.members) && team.members.length > 0)) // Any Team matching TeamId with existing TeamMembers
  },
  teamMembersByTeamId: (state, getters, rootState, rootGetters) => (teamId) => {
    return state.subscriber.dealers.find(dealer => Array.isArray(dealer.teams) && dealer.teams.length > 0 && dealer.teams.some(team => team.id === teamId)) // Find Dealer containing Team with matching TeamId
      .teams.find(team => team.id === teamId).teamsMembers // Find TeamMembers in Team with matching TeamId
  },
  hasTeamMemberById: (state, getters, rootState, rootGetters) => (teamMemberId) => {
    return state.subscriber.dealers.map(dealer => dealer.teams).flat().map(team => team.members).flat().some(teamMember => teamMember.id === teamMemberId)
  },
  teamMemberById: (state, getters, rootState, rootGetters) => (teamMemberId) => {
    return state.subscriber.dealers.map(dealer => dealer.teams).flat().map(team => team.members).flat().find(teamMember => teamMember.id === teamMemberId)
  }
}

const mutations = {
  [InternalSubscriberMutationInterface.Reset] (state) {
    state.coreSubscriber = undefined
    state.subscriber = undefined
  },
  // Subscriber
  [InternalSubscriberMutationInterface.CoreSubscriber] (state, coreSubscriber) {
    if (!coreSubscriber) return
    state.coreSubscriber = { ...coreSubscriber }
  },
  [InternalSubscriberMutationInterface.Subscriber] (state, subscriber) {
    if (!subscriber) return
    state.subscriber = { ...subscriber }
  }
}

const actions = {
  reset: function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    commit(InternalSubscriberMutationInterface.Reset)
  },
  // Subscriber
  loadCoreSubscriberAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }, coreSubscriberId) {
    try {
      const response = await subscribersProfileApiService.coreSingleAsync(coreSubscriberId)
      Vue.$log.debug('[DEV]: subscriber.loadCoreSubscriberAsync(coreSubscriberId)', coreSubscriberId, response)
      commit(InternalSubscriberMutationInterface.CoreSubscriber, response)
    } catch (error) {
      Vue.$log.debug('[DEV]: subscriber.loadCoreSubscriberAsync(coreSubscriberId) >> ERROR', coreSubscriberId, error)
      commit(InternalSubscriberMutationInterface.Reset)
    }
  },
  loadSubscriberAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }, subscriberId) {
    const response = await rtApiService.loadSubscriberAsync(subscriberId)
    if (response instanceof Error) {
      Vue.$log.debug('[DEV]: subscriber.loadSubscriberAsync(subscriberId) >> ERROR', subscriberId, response)
      commit(InternalSubscriberMutationInterface.Reset)
    }
    // Vue.$log.debug('[DEV]: subscriber.loadSubscriberAsync(subscriberId)', subscriberId, response)
    commit(InternalSubscriberMutationInterface.Subscriber, response)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
