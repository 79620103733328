<template>
  <div style="display: grid; grid-template-rows: auto; grid-template-columns: 1fr 1fr 1fr; background: white !important;border-top: 1px solid #ececf0; padding: 0 !important;">
    <div style="grid-row:1;grid-column:1;">
      <portal-target name="footer-toolbar-left" class="actions" @change="onPortalLeftChanged" />
    </div>
    <div style="grid-row:1;grid-column:2;">
      <portal-target name="footer-toolbar-middle" @change="onPortalMiddleChanged" slim />
    </div>
    <div style="grid-row:1;grid-column:2;">
      <portal-target name="footer-toolbar-right" @change="onPortalRightChanged" slim />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
  data () {
    return {
      leftCount: 0,
      midCount: 0,
      rightCount: 0
    }
  },
  methods: {
    onPortalLeftChanged: function (from) {
      if (from && from.passengers) {
        this.leftCount = from.passengers.length
      } else {
        this.leftCount = 0
      }
    },
    onPortalMiddleChanged: function (from) {
      if (from && from.passengers) {
        this.midCount = from.passengers.length
      } else {
        this.midCount = 0
      }
    },
    onPortalRightChanged: function (from) {
      if (from && from.passengers) {
        this.rightCount = from.passengers.length
      } else {
        this.rightCount = 0
      }
    }
  }
}
</script>
