import Vue from 'vue'
import {
  DefaultClientsDynamicCriteriaRequest
} from '../../definitions'
import {
  ClientStatusEnum,
  MentionStatusTypeEnum,
  ClientsFilterTypeEnum,
  FilterSubTypeEnum,
  WaitingTypeEnum,
  WatchListWaitingCriteriaSortEnum,
  WatchListStatusEnum
} from '../../enums'
import {
  InternalClientsCriteriaMutationInterface,
  UsersGetterInterface,
  ClientsGetterInterface
} from '../storeInterfaces'

const state = {
  criteria: undefined,
  searchTags: []
}

const getters = {
  isInitialized: state => !!state.criteria,
  criteria: state => state.criteria,
  isClientInFilterScope: (state, getters, rootState, rootGetters) => (client) => {
    if (typeof client !== 'object') return false

    Vue.$log.debug(`[DEV]: ${'clientsCriteria'}.isClientInFilterScope(client) >> Validate Client Id`, client.id)

    if (!!getters.clientId && client.id !== getters.clientId) {
      Vue.$log.debug(`[DEV]: ${'clientsCriteria'}.isClientInFilterScope(client) >> FAILED >> Client Id Mismatch`, client.id, getters.clientId)
      return false
    }
    if (new Date(client.updatedAt) <= new Date(getters.startDate)) {
      Vue.$log.debug(`[DEV]: ${'clientsCriteria'}.isClientInFilterScope(client) >> FAILED >> UpdatedAt <= StartDate`, client.updatedAt, getters.startDate)
      return false
    }
    if (new Date(client.updatedAt) > new Date(getters.endDate)) {
      Vue.$log.debug(`[DEV]: ${'clientsCriteria'}.isClientInFilterScope(client) >> FAILED >> UpdatedAt > EndDate`, client.updatedAt, getters.endDate)
      return false
    }
    if (client.clientType !== getters.clientType) {
      Vue.$log.debug(`[DEV]: ${'clientsCriteria'}.isClientInFilterScope(client) >> FAILED >> ClientType Mismatch`, client.clientType, getters.clientType)
      return false
    }
    if (![ClientsFilterTypeEnum.All, ClientsFilterTypeEnum.Unassigned, ClientsFilterTypeEnum.Search].includes(getters.filterType)) {
      if (!client.primaryAssignedUserId && !client.primaryAssignedTeamId) {
        Vue.$log.debug(`[DEV]: ${'clientsCriteria'}.isClientInFilterScope(client) >> FAILED >> Missing Assigned Clients`)
        return false
      }
    }
    if (getters.filterType !== ClientsFilterTypeEnum.Mentions && !getters.statuses.includes(ClientStatusEnum.All) && [ClientStatusEnum.Open, ClientStatusEnum.Closed, ClientStatusEnum.Snoozed].includes(client.status)) {
      if (!getters.statuses.includes(client.status)) {
        Vue.$log.debug(`[DEV]: ${'clientsCriteria'}.isClientInFilterScope(client) >> FAILED >> Status Mismatch`, client.status, getters.statuses)
        return false
      }
    }
    if (getters.filterSubType === FilterSubTypeEnum.Inventory && !client.hasPrimaryVehiclesOfInterest) {
      Vue.$log.debug(`[DEV]: ${'clientsCriteria'}.isClientInFilterScope(client) >> FAILED >> Inventory FilterSubType >> Missing VoI`)
      return false
    }
    if (getters.dealerCodes.lemgth > 0 && !getters.dealerCodes.includes(client.dealer.code)) {
      Vue.$log.debug(`[DEV]: ${'clientsCriteria'}.isClientInFilterScope(client) >> FAILED >> DealerCode Mismatch`, getters.dealerCodes, client.dealer.code)
      return false
    }
    if (getters.filterType === ClientsFilterTypeEnum.You) {
      if (client.primaryAssignedUserId && client.primaryAssignedUserId !== rootGetters[UsersGetterInterface.profileUser].id && !client.hasUserMentions) {
        Vue.$log.debug(`[DEV]: ${'clientsCriteria'}.isClientInFilterScope(client) >> FAILED >> You >> PrimaryAssignedUser Mismatch`, rootGetters[UsersGetterInterface.profileUser].id, client.primaryAssignedUserId, client.hasUserMentions)
        return false
        // TODO: Validate client.primaryAssignedTeamId
        // } else if (client.primaryAssignedTeamId && !client.hasUserMentions) {
        //   Vue.$log.debug(`[DEV]: ${'clientsCriteria'}.isClientInFilterScope(client) >> FAILED >> You >> Missing PrimaryAssignedTeam Mentions`)
        //   return false
      }
    } else if (getters.filterType === ClientsFilterTypeEnum.Snoozed) {
      if (!client.primaryAssignedUserId) {
        Vue.$log.debug(`[DEV]: ${'clientsCriteria'}.isClientInFilterScope(client) >> FAILED >> Snoozed >> Missing PrimaryAssignedUser`)
        return false
      }
      if (client.primaryAssignedUserId !== rootGetters[UsersGetterInterface.profileUser].id) {
        Vue.$log.debug(`[DEV]: ${'clientsCriteria'}.isClientInFilterScope(client) >> FAILED >> Snoozed >> PrimaryAssignedUser Mismatch`, client.primaryAssignedUserId, rootGetters[UsersGetterInterface.profileUser].id)
        return false
      }
      if (client.status !== ClientStatusEnum.Snoozed) {
        Vue.$log.debug(`[DEV]: ${'clientsCriteria'}.isClientInFilterScope(client) >> FAILED >> Snoozed >> Status Mismatch`, client.status)
        return false
      }
    } else if (getters.filterType === ClientsFilterTypeEnum.Mentions) {
      if (!client.primaryAssignedUserId) {
        Vue.$log.debug(`[DEV]: ${'clientsCriteria'}.isClientInFilterScope(client) >> FAILED >> Mentions >> Missing PrimaryAssignedUser`)
        return false
      }
      if (!client.hasUserMentions) {
        Vue.$log.debug(`[DEV]: ${'clientsCriteria'}.isClientInFilterScope(client) >> FAILED >> Mentions >> Missing Mentions`)
        return false
      }
      if (getters.statuses.includes(MentionStatusTypeEnum.Unread) && !client.hasUnreadUserMentions) {
        Vue.$log.debug(`[DEV]: ${'clientsCriteria'}.isClientInFilterScope(client) >> FAILED >> Mentions >> Unread >> Missing Unread Mentions`)
        return false
      }
    } else if (getters.filterType === ClientsFilterTypeEnum.WatchList) {
      if (!client.primaryAssignedUserId) {
        Vue.$log.debug(`[DEV]: ${'clientsCriteria'}.isClientInFilterScope(client) >> FAILED >> WatchList >> Missing PrimaryAssignedUser`)
        return false
      }
      if (getters.watchListStatus === WatchListStatusEnum.Active && !client.hasActiveUserWatchList) {
        Vue.$log.debug(`[DEV]: ${'clientsCriteria'}.isClientInFilterScope(client) >> FAILED >> WatchList >> Active >> Missing Active WatchList`)
        return false
      }
      if (getters.watchListStatus === WatchListStatusEnum.Removed && !client.hasRemovedUserWatchList) {
        Vue.$log.debug(`[DEV]: ${'clientsCriteria'}.isClientInFilterScope(client) >> FAILED >> WatchList >> Removed >> Missing Removed WatchList `)
        return false
      }
    } else if (getters.filterType === ClientsFilterTypeEnum.Unassigned && (client.primaryAssignedUserId || client.primaryAssignedTeamId)) {
      Vue.$log.debug(`[DEV]: ${'clientsCriteria'}.isClientInFilterScope(client) >> FAILED >> Unassigned >> Missing Unassigned Clients`)
      return false
    } else if (getters.filterType === ClientsFilterTypeEnum.Critical) {
      // TODO: Validate client date(s) = getters.waitingType > WaitingTypeEnum
    }

    return true
  },
  hasFilterSubType: state => state.criteria.filterSubType !== FilterSubTypeEnum.Customer,
  // Criteria Properties
  clientId: state => state.criteria.clientId,
  queryText: state => state.criteria.queryText,
  sortBy: state => state.criteria.sortBy,
  startDate: state => state.criteria.startDate,
  endDate: state => state.criteria.endDate,
  clientType: state => state.criteria.clientType,
  filterType: state => state.criteria.filterType,
  filterSubType: state => state.criteria.filterSubType,
  waitingType: state => state.criteria.waitingType,
  waitingSortOrder: state => state.criteria.waitingSortOrder,
  watchListStatus: state => state.criteria.watchListStatus,
  statuses: state => state.criteria.statuses,
  assignedUsers: state => state.criteria.assignedUsers,
  assignedTeams: state => state.criteria.assignedTeams,
  dealerCodes: state => state.criteria.dealerCodes,
  isGrouped: state => state.criteria.isGrouped,
  // SearchTags Properties
  hasSearchTags: state => Array.isArray(state.searchTags) && state.searchTags.lemgth > 0,
  searchTags: state => state.searchTags
}

const mutations = {
  [InternalClientsCriteriaMutationInterface.Reset]: function (state) {
    state.criteria = undefined
    state.searchTags = []
  },
  [InternalClientsCriteriaMutationInterface.SearchTags]: function (state, payload) {
    if (!Array.isArray(payload)) return
    state.searchTags = [...payload]
  },
  [InternalClientsCriteriaMutationInterface.Criteria]: function (state, payload) {
    if (!payload) return
    state.criteria = { ...payload }
    // state.searchTags = state.criteria.searchTags
  }
}

const actions = {
  reset: function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    commit(InternalClientsCriteriaMutationInterface.Reset)
  },
  setCriteria: function ({ state, rootState, commit, dispatch, getters, rootGetters }, criteria) {
    if (!criteria) return
    commit(InternalClientsCriteriaMutationInterface.Criteria, criteria)
  },
  clearSearchTags: function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    getters.criteria.searchTags = []
    commit(InternalClientsCriteriaMutationInterface.Criteria, getters.criteria)
  },
  setSearchTags: function ({ state, rootState, commit, dispatch, getters, rootGetters }, searchTags) {
    if (!Array.isArray(searchTags)) return
    commit(InternalClientsCriteriaMutationInterface.SearchTags, searchTags)
    if (getters.criteria.filterType === ClientsFilterTypeEnum.Search) {
      const criteria = { ...getters.criteria }
      criteria.searchTags = [...searchTags]
      commit(InternalClientsCriteriaMutationInterface.Criteria, criteria)
    }
  },
  setCriteriaByFilterType: function ({ state, rootState, commit, dispatch, getters, rootGetters }, filterType) {
    filterType = !!filterType || filterType >= 0 ? filterType : ClientsFilterTypeEnum.You
    const criteria = JSON.parse(JSON.stringify(DefaultClientsDynamicCriteriaRequest))

    if (filterType === ClientsFilterTypeEnum.You) {
      criteria.filterType = ClientsFilterTypeEnum.You
      criteria.statuses = [ClientStatusEnum.Open]
      criteria.assignedUsers = [rootGetters[UsersGetterInterface.profileUser].id]
    } else if (filterType === ClientsFilterTypeEnum.Snoozed) {
      criteria.filterType = ClientsFilterTypeEnum.Snoozed
      criteria.statuses = [ClientStatusEnum.Snoozed]
      criteria.assignedUsers = [rootGetters[UsersGetterInterface.profileUser].id]
    } else if (filterType === ClientsFilterTypeEnum.WatchList) {
      criteria.filterType = ClientsFilterTypeEnum.WatchList
      criteria.statuses = [ClientStatusEnum.All]
      criteria.waitingSortOrder = WatchListWaitingCriteriaSortEnum.LastAdded
      criteria.assignedUsers = [rootGetters[UsersGetterInterface.profileUser].id]
    } else if (filterType === ClientsFilterTypeEnum.Mentions) {
      criteria.filterType = ClientsFilterTypeEnum.Mentions
      criteria.statuses = [MentionStatusTypeEnum.Unread]
      criteria.assignedUsers = [rootGetters[UsersGetterInterface.profileUser].id]
    } else if (filterType === ClientsFilterTypeEnum.Critical) {
      criteria.filterType = ClientsFilterTypeEnum.Critical
      criteria.statuses = [ClientStatusEnum.Open]
      criteria.waitingType = WaitingTypeEnum.Waiting
    } else if (filterType === ClientsFilterTypeEnum.All) {
      criteria.filterType = ClientsFilterTypeEnum.All
      criteria.statuses = [ClientStatusEnum.Open]
    } else if (filterType === ClientsFilterTypeEnum.Unassigned) {
      criteria.filterType = ClientsFilterTypeEnum.Unassigned
      criteria.statuses = [ClientStatusEnum.Open]
      criteria.assignedUsers = ['000000000000000000000000']
      criteria.assignedTeams = ['000000000000000000000000']
    } else if (filterType === ClientsFilterTypeEnum.Team) {
      criteria.filterType = ClientsFilterTypeEnum.Team
      criteria.statuses = [ClientStatusEnum.Open]
      criteria.isGrouped = true
      criteria.assignedTeams = [rootGetters[ClientsGetterInterface.routeTeamId]]
    } else if (filterType === ClientsFilterTypeEnum.TeamMember) {
      criteria.filterType = ClientsFilterTypeEnum.TeamMember
      criteria.statuses = [ClientStatusEnum.Open]
      criteria.assignedUsers = [rootGetters[ClientsGetterInterface.routeUserId]]
    } else if (filterType === ClientsFilterTypeEnum.ByUser) { // TODO
      criteria.filterType = ClientsFilterTypeEnum.ByUser
      criteria.statuses = [ClientStatusEnum.Open]
      criteria.assignedUsers = [rootGetters[UsersGetterInterface.profileUser].id]
    } else if (filterType === ClientsFilterTypeEnum.PinnedUser) { // TODO
      criteria.filterType = ClientsFilterTypeEnum.PinnedUser
      criteria.statuses = [ClientStatusEnum.Open]
      criteria.assignedUsers = [rootGetters[UsersGetterInterface.profileUser].id]
    } else if (filterType === ClientsFilterTypeEnum.Search) {
      criteria.filterType = ClientsFilterTypeEnum.Search
      criteria.searchTags = getters.searchTags
    }

    criteria.dealerCodes = rootGetters[UsersGetterInterface.dealerCodes]

    commit(InternalClientsCriteriaMutationInterface.Criteria, criteria)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
