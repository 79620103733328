import Vue from 'vue'
import axios from 'axios'
import baseService from './baseService'
import config from './config'

const inventoriesProfileApiService = {
  loadAllAsync: async function (searchCriteria) {
    const response = await axios.post(config.profileApiUrl + 'inventory/list', searchCriteria, baseService.defaultConfig)
    return response.data
  },
  all (searchCriteria) {
    return new Promise((resolve, reject) => {
      axios
        .post(config.profileApiUrl + 'inventory/list', searchCriteria, baseService.defaultConfig)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response.data)
        })
    })
  },
  singleAsync: async function (inventoryId) {
    try {
      const response = await axios.get(config.profileApiUrl + 'inventory/get?id=' + inventoryId, baseService.defaultConfig)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  single (inventoryId) {
    return new Promise((resolve, reject) => {
      axios
        .get(config.profileApiUrl + 'inventory/get?id=' + inventoryId, baseService.defaultConfig)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response.data)
        })
    })
  },
  allLite () {
    return new Promise((resolve, reject) => {
      axios
        .post(config.profileApiUrl + 'inventory/listlite', baseService.defaultConfig)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response.data)
        })
    })
  },
  updateTitleStatus (inventory) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post(config.profileApiUrl + 'inventory/updateTitleStatus', inventory, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateWindowStickerStatus (inventory) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post(config.profileApiUrl + 'inventory/updateWindowStickerStatus', inventory, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateMileage (inventoryUsageModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post(config.profileApiUrl + 'inventory/updateMileage', { inventoryUsageModel }, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  capturePurchaseOffer (inventoryPurchaseModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post(config.profileApiUrl + 'inventory/capturePurchaseOffer', inventoryPurchaseModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getPurchaseOffers (inventory) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .get(config.profileApiUrl + 'inventory/getPurchaseOffers?id=' + inventory.id, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getTitleInfo (inventory) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .get(config.profileApiUrl + 'inventory/getTitleInfo?id=' + inventory.id, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getWindowStickerInfo (inventory) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .get(config.profileApiUrl + 'inventory/getWindowStickerInfo?id=' + inventory.id, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getAllAccessoryEventsByInventory (inventory) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve({})
      } else {
        axios
          .post(config.profileApiUrl + 'inventory/getAllAccessoryEvents?id=' + inventory.id, { ...baseService.defaultConfig, requestId: 'inventory-accessory-events' })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              Vue.$log.debug('[DEV]: inventory all accessory events load cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  getPricingHistory (inventory, type) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve([])
      } else {
        axios
          .post(config.profileApiUrl + 'inventory/getPricingHistory', { inventoryId: inventory.id, pricingType: type }, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  addNote (inventoryNoteModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        const inventory = [].all.find((x) => x.id === inventoryNoteModel.inventoryId)
        if (inventory) {
          inventory.notes.push(inventoryNoteModel)
        }
        resolve(inventory)
      } else {
        axios
          .post(config.profileApiUrl + 'inventory/notes', inventoryNoteModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  saveAccessoryEvent (inventoryAccessoryModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve({})
      } else {
        axios
          .post(config.profileApiUrl + 'inventory/saveAccessoryEvent', inventoryAccessoryModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default inventoriesProfileApiService
