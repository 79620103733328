import { CrmRouteEnum } from '../enums'
import {
  AuthorizationGetterInterface
} from '../store/storeInterfaces'
import Vue from 'vue'
import store from '../store/storeIndex'

function validateAuthorization (to, from, next) {
  // Vue.$log.debug('[DEV]: routeGuards.validateAuthorization(to, from, next)', to, from)
  if (!store.getters[AuthorizationGetterInterface.isAuthenticated] && to.name !== 'Loader' && to.name !== 'Login') {
    Vue.$log.debug('[DEV]: routeGuards.validateAuthorization(to, from, next) >> GOTO LOADER PATH', CrmRouteEnum.LoaderRoute)
    next({ path: CrmRouteEnum.LoaderRoute })
  } else {
    Vue.$log.debug('[DEV]: routeGuards.validateAuthorization(to, from, next) >> GOTO NEXT PATH', to.fullPath)
    next()
  }
}

export default validateAuthorization
