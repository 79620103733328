import {
  AuthorizationActionInterface
} from '../store/storeInterfaces'
import axios from 'axios'
import axiosCancel from 'axios-cancel'
import config from './config'
import { DefaultAppConfig } from '@/definitions'
import store from '@/store/storeIndex'

const isTestMode = false
axios.defaults.baseURL = config.crmApiUrl
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common['X-App-Version'] = DefaultAppConfig.appVersion

const defaultConfig = { }

axiosCancel(axios, { debug: false })

// before a request is made
axios.interceptors.request.use(config => { return config })

// before a response is returned
axios.interceptors.response.use(
  response => { return response },
  error => {
    if (error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
      store.dispatch(AuthorizationActionInterface.logoutAsync).then()
    }
    return Promise.reject(error)
  })

export default { isTestMode, defaultConfig }
