import Vue from 'vue'
import usersProfileApiService from '../../services/usersProfileApiService.js'
import rtApiService from '../../services/rtApiService.js'
import jstz from 'jstimezonedetect'
import { InternalUsersMutationInterface } from '../storeInterfaces.js'

const TemplateProfileUserSettings = {
  dealerCodes: []
}

const state = {
  coreProfileUser: undefined,
  profileUser: undefined,
  users: [],
  userReferences: [],
  defaultTimeZone: undefined
}

const getters = {
  isInitialized: state => !!state.coreProfileUser || !!state.profileUser,
  hasCoreProfileUser: state => !!state.coreProfileUser,
  coreProfileUser: state => state.coreProfileUser,
  hasProfileUser: state => !!state.profileUser,
  profileUser: state => state.profileUser,
  hasUsers: state => Array.isArray(state.users) && state.users.length > 0,
  users: state => state.users,
  user: (state, getters, rootState, rootGetters) => (userId) => {
    if (!getters.hasUsers) return undefined
    return getters.users.find(user => user.id === userId)
  },
  hasUserReferences: state => Array.isArray(state.userReferences) && state.userReferences.length > 0,
  userReferences: state => state.userReferences,
  userReference: (state, getters, rootState, rootGetters) => (userId) => {
    if (!getters.hasUserReferences) return undefined
    return getters.userReferences.find(userRef => userRef.id === userId)
  },
  defaultTimeZone: state => state.defaultTimeZone,
  // Profile Settings
  hasProfileSettings: state => !!state.profileUser && !!state.profileUser.profile && !!state.profileUser.profile.settings,
  profileSettings: state => state.profileUser.profile.settings, // JSON.parse(state.profileUser.profile.settings)
  hasAllDealerCodes: state => !!state.profileUser.profile.settings && Array.isArray(state.profileUser.profile.settings.dealerCodes) && state.profileUser.profile.settings.dealerCodes.length === 0,
  hasDealerCodes: state => !!state.profileUser.profile.settings && Array.isArray(state.profileUser.profile.settings.dealerCodes) && state.profileUser.profile.settings.dealerCodes.length > 0,
  dealerCodes: state => state.profileUser.profile.settings.dealerCodes
}

const mutations = {
  [InternalUsersMutationInterface.Reset] (state, newUser) {
    state.coreProfileUser = undefined
    state.profileUser = undefined
    state.users = []
    state.userReferences = []
    state.defaultTimeZone = undefined
  },
  [InternalUsersMutationInterface.CoreProfileUser] (state, coreUser) {
    state.coreProfileUser = coreUser
  },
  [InternalUsersMutationInterface.ProfileUser] (state, user) {
    state.profileUser = user
    if (!state.profileUser.profile) {
      state.profileUser.profile = { settings: TemplateProfileUserSettings }
    } else {
      state.profileUser.profile.settings = JSON.parse(state.profileUser.profile.settings)
    }
  },
  [InternalUsersMutationInterface.Users] (state, users) {
    state.users = [...users]
  },
  [InternalUsersMutationInterface.UserReferences] (state, userRefs) {
    state.userReferences = [...userRefs]
  },
  [InternalUsersMutationInterface.DefaultTimeZone] (state, timeZone) {
    state.defaultTimeZone = timeZone
  },
  [InternalUsersMutationInterface.DealerCodes] (state, dealerCodes) {
    state.profileUser.profile.settings.dealerCodes = [...dealerCodes]
  }
}

const actions = {
  reset: function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    commit(InternalUsersMutationInterface.Reset)
  },
  loadCoreProfileUserAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    try {
      const response = await usersProfileApiService.loadCoreProfileUserAsync()
      Vue.$log.debug('[DEV]: users.loadCoreProfileUserAsync()', response)
      commit(InternalUsersMutationInterface.CoreProfileUser, response)
    } catch (error) {
      Vue.$log.debug('[DEV]: users.loadCoreProfileUserAsync() >> ERROR', error)
      commit(InternalUsersMutationInterface.CoreProfileUser, undefined)
    }
  },
  loadProfileUserAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    if (!state.coreProfileUser) return
    const response = await rtApiService.loadUserBySqlIdAsync(state.coreProfileUser.id)
    if (response instanceof Error) {
      Vue.$log.debug('[DEV]: users.loadProfileUserAsync() >> ERROR', response)
      commit(InternalUsersMutationInterface.ProfileUser, undefined)
    }
    // Vue.$log.debug('[DEV]: users.loadProfileUserAsync()')
    commit(InternalUsersMutationInterface.ProfileUser, response)
  },
  loadAllUsersAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    const response = await rtApiService.getAllUsersAsync()
    if (response instanceof Error) {
      Vue.$log.debug('[DEV]: users.loadAllUsersAsync() >> ERROR', response)
      commit(InternalUsersMutationInterface.Users, [])
    }
    // Vue.$log.debug('[DEV]: users.loadAllUsersAsync()')
    commit(InternalUsersMutationInterface.Users, response)
  },
  loadAllUserReferencesAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    const response = await rtApiService.getAllUserReferencesAsync()
    if (response instanceof Error) {
      Vue.$log.debug('[DEV]: users.loadAllUserReferencesAsync() >> ERROR', response)
      commit(InternalUsersMutationInterface.UserReferences, [])
    }
    // Vue.$log.debug('[DEV]: users.loadAllUserReferencesAsync()')
    commit(InternalUsersMutationInterface.UserReferences, response)
  },
  setDefaultTimeZone: function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    // Vue.$log.debug('[DEV]: users.setDefaultTimeZone()')
    const detected = jstz.determine().name()
    const currentSubscriber = rootState.subscriber.currentSubscriber
    const currentDealer = rootState.dealer.currentDealer
    const timeZoneOptions = [currentDealer.timezone, currentSubscriber.timezone, detected]
    const timeZone = timeZoneOptions.find((x) => x && x !== '')
    // moment.tz.setDefault(timeZone)
    commit(InternalUsersMutationInterface.DefaultTimeZone, timeZone)
  },
  updateDealerCodesAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }, dealerCodes) {
    if (!Array.isArray(dealerCodes)) return
    Vue.$log.debug('[DEV]: users.updateDealerCodesAsync() >> START', dealerCodes)
    commit(InternalUsersMutationInterface.DealerCodes, dealerCodes)
    const response = await rtApiService.updateUserSettingsAsync(getters.profileUser.id, JSON.stringify(getters.profileSettings))
    if (!response || response instanceof Error) {
      Vue.$log.debug('[DEV]: users.updateDealerCodesAsync() >> ERROR', response)
    }
    Vue.$log.debug('[DEV]: users.updateDealerCodesAsync() >> END', getters.profileSettings)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
