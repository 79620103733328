import Vue from 'vue'
import { CrmRouteEnum } from '../../enums'
import {
  InternalSystemMutationInterface,
  SystemActionInterface,
  AuthorizationGetterInterface,
  SubscriberGetterInterface,
  SubscriberActionInterface,
  UsersGetterInterface,
  UsersActionInterface,
  DealersGetterInterface,
  DealersActionInterface,
  TeamsGetterInterface,
  TeamsActionInterface,
  UiGetterInterface,
  UiActionInterface,
  ClientsCriteriaGetterInterface,
  ClientsCriteriaActionInterface,
  ClientsGetterInterface,
  ClientsActionInterface,
  AdministrationActionInterface
} from '../storeInterfaces'
import rtApiService from '../../services/rtApiService'

const state = {
  isSystemInitialized: false,
  connectionStatus: 'Disconnected',
  route: undefined
}

const getters = {
  isLocal: state => !!process.env.VUE_APP_CRM_API && process.env.VUE_APP_CRM_API.includes('localhost'),
  isDevelopment: state => process.env.NODE_ENV === 'development',
  isStaging: state => process.env.NODE_ENV === 'staging',
  isProduction: state => process.env.NODE_ENV === 'production',
  isSystemInitialized: state => state.isSystemInitialized,
  connectionStatus: state => state.connectionStatus,
  hasRoute: state => !!state.route,
  route: state => state.route,
  hasRouteFilter: state => !!state.route && !!state.route.path && state.route.path.startsWith('/'),
  routeFilter: state => {
    if (!!state.route && !!state.route.path && state.route.path.startsWith('/')) return state.route.path.split('/')[1]
    return ''
  }
}

const mutations = {
  [InternalSystemMutationInterface.SystemReady]: (state, isReady) => {
    state.isSystemInitialized = isReady
  },
  [InternalSystemMutationInterface.ConnectionStatus]: (state, status) => {
    state.connectionStatus = status
  },
  [InternalSystemMutationInterface.Route] (state, payload) {
    state.route = payload
  }
}

const actions = {
  systemReset: function ({ state, rootState, commit, dispatch, getters, rootGetters }) { // Reset all in Reverse Order
    commit(InternalSystemMutationInterface.SystemReady, false)

    // Reset Clients
    if (rootGetters[ClientsGetterInterface.isInitialized]) dispatch(ClientsActionInterface.reset, null, { root: true })
    // Reset ClientsCriteria
    if (rootGetters[ClientsCriteriaGetterInterface.isInitialized]) dispatch(ClientsCriteriaActionInterface.reset, null, { root: true })
    // Reset Teams
    if (rootGetters[TeamsGetterInterface.isInitialized]) dispatch(TeamsActionInterface.reset, null, { root: true })
    // Reset Dealers
    if (rootGetters[DealersGetterInterface.isInitialized]) dispatch(DealersActionInterface.reset, null, { root: true })
    // Reset Users (+ Users + User-References)
    if (rootGetters[UsersGetterInterface.hasCoreProfileUser]) dispatch(UsersActionInterface.reset, null, { root: true })
    // Reset Subscriber
    if (rootGetters[SubscriberGetterInterface.isInitialized]) dispatch(SubscriberActionInterface.reset, null, { root: true })

    commit(InternalSystemMutationInterface.Route, undefined)
  },
  systemInitializeAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    // Verifications
    let canProceed = (!getters.isSystemInitialized || !rootGetters[AuthorizationGetterInterface.isAuthenticated])
    if (!canProceed) {
      Vue.$log.debug(`[DEV]: system.systemInitializeAsync() >> FAILED >> isSystemInitialized: ${getters.isSystemInitialized}, isAuthenticated: ${rootGetters[AuthorizationGetterInterface.isAuthenticated]}`)
      return
    }

    // Load ProfileApi User
    if (canProceed) await dispatch(UsersActionInterface.loadCoreProfileUserAsync, null, { root: true })
    // Vue.$log.debug('[DEV]: system.systemInitializeAsync()', rootGetters[UsersGetterInterface.hasCoreProfileUser])
    canProceed &= rootGetters[UsersGetterInterface.hasCoreProfileUser]
    if (!canProceed) Vue.$log.debug(`[DEV]: system.systemInitializeAsync() >> ${UsersActionInterface.loadCoreProfileUserAsync} >> FAILED`)

    // Load ProfileApi Subscriber
    if (canProceed) await dispatch(SubscriberActionInterface.loadCoreSubscriberAsync, rootGetters[UsersGetterInterface.coreProfileUser].subscriberId, { root: true })
    canProceed &= rootGetters[SubscriberGetterInterface.hasCoreSubscriber]
    if (!canProceed) Vue.$log.debug(`[DEV]: system.systemInitializeAsync() >> ${SubscriberActionInterface.loadCoreSubscriberAsync} >> FAILED`)

    // Load ProfileApi Dealers
    if (canProceed) await dispatch(DealersActionInterface.loadCoreDealersAsync, null, { root: true })
    canProceed &= rootGetters[DealersGetterInterface.isInitialized]
    if (!canProceed) Vue.$log.debug(`[DEV]: system.systemInitializeAsync() >> ${DealersActionInterface.loadCoreDealersAsync} >> FAILED`)

    // SignalR Initialization (rtApiService)
    // Vue.$log.debug('[DEV]: system.systemInitializeAsync() >> SignalR Initialization', rootGetters[AuthorizationGetterInterface.token])
    if (canProceed) {
      const rtStatus = await rtApiService.configureSocketAsync(rootGetters[AuthorizationGetterInterface.token])
      canProceed &= rtStatus.isConnected
    }
    if (!canProceed) Vue.$log.debug(`[DEV]: system.systemInitializeAsync() >> rtApiService.configureSocketAsync(${rootGetters[AuthorizationGetterInterface.token]}) >> FAILED`)

    // Load User
    if (canProceed) await dispatch(UsersActionInterface.loadProfileUserAsync, null, { root: true })
    canProceed &= rootGetters[UsersGetterInterface.hasProfileUser]
    if (!canProceed) Vue.$log.debug(`[DEV]: system.systemInitializeAsync() >> ${UsersActionInterface.loadProfileUserAsync} >> FAILED`)

    // Load Users
    if (canProceed) await dispatch(UsersActionInterface.loadAllUsersAsync, null, { root: true })
    canProceed &= rootGetters[UsersGetterInterface.hasUsers]
    if (!canProceed) Vue.$log.debug(`[DEV]: system.systemInitializeAsync() >> ${UsersActionInterface.loadAllUsersAsync} >> FAILED`)

    // Load User-References
    if (canProceed) await dispatch(UsersActionInterface.loadAllUserReferencesAsync, null, { root: true })
    canProceed &= rootGetters[UsersGetterInterface.hasUserReferences]
    if (!canProceed) Vue.$log.debug(`[DEV]: system.systemInitializeAsync() >> ${UsersActionInterface.loadAllUserReferencesAsync} >> FAILED`)

    // Load Subscriber
    if (canProceed) await dispatch(SubscriberActionInterface.loadSubscriberAsync, rootGetters[UsersGetterInterface.profileUser].subscriber.id, { root: true })
    canProceed &= rootGetters[SubscriberGetterInterface.hasSubscriber]
    if (!canProceed) Vue.$log.debug(`[DEV]: system.systemInitializeAsync() >> ${SubscriberActionInterface.loadSubscriberAsync} >> FAILED`)

    // Load Teams
    if (canProceed) await dispatch(TeamsActionInterface.loadAllTeamReferencesAsync, null, { root: true })
    canProceed &= rootGetters[TeamsGetterInterface.isInitialized]
    if (!canProceed) Vue.$log.debug(`[DEV]: system.systemInitializeAsync() >> ${TeamsActionInterface.loadAllTeamReferencesAsync} >> FAILED`)

    // Load UI Settings
    if (canProceed) await dispatch(UiActionInterface.initialize, null, { root: true })
    canProceed &= rootGetters[UiGetterInterface.isInitialized]
    if (!canProceed) Vue.$log.debug(`[DEV]: system.systemInitializeAsync() >> ${UiActionInterface.initialize} >> FAILED`)

    // ? CoreVehicleMakes
    // ? CoreInventories
    // ? Signatures
    // ? Clear Draft Messages
    // ? Departments
    // ? Designations
    // ? WatchList

    if (!canProceed) {
      dispatch(SystemActionInterface.systemReset, null, { root: true })
    } else {
      commit(InternalSystemMutationInterface.SystemReady, true)
    }
  },
  setConnectionStatus: function ({ state, rootState, commit, dispatch, getters, rootGetters }, status) {
    commit(InternalSystemMutationInterface.ConnectionStatus, status)
    // Vue.$log.debug('[DEV]: store.global.setConnectionStatus()', status)
  },
  setRouteAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }, route) {
    Vue.$log.debug('[DEV]: system.setRouteAsync(route)', route)
    if (!route || (getters.hasRoute && getters.route.fullPath === route.fullPath)) return
    commit(InternalSystemMutationInterface.Route, route)
    Vue.$log.debug('[DEV]: system.setRouteAsync(route) >> getters.routeFilter', getters.routeFilter)

    // Conditional Resets Clients
    if (rootGetters[ClientsGetterInterface.isInitialized] && getters.hasRouteFilter && getters.routeFilter !== CrmRouteEnum.Clients) {
      dispatch(ClientsActionInterface.reset, null, { root: true })
    }

    // Conditional Route Initializations
    if (getters.routeFilter === CrmRouteEnum.Clients) {
      await dispatch(ClientsActionInterface.setRouteParametersAsync, getters.route.params, { root: true })
    } else if (getters.routeFilter === CrmRouteEnum.Administration) {
      await dispatch(AdministrationActionInterface.setRouteParametersAsync, getters.route.params, { root: true })
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
