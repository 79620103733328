import validateAuthorization from './routeGuards'
import { CrmRouteEnum } from '../enums'
import AppDashboardPage from '@/components/AppDashboardPage'

export default [
  {
    path: `/${CrmRouteEnum.Administration}/:filter`,
    name: 'AdministrationFilter',
    component: AppDashboardPage,
    beforeEnter: validateAuthorization
  }
]
