import Vue from 'vue'

const eventBus = new Vue({
  methods: {
    emitEvent: function (eventName, eventData) {
      Vue.$log.info('[EventBus] Emitting Event >> ', eventName, eventData)
      this.$emit(eventName, eventData)
    }
  }
})
export default eventBus
