import camelCase from 'lodash/camelCase'
const requireModule = require.context('.', false, /\.js$/)
const modules = {}
requireModule.keys().forEach(fileName => {
  // Don't register this file as a Vuex module
  if (fileName === './modulesIndex.js') return
  const moduleName = camelCase(fileName.replace(/(\.\/|\.js)/g, ''))
  modules[moduleName] = {
    ...requireModule(fileName).default,
    namespaced: true
  }
})

export default modules
