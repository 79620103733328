import * as enums from '@/enums'

// ==============================================================================================================================
// Constants
// ==============================================================================================================================

export const DefaultAppConfig = { appVersion: '0.1.0' }
export const MaxPageSize = 50
export const MaxLoadedPages = 3
export const StandardListItemHeight = 71 // Height: 60px + 10px Padding + 1px Bottom Border
export const GroupHeaderListItemHeight = 19 // Height: Height: 18px + 1px Bottom Border
export const DefaultInteriorColors = ['White', 'Silver', 'Black', 'Grey', 'Blue', 'Red', 'Brown', 'Green', 'Orange', 'Green', 'Yellow']
export const DefaultExteriorColors = ['White', 'Silver', 'Black', 'Grey', 'Blue', 'Red', 'Brown', 'Green', 'Orange', 'Green', 'Yellow']

// ==============================================================================================================================
// Client(s) Criteria Definitions
// ==============================================================================================================================

export const DefaultDynamicClientsCriteriaRequest = { // (OBSOLETE)
  // DynamicClientsCriteriaRequestModel
  target: enums.ClientsCriteriaTargetTypeEnum.Grid,
  // ClientsCriteriaRequestModel
  clientIds: [],
  validateClientIds: false,
  excludeIds: null,
  // ClientCriteriaRequestModel
  clientId: '',
  queryText: '',
  sortBy: {}, // { UpdatedAt: true, Status: true }
  startDate: new Date('1/1/1900 00:00:00'),
  endDate: new Date('1/1/2100 00:00:00'),
  clientType: enums.ClientTypeEnum.Customer,
  filterType: -1,
  filterSubType: enums.FilterSubTypeEnum.Customer,
  waitingType: enums.WaitingTypeEnum.All,
  waitingSortOrder: enums.WaitingCriteriaSortEnum.Newest,
  watchListStatus: enums.WatchListStatusEnum.Active,
  statuses: [],
  assignedUsers: [],
  assignedTeams: [],
  dealerCodes: [],
  searchTags: [],
  isGrouped: false
}

export const DefaultClientDynamicCriteriaRequest = {
  clientId: '', // Selected ClientId
  // isOutOfSync: false // Call DealerPeak Sync?
  isSelected: false
}

export const DefaultClientsDynamicCriteriaRequest = {
  ...DefaultClientDynamicCriteriaRequest,
  isGrouped: false,
  clientIds: [], // Loaded/Available Clients >> reLoad current loaded Clients
  validateClientIds: false, // Include standard Clients validations for Clients in clientIds?
  excludeIds: null, // Current loaded ClientIds to skip when loading the next Clients page (See: clients.loadClientsCompositionNextPageAsync)
  queryText: '', // Simple search in loaded Clients-List
  sortBy: {}, // { UpdatedAt: true, Status: true }
  startDate: new Date('1/1/1900 00:00:00'),
  endDate: new Date('1/1/2100 00:00:00'),
  clientType: enums.ClientTypeEnum.Customer,
  filterType: -1,
  filterSubType: enums.FilterSubTypeEnum.Customer,
  waitingType: enums.WaitingTypeEnum.All,
  waitingSortOrder: enums.WaitingCriteriaSortEnum.Newest,
  watchListStatus: enums.WatchListStatusEnum.Active,
  statuses: [],
  assignedUsers: [],
  assignedTeams: [],
  dealerCodes: [],
  searchTags: [] // Advanced search (See: DefaultSearchTag)
}

// ==============================================================================================================================
// Client(s) Response Definitions (Templates)
// ==============================================================================================================================

export const PrimaryClientsCriteriaRespnseTemplate = {
  clients: [], // DefaultClientCriteriaRespnse | DefaultGroupedClientCriteriaRespnse
  // isClientSynced: false,
  isLastPage: false,
  total: 0
}

export const PrimaryClientCriteriaRespnseTemplate = {
  client: {}, // DefaultClientCriteriaRespnse | DefaultGroupedClientCriteriaRespnse
  totalDuplicates: 0,
  dealerPeakProfile: undefined
}

export const DefaultClientCriteriaRespnseTemplate = {
  id: '000000000000000000000000',
  status: enums.ClientStatusEnum.Open,
  priority: enums.ClientPriorityEnum.None,
  clientType: enums.ClientTypeEnum.Customer,
  tags: [],
  createdAt: Date.now(),
  modifiedAt: Date.now(),
  updatedAt: Date.now(),
  hasWatchList: false,
  latestWatchListId: '000000000000000000000000',
  latestWatchListAddedById: '000000000000000000000000',
  latestWatchListAddedBySqlId: 0,
  hasUserWatchList: false,
  hasActiveUserWatchList: false,
  hasRemovedUserWatchList: false,
  hasMentions: false,
  hasUnreadMentions: false,
  hasUserMentions: false,
  hasUnreadUserMentions: false,
  latestLeadId: '000000000000000000000000',
  latestLeadStatus: enums.LeadStatusEnum.NotSet,
  latestLeadStatusDescription: '',
  hasLeadMessage: false,
  isReadyToClose: false,
  requiresCloseReason: false,
  primaryAssignedUserId: '000000000000000000000000',
  primaryAssignedUserSqlId: 0,
  primaryAssignedUserFullName: '',
  primaryAssignedTeamId: '000000000000000000000000',
  primaryAssignedTeamName: '',
  dealerId: '000000000000000000000000',
  dealerSqlId: 0,
  dealerCode: '',
  customerId: '000000000000000000000000',
  customerSqlId: 0,
  customerFullName: '',
  customerEmailAddress: '',
  customerMobileNumber: '',
  customerDealerPeakId: '00000000-0000-0000-0000-000000000000',
  hasCustomerDealerPeakId: false,
  hasVehiclesOfInterest: false,
  hasPrimaryVehiclesOfInterest: false
}

export const DefaultGroupedClientCriteriaRespnseTemplate = {
  groupId: 0,
  group: '',
  ...DefaultClientCriteriaRespnseTemplate
}

export const ExtendedClientCriteriaRespnseTemplate = {
  size: StandardListItemHeight, // StandardListItemHeight | GroupHeaderListItemHeight
  type: 'client', // 'client' | 'group'
  isGroupHeader: false, // false | true
  isMarked: false,
  isPinned: false,
  isOutOfSync: false,
  dealerPeakProfile: undefined, // Source: PrimaryClientCriteriaRespnseTemplate.dealerPeakProfile
  ...DefaultClientCriteriaRespnseTemplate // DefaultClientCriteriaRespnseTemplate | DefaultGroupedClientCriteriaRespnseTemplate
}

export const ExtendedStaticClientCriteriaRespnseTemplate = {
  size: StandardListItemHeight, // StandardListItemHeight | GroupHeaderListItemHeight
  type: 'client', // 'client' | 'group'
  isGroupHeader: false, // false | true
  isMarked: false,
  isUpdated: false,
  isOutOfScope: false,
  index: 0,
  ...DefaultClientCriteriaRespnseTemplate // DefaultClientCriteriaRespnseTemplate | DefaultGroupedClientCriteriaRespnseTemplate
}

// ==============================================================================================================================
// Lead(s) Criteria Definitions
// ==============================================================================================================================

export const DefaultLeadCriteriaRequest = {
  clientId: '',
  leadId: '',
  officialLeadId: ''
}

export const DefaultLeadsCriteriaRequest = {
  ...DefaultLeadCriteriaRequest,
  queryText: '',
  sortBy: {},
  startDate: new Date('1/1/1900 00:00:00'),
  endDate: new Date('1/1/2100 00:00:00'),
  types: [],
  statuses: [],
  dealerCodes: []
}

// ==============================================================================================================================
// Message(s) Criteria Definitions
// ==============================================================================================================================

export const DefaultMessageCriteriaRequest = {
  clientId: '',
  leadId: '',
  officialLeadId: '',
  messageId: '',
  queryText: '',
  sortBy: {},
  startDate: new Date('1/1/1900 00:00:00'),
  endDate: new Date('1/1/2100 00:00:00'),
  types: [],
  statuses: [],
  dealerCodes: [],
  tags: []
}

export const DefaultMessagesCriteriaRequest = {
  page: 1,
  messageIds: [],
  ...DefaultMessageCriteriaRequest
}

// ==============================================================================================================================
// Customer(s) Criteria Definitions
// ==============================================================================================================================

export const DefaultCustomerCriteriaRequest = {
  customerId: '',
  queryText: '',
  sortBy: {},
  customerTypes: [],
  statuses: [],
  dealerCodes: []
}

export const DefaultCustomersCriteriaRequest = {
  ...DefaultCustomerCriteriaRequest,
  customerIds: [],
  validateCustomerIds: false,
  excludeIds: null
}

// ==============================================================================================================================
// Inventories Criteria Definitions
// ==============================================================================================================================

export const DefaultInventoryPriceCriteria = {
  minAskingPrice: 0,
  maxAskingPrice: 10000000,
  minLeasePrice: 0,
  maxLeasePrice: 5000
}

export const DefaultInventoryFilterCriteria = {
  queryText: '',
  type: enums.InventoryTypeEnum.All,
  make: undefined,
  productionYear: undefined,
  model: undefined,
  trim: undefined,
  maxAskingPrice: DefaultInventoryPriceCriteria.maxAskingPrice,
  maxLeasePrice: DefaultInventoryPriceCriteria.maxLeasePrice,
  minAskingPrice: DefaultInventoryPriceCriteria.minAskingPrice,
  minLeasePrice: DefaultInventoryPriceCriteria.minLeasePrice,
  interiorColor: '',
  exteriorColor: '',
  showUnsold: false,
  showAll: false,
  showSpecialsSpiffs: false,
  showCpoOnly: false,
  showServiceLoaners: false,
  dealerIds: [-1],
  activePricing: 'Asking Price',
  get validMakeIds () { return this.make ? [this.make.id] : [-1] },
  get validModels () { return this.model ? [this.model.name] : [] },
  get validYears () { return this.productionYear ? [this.productionYear] : [] },
  get validTrims () { return this.trim ? [this.trim] : [] },
  get validMaxAskingPrice () { return this.maxAskingPrice && this.maxAskingPrice !== DefaultInventoryPriceCriteria.maxAskingPrice ? this.maxAskingPrice : undefined },
  get validMaxLeasePrice () { return this.maxLeasePrice && this.maxLeasePrice !== DefaultInventoryPriceCriteria.maxLeasePrice ? this.maxLeasePrice : undefined },
  get validMinAskingPrice () { return this.minAskingPrice && this.minAskingPrice !== DefaultInventoryPriceCriteria.minAskingPrice ? this.minAskingPrice : undefined },
  get validMinLeasePrice () { return this.minLeasePrice && this.minLeasePrice !== DefaultInventoryPriceCriteria.minLeasePrice ? this.minLeasePrice : undefined },
  get validDealerIds () { return this.dealerIds.length > 1 ? this.dealerIds.filter(dealerId => dealerId !== -1) : (this.dealerIds.length > 0 ? this.dealerIds : [-1]) },
  toggleActivePricing: function () { this.activePricing = this.activePricing === 'Lease Price' ? 'Asking Price' : 'Lease Price' }
}

export const DefaultInventorySearchCriteria = {
  accessoryStatus: undefined, // []
  agingIndex: undefined, // 0
  dealerId: undefined, // 0
  dealerIds: [-1],
  dealStatusType: undefined, // ''
  defaultSearchView: undefined, // 0
  documentServiceEntry: undefined, // false
  endDate: undefined, // null
  exteriorColors: DefaultExteriorColors,
  fullVin: true,
  interiorColors: DefaultInteriorColors,
  isPricingView: undefined, // false
  isSelector: undefined, // false
  leadStatus: undefined, // ''
  loanedOutStatus: undefined, // []
  loanerTypeIds: undefined, // []
  locationIds: undefined, // []
  locationStatus: undefined, // ''
  makeIds: [-1],
  maxAskingPrice: undefined, // 0.00
  maxLeasePrice: undefined, // 0.00
  minAskingPrice: undefined, // 0.00
  minLeasePrice: undefined, // 0.00
  modelNames: undefined, // []
  models: [],
  offerStatus: undefined, // ''
  originalTitleStatus: undefined, // ''
  page: 0,
  pageSize: 250,
  payoffUploadStatus: undefined, // []
  photoUploadStatus: undefined, // []
  possessionStatus: undefined, // []
  possessionTypeId: [0, 3, 4],
  publishStatus: undefined, // ''
  purchasePaperworkStatus: undefined, // []
  query: '',
  requiresStatistics: undefined, // false
  saleType: undefined, // []
  serviceLoanerStatus: undefined, // ''
  showCancelledOnly: false,
  showCdkIssues: undefined, // false
  showCpoOnly: false,
  showCustomVehicleOnly: undefined, // false
  showDamaged: false,
  showDemo: undefined, // false
  showDispositionChanged: undefined, // false
  showDispositionExpired: undefined, // false
  showFleet: undefined, // false
  showGoneButAccessoriesRetained: undefined, // false
  showInboundOnly: undefined, // false
  showInStockOnly: false,
  showLoaners: undefined, // false
  showOnSpecial: false,
  showPpa: undefined, // false
  showPreEntry: false,
  showPreEntryPpa: undefined, // false
  showPriorityNotes: undefined, // false
  showSellersAgreementUploadedOnly: undefined, // false
  showServiceLoaners: false,
  showSold: undefined, // false
  showSoldOnly: undefined, // false
  showSpiff: false,
  showUnGrouped: undefined, // false
  showUnPublished: undefined, // false
  soldStatus: undefined, // []
  sortBy: 'name.asc',
  startDate: undefined, // null
  status: undefined, // []
  styles: [],
  subModelNames: undefined, // []
  subscriberId: 0,
  titleUploadStatus: undefined, // []
  trims: [],
  typeId: [enums.InventoryTypeEnum.All],
  vehicleType: undefined, // ''
  years: []
}

// ==============================================================================================================================
// Clients Merge Definitions
// ==============================================================================================================================

export const MergeClientsFields = [
  { meta: { fieldTtype: 'text', hasInput: true, isOptional: false, isHidden: false, color: 'red' }, column: 'First Name' },
  { meta: { fieldTtype: 'text', hasInput: true, isOptional: false, isHidden: false, color: 'red' }, column: 'Last Name' },
  { meta: { fieldTtype: 'text', hasInput: true, isOptional: false, isHidden: false, color: 'red' }, column: 'Primary Contact Number' },
  { meta: { fieldTtype: 'text', hasInput: true, isOptional: true, isHidden: false, color: 'red' }, column: 'Additional Contact Numbers' },
  { meta: { fieldTtype: 'email', hasInput: true, isOptional: false, isHidden: false, color: 'red' }, column: 'Primary Email Address' },
  { meta: { fieldTtype: 'email', hasInput: true, isOptional: true, isHidden: false, color: 'red' }, column: 'Additional Email Addresses' },
  { meta: { fieldTtype: 'email', hasInput: false, isOptional: false, isHidden: true, color: 'red' }, column: 'Email Address (Reply)' },
  { meta: { fieldTtype: 'text', hasInput: false, isOptional: false, isHidden: false, color: 'red' }, column: 'Assigned To' },
  { meta: { fieldTtype: 'text', hasInput: false, isOptional: false, isHidden: false, color: 'red' }, column: 'Status' },
  { meta: { fieldTtype: 'text', hasInput: false, isOptional: false, isHidden: true, color: 'red' }, column: 'Customer Record' }
]

export const MergeClientsColumns = [
  {
    id: -1,
    field: enums.MergeRecordColumnEnum.keys[enums.MergeRecordColumnEnum.Meta],
    visible: false,
    primary: false,
    source: false
  },
  {
    id: 0,
    field: enums.MergeRecordColumnEnum.keys[enums.MergeRecordColumnEnum.Meta],
    sticky: true,
    width: 150,
    primary: false,
    source: false
  }
]

export const MergeClientsRequest = {
  primaryClientId: '',
  sourceClientId: '',
  firstName: '',
  lastName: '',
  emailAddress: '',
  phoneNumber: '',
  emailAddresses: [],
  phoneNumbers: []
}

// ==============================================================================================================================
// Other Definitions (TBD)
// ==============================================================================================================================

export const CreateMessageCommandModel = {
  clientId: '',
  leadId: '',
  type: enums.DraftMessageTypeEnum.Note,
  subject: '',
  content: '',
  onBehalfOfId: undefined,
  mentionedIds: [],
  attachments: []
}

export const DefaultClientCustomer = {
  id: '',
  firstName: '',
  lastName: '',
  fullName: '',
  emailAddress: '',
  mobileNumber: '',
  telephoneNumber: ''
}

export const MessageTokens = [
  {
    heading: { tag: 'author-tokens', label: 'Author Tokens' },
    tags: [
      { tag: 'author-name', label: 'Author Name', value: undefined },
      { tag: 'author-fullname', label: 'Author Fullname', value: undefined },
      { tag: 'author-tel', label: 'Author Tel', value: undefined }
    ]
  },
  {
    heading: { tag: 'customer-tokens', label: 'Customer Tokens' },
    tags: [
      { tag: 'customer-firstname', label: 'Customer Firstname', value: undefined },
      { tag: 'customer-lastname', label: 'Customer Lastname', value: undefined },
      { tag: 'customer-tel', label: 'Customer Tel', value: undefined },
      { tag: 'customer-email', label: 'Customer Email', value: undefined }
    ]
  },
  {
    heading: { tag: 'vehicle-tokens', label: 'Vehicle Tokens' },
    tags: [
      { tag: 'vehicle-model', label: 'Vehicle Model', value: undefined },
      { tag: 'vehicle-make', label: 'Vehicle Make', value: undefined },
      { tag: 'vehicle-vin', label: 'VIN', value: undefined },
      { tag: 'vehicle-stock-no', label: 'Stock No', value: undefined },
      { tag: 'vehicle-sold-status', label: 'Sold Status', value: undefined },
      { tag: 'window-sticker-link', label: 'Window Sticker Link', value: undefined },
      { tag: 'car-fax-link', label: 'Car Fax Link', value: undefined },
      { tag: 'full-vehicle-name', label: 'Full Vehicle Name', value: undefined }
    ]
  },
  {
    heading: { tag: 'contact-tokens', label: 'Contact Tokens' },
    tags: [
      { tag: 'bdc-name', label: 'BDC Name', value: undefined },
      { tag: 'bdc-tel', label: 'BDC Tel', value: undefined },
      { tag: 'fi-name', label: 'F&I Name', value: undefined },
      { tag: 'fi-tel', label: 'F&I Tel', value: undefined },
      { tag: 'sales-tel', label: 'Sales Department Tel', value: undefined },
      { tag: 'services-tel', label: 'Services Department Tel', value: undefined },
      { tag: 'parts-tel', label: 'Parts Department Tel', value: undefined }
    ]
  },
  {
    heading: { tag: 'location-tokens', label: 'Location Tokens' },
    tags: [
      { tag: 'location-name', label: 'Location Name', value: undefined },
      { tag: 'location-tel', label: 'Location Tel', value: undefined },
      { tag: 'location-address', label: 'Location Address', value: undefined },
      { tag: 'location-logo', label: 'Location Logo', value: undefined }
    ]
  },
  {
    heading: { tag: 'lead-tokens', label: 'Lead Tokens' },
    tags: [
      { tag: 'lead-source', label: 'Lead Source', value: undefined }
    ]
  }
]

// export const DefaultSearchTag = {
//   group: enums.SearchTagEnum.NotSet,
//   title: '',
//   // target: enums.SearchTagTargetEnum.NotSet,
//   // range: enums.SearchTagDateRangeEnum.NotSet,
//   value: ''
// }

// export const DefaultSelectedClientCriteria = {
//   client: undefined,
//   criteria: undefined
// }
