/* eslint-disable no-unused-vars */
import Vue from 'vue'
import axios from 'axios'
import baseService from './baseService'
import config from './config'

const vehicleMakesProfileApiService = {
  allVehicleMakes () {
    return new Promise((resolve, reject) => {
      axios
        .get(config.profileApiUrl + 'vehiclemake/list', baseService.defaultConfig)
        .then(response => {
          // Vue.$log.debug('[DEV]: vehicleMakesProfileApiService.allVehicleMakes()', response)
          resolve(response.data)
        })
        .catch(error => {
          Vue.$log.debug('[DEV]: vehicleMakesProfileApiService.allVehicleMakes() >> ERROR', error)
          reject(error.response)
        })
    })
  },
  single (vehicleMakeId) {
    return new Promise((resolve, reject) => {
      axios
        .post(config.profileApiUrl + '[DEV]: vehiclemake/get?id=' + vehicleMakeId, baseService.defaultConfig)
        .then(response => {
          // Vue.$log.debug('[DEV]: vehicleMakesProfileApiService.single(vehicleMakeId)', vehicleMakeId, response)
          resolve(response.data)
        })
        .catch(error => {
          Vue.$log.debug('[DEV]: vehicleMakesProfileApiService.single(vehicleMakeId) >> ERROR', vehicleMakeId, error)
          reject(error.response.data)
        })
    })
  }
}

export default vehicleMakesProfileApiService
