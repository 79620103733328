// TODO: Resolve Leads related issues
// TODO: Add MMS-Media content for SMS Messages
// TODO: Add Draft Messages (per Lead)
// TODO: Add Client-List Search functionality
// TODO: Implement Filter Leads
// TODO: Implement Toggle MessageType In/Out Bounds

import Vue from 'vue'
import {
  NotificationEventEnum,
  CrmRouteEnum,
  CrmClientsRouteEnum,
  ClientsFilterTypeEnum,
  LeadsCriteriaSortByEnum,
  LeadStatusEnum,
  MessagesCriteriaSortByEnum,
  MessageTypeEnum
} from '../../enums'
import {
  InternalClientsMutationInterface,
  DealersGetterInterface,
  ClientsActionInterface,
  ClientsCriteriaGetterInterface,
  ClientsCriteriaActionInterface,
  NavigateParticipantsActionInterface,
  NavigateMentionsActionInterface
} from '../storeInterfaces'
import {
  MaxPageSize,
  DefaultClientDynamicCriteriaRequest,
  DefaultLeadsCriteriaRequest,
  DefaultMessagesCriteriaRequest,
  StandardListItemHeight,
  GroupHeaderListItemHeight
} from '../../definitions'
import rtApiService from '../../services/rtApiService'
import { uuid } from 'vue-uuid'

const state = {
  isBusyCounter: 0,
  // Routes
  routeParameters: undefined,
  // Clients
  hasPendingNewClients: false,
  allFilteredClientIds: [],
  clientsFiltersTotals: undefined,
  clients: [],
  clientsTotal: 0,
  isAllClientsLoaded: false,
  isAllClientsMarked: false,
  // Client
  client: undefined,
  clientDuplicatesTotal: 0,
  // Customer
  clientCustomer: undefined,
  dealerPeakProfile: undefined,
  // Leads
  leads: [],
  lead: undefined,
  // Messages
  messages: [],
  messagesFilter: []
}

const getters = {
  isInitialized: state => !!state.routeParameters,
  isBusy: state => state.isBusyCounter > 0,
  // Routes
  hasRouteParamaters: state => !!state.routeParameters,
  routeParameters: state => state.routeParameters,
  hasRouteTeamId: state => !!state.routeParameters && !!state.routeParameters.teamId,
  routeTeamId: state => state.routeParameters.teamId,
  hasRouteUserId: state => !!state.routeParameters && !!state.routeParameters.userId,
  routeUserId: state => state.routeParameters.userId,
  // Clients
  hasPendingNewClients: state => state.hasPendingNewClients,
  allFilteredClientIds: state => state.allFilteredClientIds,
  hasClientsFiltersTotals: state => !!state.clientsFiltersTotals,
  clientsFiltersTotals: state => state.clientsFiltersTotals,
  hasClients: state => Array.isArray(state.clients) && state.clients.length > 0,
  clients: state => state.clients,
  clientsTotal: state => state.clientsTotal,
  isAllClientsLoaded: state => state.isAllClientsLoaded,
  hasClient: state => !!state.client,
  client: state => state.client,
  hasClientTags: state => !!state.client && Array.isArray(state.client.tags) && state.client.tags.length > 0,
  isAllClientsMarked: state => state.isAllClientsMarked,
  hasMarkedClients: state => Array.isArray(state.clients) && state.clients.length > 0 && state.clients.some(client => client.isMarked),
  hasMultipleMarkedClients: state => Array.isArray(state.clients) && state.clients.length > 0 && state.clients.filter(client => client.isMarked).length > 1,
  canMergeMarkedClients: state => Array.isArray(state.clients) && state.clients.length > 0 && state.clients.filter(client => client.isMarked).length === 2,
  markedClients: state => state.clients.filter(client => client.isMarked),
  isClientMarked: (state, getters, rootState, rootGetters) => (clientId) => state.clients.some(client => client.id === clientId && client.isMarked),
  hasOutOfScopeClients: state => state.clients.some(client => client.isOutOfScope),
  hasUpdatedClients: state => state.clients.some(client => client.isUpdated),
  hasClientDuplicates: state => state.clientDuplicatesTotal > 1,
  clientDuplicatesTotal: state => state.clientDuplicatesTotal,
  // Customer
  hasDealerPeakProfile: state => state.hasClient && !!state.dealerPeakProfile,
  dealerPeakProfile: state => state.dealerPeakProfile,
  hasDuplicateDealerPeakProfiles: state => state.hasDealerPeakProfile && state.dealerPeakProfile.requiresCrmSelection,
  // Leads
  hasLeads: state => Array.isArray(state.leads) && state.leads.length > 0,
  leads: state => state.leads,
  leadsTotal: state => state.leads.length,
  hasLead: state => !!state.lead,
  hasOpenLead: state => !!state.lead && state.lead.status === LeadStatusEnum.Open,
  lead: state => state.lead,
  hasLeadParticipants: state => !!state.lead && Array.isArray(state.lead.participants) && state.lead.participants.length > 0,
  hasLeadTags: state => !!state.lead && Array.isArray(state.lead.tags) && state.lead.tags.length > 0,
  // Messages
  hasMessages: state => Array.isArray(state.messages) && state.messages.length > 0,
  messagesTotal: state => state.messages.length,
  hasMessagesFilter: state => Array.isArray(state.messagesFilter) && state.messagesFilter.length > 0,
  messagesFilter: state => state.messagesFilter,
  hasMessagesTags: state => Array.isArray(state.messages) && state.messages.length > 0 && state.messages.some(message => Array.isArray(message.tags) && message.tags.length > 0),
  messagesTags: state => [...new Set(state.messages.filter(message => Array.isArray(message.tags) && message.tags.length > 0).map(message => message.tags).flat())],
  leadMessage: state => state.messages.find(message => message.type === MessageTypeEnum.Lead),
  lastMessage: state => state.messages[state.messages.length - 1],
  messages: state => state.messages,
  // Messages Mentions
  // TODO: Add additional Mentioned Users Sorting - See: CrmDetailsPanelComponent.sortedMentionedUserList (MentionsCriteriaSortEnum)
  // TODO: Refactor/Optimize store.navigateMentions
  hasMessagesMentions: state => {
    return Array.isArray(state.messages) && state.messages.length > 0 && // Verify Messages
      state.messages.some(message => Array.isArray(message.mentions) && message.mentions.length > 0) // Verify Messages with Mentions
  },
  messageIdsWithMentions: state => {
    return state.messages.filter(message => Array.isArray(message.mentions) && message.mentions.length > 0) // Filter Messages with Mentions
      .sort((a, b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0)) // Messages Ascending
      .map(message => message.id) // Flatten Message Ids
  },
  messagesMentionsUserIds: state => {
    return [...new Set(
      state.messages.filter(message => Array.isArray(message.mentions) && message.mentions.length > 0) // Filter Messages with Mentions
        .map(message => message.mentions).flat() // Flatten Messages Mentions
        .map(mention => mention.user.id).flat() // Flatten User Ids
    )]
  },
  hasMessagesMentionsByUserId: (state, getters, rootState, rootGetters) => (userId) => {
    return Array.isArray(state.messages) && state.messages.length > 0 && // Verify Messages
      state.messages.some(message => Array.isArray(message.mentions) && // Verify Messages with Mentions
      message.mentions.length > 0 && message.mentions.some(mention => mention.user.id === userId)) // Verify Messages Mentions User Ids
  },
  messageIdsWithMentionsByUserId: (state, getters, rootState, rootGetters) => (userId) => {
    return state.messages.filter(message => Array.isArray(message.mentions) && message.mentions.length > 0 && // Filter Messages
      message.mentions.some(mention => mention.user.id === userId)) // Filter Messages Mentions
      .sort((a, b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0)) // Messages Ascending
      .map(message => message.id) // Flatten Messages Ids
  }
}

const mutations = {
  [InternalClientsMutationInterface.Reset] (state) {
    state.isBusyCounter = 0
    // Clients
    state.hasPendingNewClients = false
    state.allFilteredClientIds = []
    state.clientsFiltersTotals = undefined
    state.clients = []
    state.clientsTotal = 0
    state.isAllClientsLoaded = false
    state.isAllClientsMarked = false
    // Client
    state.client = undefined
    state.clientDuplicatesTotal = 0
    // Customer
    state.clientCustomer = undefined
    state.dealerPeakProfile = undefined
    // Leads
    state.leads = []
    state.lead = undefined
    // Messages
    state.messagesFilter = []
    state.messages = []
  },
  [InternalClientsMutationInterface.InProgressIndicator] (state, isBusy) {
    if (isBusy) {
      state.isBusyCounter++
    } else if (state.isBusyCounter > 0) {
      state.isBusyCounter--
    }
  },
  [InternalClientsMutationInterface.ResetProgressIndicator] (state) {
    state.isBusyCounter = 0
  },
  [InternalClientsMutationInterface.RouteParamaters] (state, routeParameters) {
    if (!routeParameters || (!!state.routeParameters && state.routeParameters.filter === routeParameters.filter && state.routeParameters.teamId === routeParameters.team && state.routeParameters.userId === routeParameters.userId)) return

    // Clients
    state.allFilteredClientIds = []
    state.clientsFiltersTotals = undefined
    state.clients = []
    state.clientsTotal = 0
    state.isAllClientsLoaded = false
    state.client = undefined
    state.clientDuplicatesTotal = 0
    state.isAllClientsMarked = false
    // Customer
    state.clientCustomer = undefined
    state.dealerPeakProfile = undefined
    // Leads
    state.leads = []
    state.lead = undefined
    // Messages
    state.messagesFilter = []
    state.messages = []

    state.routeParameters = { filter: routeParameters.filter, teamId: routeParameters.team, userId: routeParameters.user }
  },
  // Clients
  [InternalClientsMutationInterface.ClientsReset] (state) {
    // Clients
    state.hasPendingNewClients = false
    state.allFilteredClientIds = []
    state.clients = []
    state.clientsTotal = 0
    state.isAllClientsLoaded = false
    state.isAllClientsMarked = false
    // Client
    state.client = undefined
    state.clientDuplicatesTotal = 0
    // Customer
    state.clientCustomer = undefined
    state.dealerPeakProfile = undefined
    // Leads
    state.leads = []
    state.lead = undefined
    // Messages
    state.messagesFilter = []
    state.messages = []
  },
  [InternalClientsMutationInterface.ClientReset] (state, fullReset) {
    // Leads
    state.leads = []
    state.lead = undefined
    // Messages
    state.messagesFilter = []
    state.messages = []
    if (fullReset) {
    // Client
      state.client = undefined
      state.clientDuplicatesTotal = 0
      // Customer
      state.clientCustomer = undefined
      state.dealerPeakProfile = undefined
    }
  },
  [InternalClientsMutationInterface.ClientsFiltersTotals] (state, clientsFiltersTotals) {
    if (typeof clientsFiltersTotals !== 'object') return
    state.clientsFiltersTotals = { ...clientsFiltersTotals }
  },
  [InternalClientsMutationInterface.ClientsLoaded] (state, loadedClientsModel) {
    if (!loadedClientsModel) return
    if (!loadedClientsModel.isNextPage) {
      // Clients
      state.allFilteredClientIds = loadedClientsModel.clientIds
      state.clientsTotal = loadedClientsModel.clientsTotal
      // Client
      state.client = { ...loadedClientsModel.client }
      state.clientDuplicatesTotal = loadedClientsModel.clientDuplicatesTotal
      // Customer
      state.clientCustomer = { ...loadedClientsModel.customer }
      state.dealerPeakProfile = { ...loadedClientsModel.dealerPeakProfile }
      // Leads
      state.leads = [...loadedClientsModel.leads]
      state.lead = { ...loadedClientsModel.lead }
      // Messages
      state.messages = [...loadedClientsModel.messages]
    }
    state.isAllClientsLoaded = loadedClientsModel.isAllClientsLoaded
    state.clients = [...loadedClientsModel.clients]
  },
  // Current Client | Loaded Client
  [InternalClientsMutationInterface.ClientLoaded] (state, loadedClientModel) {
    if (typeof loadedClientModel !== 'object') return

    const clients = [...state.clients]
    // Client
    if (!state.client || state.client.id === loadedClientModel.client.id) {
      state.client = { ...loadedClientModel.client }
      state.clientDuplicatesTotal = loadedClientModel.clientDuplicatesTotal
      // Customer
      state.clientCustomer = { ...loadedClientModel.customer }
      state.dealerPeakProfile = { ...loadedClientModel.dealerPeakProfile }
      // Leads
      state.leads = [...loadedClientModel.leads]
      state.lead = { ...loadedClientModel.lead }
      // Messages
      state.messages = [...loadedClientModel.messages]
    }

    const clientIndex = clients.findIndex(client => client.id === loadedClientModel.client.id)
    if (clientIndex !== -1) {
      clients[clientIndex] = { ...loadedClientModel.client }
    }
    // Clients
    state.clients = [...clients]
  },
  [InternalClientsMutationInterface.ClientsTotals] (state, clientsTotal) {
    state.clientsTotal = clientsTotal
  },
  [InternalClientsMutationInterface.SetPendingNewClient] (state, clientId) {
    if (state.hasPendingNewClients) return
    state.hasPendingNewClients = true
    // Vue.$log.debug('[DEV]: clients >> InternalClientsMutationInterface.ToggledMarkClient', state.clients[clientIndex])
  },
  [InternalClientsMutationInterface.ToggledMarkClient] (state, clientId) {
    if (typeof clientId !== 'string' || state.isAllClientsMarked) return
    const clientIndex = state.clients.findIndex(client => client.id === clientId)
    const affectedClient = state.clients[clientIndex]
    if (state.client.id === affectedClient.id) {
      state.client.isMarked = !affectedClient.isMarked
    }
    affectedClient.isMarked = !affectedClient.isMarked
    state.clients[clientIndex] = { ...affectedClient }
    state.isAllClientsMarked = state.isAllClientsMarked && affectedClient.isMarked

    state.clients = [...state.clients]
    // Vue.$log.debug('[DEV]: clients >> InternalClientsMutationInterface.ToggledMarkClient', state.clients[clientIndex])
  },
  [InternalClientsMutationInterface.ToggledMarkAllClients] (state, isAllMarked) {
    if (typeof isAllMarked !== 'boolean' || state.isAllClientsMarked === isAllMarked) return
    state.isAllClientsMarked = isAllMarked
    state.clients.forEach(client => {
      client.isMarked = state.isAllClientsMarked
      return client
    })
    state.clients = [...state.clients]
  },
  [InternalClientsMutationInterface.LeadsLoaded] (state, loadedLeadsModel) {
    if (typeof loadedLeadsModel !== 'object') return
    state.leads = loadedLeadsModel.leads
    state.lead = loadedLeadsModel.lead
  },
  [InternalClientsMutationInterface.LeadAdded] (state, addedLeadModel) {
    if (typeof addedLeadModel !== 'object') return
    state.leads = addedLeadModel.leads
  },
  [InternalClientsMutationInterface.LeadExpanded] (state, expandedLeadModel) {
    if (typeof expandedLeadModel !== 'object') return
    state.lead = { ...expandedLeadModel.lead }
    state.messages = expandedLeadModel.messages
  },
  [InternalClientsMutationInterface.MessageLoaded] (state, loadedMessage) {
    if (!loadedMessage) return
    const messageIndex = state.messages.findIndex(message => message.id === loadedMessage.id)
    if (messageIndex !== -1) {
      state.messages[messageIndex] = { ...loadedMessage }
    }
  },
  [InternalClientsMutationInterface.MessagesLoaded] (state, loadedMessages) {
    if (!Array.isArray(loadedMessages)) return
    state.messages = loadedMessages
  },
  [InternalClientsMutationInterface.MessagesFiltered] (state, messagesFilter) {
    if (!Array.isArray(messagesFilter)) return
    state.messagesFilter = messagesFilter
  }
}

const actions = {
  reset: function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    commit(InternalClientsMutationInterface.Reset)
  },
  resetBusy: function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    commit(InternalClientsMutationInterface.ResetProgressIndicator)
  },
  setBusy: function ({ state, rootState, commit, dispatch, getters, rootGetters }, isBusy) {
    commit(InternalClientsMutationInterface.InProgressIndicator, isBusy)
  },
  notificationAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }, eventData) {
    Vue.$log.debug(`[DEV]: ${'clients'}.notificationAsync(eventData)`, eventData.event, NotificationEventEnum[eventData.event])

    // INFO: Update Root-Filter Totals
    await dispatch(ClientsActionInterface.loadClientsFiltersTotalsAsync, null, { root: true })

    if (NotificationEventEnum[eventData.event] === NotificationEventEnum.LeadAdded) {
      if (getters.clients.some(client => client.id === eventData.clientId)) { // Is Client available?
        Vue.$log.debug(`[DEV]: ${'clients'}.notificationAsync(eventData) >> ${NotificationEventEnum.keyOf(NotificationEventEnum[eventData.event])} >> START`, eventData)
        // INFO: Load Client Composition
        await dispatch(ClientsActionInterface.loadClientCompositionAsync, { clientId: eventData.clientId, isUpdated: true }, { root: true })
        Vue.$log.debug(`[DEV]: ${'clients'}.notificationAsync(eventData) >> ${NotificationEventEnum.keyOf(NotificationEventEnum[eventData.event])} >> END`)
      }
    } else if (NotificationEventEnum[eventData.event] === NotificationEventEnum.MessageAdded) {
      if (getters.clients.some(client => client.id === eventData.clientId)) { // Is Client available?
        Vue.$log.debug(`[DEV]: ${'clients'}.notificationAsync(eventData) >> ${NotificationEventEnum.keyOf(NotificationEventEnum[eventData.event])} >> START`, eventData)
        // INFO: Load Client Composition
        await dispatch(ClientsActionInterface.loadClientCompositionAsync, { clientId: eventData.clientId, isUpdated: true }, { root: true })
        Vue.$log.debug(`[DEV]: ${'clients'}.notificationAsync(eventData) >> ${NotificationEventEnum.keyOf(NotificationEventEnum[eventData.event])} >> END`)
      }
    } else if (NotificationEventEnum[eventData.event] === NotificationEventEnum.MessageStatusUpdated ||
      NotificationEventEnum[eventData.event] === NotificationEventEnum.MessageTransactionUpdated) {
      if (getters.clients.some(client => client.id === eventData.clientId)) { // Is Client available?
        Vue.$log.debug(`[DEV]: ${'clients'}.notificationAsync(eventData) >> ${NotificationEventEnum.keyOf(NotificationEventEnum[eventData.event])} >> START`, eventData)
        // INFO: Load Client Composition
        await dispatch(ClientsActionInterface.loadClientCompositionAsync, { clientId: eventData.clientId, isUpdated: true }, { root: true })
        Vue.$log.debug(`[DEV]: ${'clients'}.notificationAsync(eventData) >> ${NotificationEventEnum.keyOf(NotificationEventEnum[eventData.event])} >> END`)
      }
    } else if (NotificationEventEnum[eventData.event] === NotificationEventEnum.NewClient) {
      if (!getters.hasPendingNewClients) { // Is Pending New Clients already set?
        Vue.$log.debug(`[DEV]: ${'clients'}.notificationAsync(eventData) >> ${NotificationEventEnum.keyOf(NotificationEventEnum[eventData.event])} >> START`, eventData)
        // INFO: Verify New Client
        await dispatch(ClientsActionInterface.verifyNewClientCompositionAsync, eventData.clientId, { root: true })
        Vue.$log.debug(`[DEV]: ${'clients'}.notificationAsync(eventData) >> ${NotificationEventEnum.keyOf(NotificationEventEnum[eventData.event])} >> END`)
      }
    } else if (NotificationEventEnum[eventData.event] === NotificationEventEnum.ClientAssignmentUpdated ||
      NotificationEventEnum[eventData.event] === NotificationEventEnum.ClientTagsUpdated ||
      NotificationEventEnum[eventData.event] === NotificationEventEnum.ClientStatusUpdated ||
      NotificationEventEnum[eventData.event] === NotificationEventEnum.ClientPriorityUpdated ||
      NotificationEventEnum[eventData.event] === NotificationEventEnum.ClientWatchListAdded ||
      NotificationEventEnum[eventData.event] === NotificationEventEnum.ClientWatchListRemoved ||
      NotificationEventEnum[eventData.event] === NotificationEventEnum.ClientsMerged) {
      if (getters.clients.some(client => client.id === eventData.clientId)) { // Is Client available?
        Vue.$log.debug(`[DEV]: ${'clients'}.notificationAsync(eventData) >> ${NotificationEventEnum.keyOf(NotificationEventEnum[eventData.event])} >> START`, eventData)
        // INFO: Load Client Composition
        await dispatch(ClientsActionInterface.loadClientCompositionAsync, { clientId: eventData.clientId, isUpdated: true }, { root: true })
        Vue.$log.debug(`[DEV]: ${'clients'}.notificationAsync(eventData) >> ${NotificationEventEnum.keyOf(NotificationEventEnum[eventData.event])} >> END`)
      }
    } else if (NotificationEventEnum[eventData.event] === NotificationEventEnum.ClientsStatusesUpdated ||
      NotificationEventEnum[eventData.event] === NotificationEventEnum.ClientsPrioritiesUpdated ||
      NotificationEventEnum[eventData.event] === NotificationEventEnum.ClientsCustomersUpdated) {
      eventData.clientIds = eventData.clientIds.filter(clientId => getters.clients.some(client => client.id === clientId))
      if (eventData.clientIds.length > 0) {
        Vue.$log.debug(`[DEV]: ${'clients'}.notificationAsync(eventData) >> ${NotificationEventEnum.keyOf(NotificationEventEnum[eventData.event])} >> START`, eventData)
        await eventData.clientIds.forEach(async (upadetedClientId) => {
          await dispatch(ClientsActionInterface.loadClientCompositionAsync, { clientId: upadetedClientId, isUpdated: true }, { root: true })
        })
        Vue.$log.debug(`[DEV]: ${'clients'}.notificationAsync(eventData) >> ${NotificationEventEnum.keyOf(NotificationEventEnum[eventData.event])} >> END`)
      }
    }
  },
  // Routes
  setRouteParametersAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }, routeParameters) {
    // Vue.$log.debug('[DEV]: clients.setRouteParametersAsync(routeParameters)', routeParameters)
    if (!routeParameters || (getters.hasRouteParamaters && JSON.stringify(getters.routeParameters) === JSON.stringify(routeParameters))) return

    commit(InternalClientsMutationInterface.InProgressIndicator, true)

    commit(InternalClientsMutationInterface.RouteParamaters, routeParameters)
    // Vue.$log.debug('[DEV]: clients.routeParameters', getters.routeParameters)
    const targetPath = `/${CrmRouteEnum.Clients}/${getters.routeParameters.filter}`
    // Vue.$log.debug('[DEV]: clients.setRouteParametersAsync(routeParameters)', targetRouteFilter)
    const filterType = ClientsFilterTypeEnum[CrmClientsRouteEnum.keyOf(targetPath)]
    // Vue.$log.debug('[DEV]: clients.setRouteParametersAsync(routeParameters)', filterType)

    dispatch(ClientsCriteriaActionInterface.setCriteriaByFilterType, filterType, { root: true })
    // Vue.$log.debug('[DEV]: clients.setRouteParametersAsync(routeParameters) >> rootGetters[ClientsCriteriaGetterInterface.filterType]', rootGetters[ClientsCriteriaGetterInterface.filterType])
    await dispatch(ClientsActionInterface.loadClientsFiltersTotalsAsync, null, { root: true })
    await dispatch(ClientsActionInterface.loadClientsCompositionAsync, null, { root: true })

    commit(InternalClientsMutationInterface.InProgressIndicator, false)
  },
  // Clients
  loadClientsFiltersTotalsAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }, criteria) {
    if (!getters.isInitialized || (!criteria && !rootGetters[ClientsCriteriaGetterInterface.isInitialized])) return
    const totalsResponse = await rtApiService.loadClientsFiltersTotalsAsync()
    commit(InternalClientsMutationInterface.ClientsFiltersTotals, totalsResponse)
  },
  loadClientCompositionAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }, { clientId, isUpdated }) {
    if (!getters.isInitialized || !clientId || !getters.clients.some(client => client.id === clientId)) return

    commit(InternalClientsMutationInterface.InProgressIndicator, true)

    // INFO: Load Client
    const clientCriteriaRequest = JSON.parse(JSON.stringify(DefaultClientDynamicCriteriaRequest))
    clientCriteriaRequest.clientId = clientId
    clientCriteriaRequest.isSelected = !getters.hasClient || clientId === getters.client.id
    Vue.$log.debug('[DEV]: clients.loadClientCompositionAsync(criteria) >> LOAD CLIENT, CUSTOMER & DEALERPEAK >> STARTED', clientId)
    const clientResponse = await rtApiService.loadClientDynamicAsync(clientCriteriaRequest)
    Vue.$log.debug('[DEV]: clients.loadClientCompositionAsync(criteria) >> LOAD CLIENT, CUSTOMER & DEALERPEAK >> ENDED', clientResponse)

    if (!clientResponse || !clientResponse.client) { // INFO: Client not found
      commit(InternalClientsMutationInterface.InProgressIndicator, false)
      return
    }

    let matchingClient
    if (getters.hasClient && getters.client.id === clientId) {
      matchingClient = { ...getters.client }
    } else {
      matchingClient = { ...getters.clients.find(client => client.id === clientId) }
    }

    if (!matchingClient) { // INFO: Client not found
      commit(InternalClientsMutationInterface.InProgressIndicator, false)
      return
    }

    const clientModel = {
      client: undefined,
      clientDuplicatesTotal: 0,
      customer: undefined,
      dealerPeakProfile: undefined,
      leads: [],
      lead: undefined,
      messages: []
    }

    clientModel.client = { ...clientResponse.client }
    clientModel.client.index = matchingClient.index
    clientModel.client.size = matchingClient.size
    clientModel.client.type = matchingClient.type
    clientModel.client.isGroupHeader = false
    clientModel.client.isMarked = matchingClient.isMarked
    clientModel.client.isUpdated = isUpdated
    clientModel.client.isOutOfScope = !rootGetters[ClientsCriteriaGetterInterface.isClientInFilterScope](clientModel.client)
    clientModel.clientDuplicatesTotal = clientResponse.totalDuplicates
    clientModel.customer = clientResponse.customer
    clientModel.dealerPeakProfile = clientResponse.dealerPeakProfile
    if (rootGetters[ClientsCriteriaGetterInterface.isGrouped]) {
      clientModel.client.groupId = matchingClient.groupId
      clientModel.client.group = matchingClient.group
    }

    if (!getters.hasClient || getters.client.id === clientId) {
    // INFO: Load Leads
      const leadsCriteriaRequest = JSON.parse(JSON.stringify(DefaultLeadsCriteriaRequest))
      leadsCriteriaRequest.clientId = clientId
      leadsCriteriaRequest.sortBy[LeadsCriteriaSortByEnum.UpdatedAt] = false // INFO: Ascending
      leadsCriteriaRequest.statuses = []
      leadsCriteriaRequest.dealerCodes = rootGetters[DealersGetterInterface.selectedDealerCodes]
      const leadsResponse = await rtApiService.loadLeadsDynamicAsync(leadsCriteriaRequest)
      clientModel.leads = leadsResponse || []

      // INFO: Focus/Expand last Lead
      clientModel.lead = clientModel.leads[clientModel.leads.length - 1]

      // INFO: Load Messages
      const messagesCriteriaRequest = JSON.parse(JSON.stringify(DefaultMessagesCriteriaRequest))
      messagesCriteriaRequest.clientId = clientId
      messagesCriteriaRequest.leadId = clientModel.lead.id
      messagesCriteriaRequest.sortBy[MessagesCriteriaSortByEnum.CreatedAt] = false // INFO: Ascending
      messagesCriteriaRequest.types = []
      messagesCriteriaRequest.statuses = []
      messagesCriteriaRequest.dealerCodes = rootGetters[DealersGetterInterface.selectedDealerCodes]
      const messagesResponse = await rtApiService.loadLeadMessagesAsync(messagesCriteriaRequest)
      clientModel.messages = messagesResponse
    }

    commit(InternalClientsMutationInterface.ClientLoaded, clientModel)
    commit(InternalClientsMutationInterface.InProgressIndicator, false)
  },
  loadClientsCompositionAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    if (!getters.isInitialized || !rootGetters[ClientsCriteriaGetterInterface.isInitialized]) return

    commit(InternalClientsMutationInterface.InProgressIndicator, true)

    const currentClientId = getters.client?.id
    const criteria = { ...rootGetters[ClientsCriteriaGetterInterface.criteria] }

    // INFO: Load initial Clients (Grouped or UnGrouped)
    let clientsResponse
    if (rootGetters[ClientsCriteriaGetterInterface.isGrouped]) {
      Vue.$log.debug(`[DEV]: ${'clients'}.loadClientsCompositionAsync() >> rtApiService.loadStaticGroupedClientsDynamicAsync(criteria, groupedClientIds)`, criteria)
      clientsResponse = await rtApiService.loadStaticGroupedClientsDynamicAsync(criteria, null)
      Vue.$log.debug(`[DEV]: ${'clients'}.loadClientsCompositionAsync() >> rtApiService.loadStaticGroupedClientsDynamicAsync(criteria, groupedClientIds)`, clientsResponse)
    } else {
      Vue.$log.debug(`[DEV]: ${'clients'}.loadClientsCompositionAsync() >> rtApiService.loadStaticClientsDynamicAsync(criteria, clientIds)`, criteria)
      clientsResponse = await rtApiService.loadStaticClientsDynamicAsync(criteria, null)
      Vue.$log.debug(`[DEV]: ${'clients'}.loadClientsCompositionAsync() >> rtApiService.loadStaticClientsDynamicAsync(criteria, clientIds)`, clientsResponse)
    }

    if (!clientsResponse.clients) {
      commit(InternalClientsMutationInterface.InProgressIndicator, false)
      return // INFO: No further processing required
    }

    if (clientsResponse.clients.length > 0) {
      // INFO: Extend Clients, Grouping, etc. (See: ExtendedStaticClientCriteriaRespnseTemplate)
      const tempClientsList = []
      if (rootGetters[ClientsCriteriaGetterInterface.isGrouped]) {
        let lastGroupId = -1
        clientsResponse.clients.forEach(client => {
          if (client.groupId !== lastGroupId) { // INFO: Is New Group Header?
            lastGroupId = client.groupId
            const clientIdItem = clientsResponse.clientIds.find(clientIdItem => clientIdItem.groupId === client.groupId)
            const groupHeader = {
              id: uuid.v4(),
              isGroupHeader: true,
              groupId: clientIdItem.groupId,
              group: clientIdItem.group,
              total: clientIdItem.total,
              size: GroupHeaderListItemHeight,
              type: 'group'
            }
            tempClientsList.push(groupHeader)
          }
          client.isGroupHeader = false
          client.size = StandardListItemHeight
          client.type = 'client'
          client.isMarked = false
          client.isUpdated = false
          client.isOutOfScope = false
          tempClientsList.push(client)
        })
      } else {
        clientsResponse.clients.forEach(client => {
          client.isGroupHeader = false
          client.size = StandardListItemHeight
          client.type = 'client'
          client.isMarked = false
          client.isUpdated = false
          client.isOutOfScope = false
          tempClientsList.push(client)
        })
      }

      const clientsModel = {
        isNextPage: false,
        clientIds: clientsResponse.clientIds,
        clients: tempClientsList,
        clientsTotal: clientsResponse.clientIds.length,
        isAllClientsLoaded: tempClientsList[tempClientsList.length - 1].id === clientsResponse.clientIds[clientsResponse.clientIds.length - 1].clientId,
        client: undefined,
        clientDuplicatesTotal: 0,
        clientCustomer: undefined,
        dealerPeakProfile: undefined,
        leads: [],
        lead: undefined,
        messages: []
      }

      // INFO: Load first Client
      const clientCriteriaRequest = JSON.parse(JSON.stringify(DefaultClientDynamicCriteriaRequest))
      if (currentClientId && clientsResponse.clients.some(client => client.id === currentClientId)) {
        clientCriteriaRequest.clientId = currentClientId
      } else {
        clientCriteriaRequest.clientId = tempClientsList[(rootGetters[ClientsCriteriaGetterInterface.isGrouped] ? 1 : 0)].id
      }
      clientCriteriaRequest.isSelected = true
      Vue.$log.debug('[DEV]: clients.loadClientsCompositionAsync(criteria) >> LOAD CLIENT, CUSTOMER & DEALERPEAK >> STARTED', clientCriteriaRequest.clientId, clientsModel.isAllClientsLoaded, tempClientsList[tempClientsList.length - 1].id, clientsResponse.clientIds[clientsResponse.clientIds.length - 1].clientId)
      const clientResponse = await rtApiService.loadClientDynamicAsync(clientCriteriaRequest)
      Vue.$log.debug('[DEV]: clients.loadClientsCompositionAsync(criteria) >> LOAD CLIENT, CUSTOMER & DEALERPEAK >> ENDED', clientResponse)

      if (clientResponse.client) {
        clientsModel.client = { ...clientResponse.client }
        clientsModel.clientDuplicatesTotal = clientResponse.totalDuplicates
        clientsModel.customer = clientResponse.customer
        clientsModel.dealerPeakProfile = clientResponse.dealerPeakProfile

        // INFO: Load Leads
        const leadsCriteriaRequest = JSON.parse(JSON.stringify(DefaultLeadsCriteriaRequest))
        leadsCriteriaRequest.clientId = clientsModel.client.id
        leadsCriteriaRequest.sortBy[LeadsCriteriaSortByEnum.UpdatedAt] = false // INFO: Ascending
        leadsCriteriaRequest.statuses = []
        leadsCriteriaRequest.dealerCodes = rootGetters[DealersGetterInterface.selectedDealerCodes]
        const leadsResponse = await rtApiService.loadLeadsDynamicAsync(leadsCriteriaRequest)
        clientsModel.leads = leadsResponse || []

        // INFO: Focus/Expand first Lead
        clientsModel.lead = clientsModel.leads[clientsModel.leads.length - 1]

        // INFO: Load Messages
        const messagesCriteriaRequest = JSON.parse(JSON.stringify(DefaultMessagesCriteriaRequest))
        messagesCriteriaRequest.clientId = rootGetters[ClientsCriteriaGetterInterface.clientId]
        messagesCriteriaRequest.leadId = clientsModel.lead.id
        messagesCriteriaRequest.sortBy[MessagesCriteriaSortByEnum.CreatedAt] = false // INFO: Ascending
        messagesCriteriaRequest.types = []
        messagesCriteriaRequest.statuses = []
        messagesCriteriaRequest.dealerCodes = rootGetters[DealersGetterInterface.selectedDealerCodes]
        const messagesResponse = await rtApiService.loadLeadMessagesAsync(messagesCriteriaRequest)
        clientsModel.messages = messagesResponse
      }

      commit(InternalClientsMutationInterface.ClientsLoaded, clientsModel)
    } else {
      // INFO: No Filtered Clients found >> Reset/Cleanup Clients
      commit(InternalClientsMutationInterface.ClientsReset)
    }
    commit(InternalClientsMutationInterface.InProgressIndicator, false)
  },
  loadClientsCompositionNextPageAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    // Vue.$log.debug('[DEV]: clients.loadClientsCompositionNextPageAsync()', !getters.isInitialized, !rootGetters[ClientsCriteriaGetterInterface.isInitialized], getters.isAllClientsLoaded)
    if (!getters.isInitialized || !rootGetters[ClientsCriteriaGetterInterface.isInitialized] || getters.isAllClientsLoaded) return

    const criteria = { ...rootGetters[ClientsCriteriaGetterInterface.criteria] }
    const lastClient = getters.clients[getters.clients.length - 1]
    const pagedClientIdItems = getters.allFilteredClientIds.filter(filteredClientIdItem => filteredClientIdItem.index > lastClient.index && filteredClientIdItem.index <= lastClient.index + MaxPageSize)

    let clientsResponse
    if (rootGetters[ClientsCriteriaGetterInterface.isGrouped]) {
      Vue.$log.debug(`[DEV]: ${'clients'}.loadClientsCompositionNextPageAsync() >> rtApiService.loadStaticGroupedClientsDynamicAsync(criteria, groupedClientIds)`, criteria, pagedClientIdItems)
      clientsResponse = await rtApiService.loadStaticGroupedClientsDynamicAsync(criteria, pagedClientIdItems)
      Vue.$log.debug(`[DEV]: ${'clients'}.loadClientsCompositionNextPageAsync() >> rtApiService.loadStaticGroupedClientsDynamicAsync(criteria, groupedClientIds)`, clientsResponse)
    } else {
      Vue.$log.debug(`[DEV]: ${'clients'}.loadClientsCompositionNextPageAsync() >> rtApiService.loadStaticClientsDynamicAsync(criteria, clientIds)`, criteria, pagedClientIdItems)
      clientsResponse = await rtApiService.loadStaticClientsDynamicAsync(criteria, pagedClientIdItems)
      Vue.$log.debug(`[DEV]: ${'clients'}.loadClientsCompositionNextPageAsync() >> rtApiService.loadStaticClientsDynamicAsync(criteria, clientIds)`, clientsResponse)
    }

    const tempClientsList = []
    if (rootGetters[ClientsCriteriaGetterInterface.isGrouped]) {
      let lastGroupId = lastClient.groupId
      clientsResponse.forEach(client => {
        if (client.groupId !== lastGroupId) { // INFO: Is New Group Header?
          lastGroupId = client.groupId
          const clientIdItem = getters.allFilteredClientIds.find(clientIdItem => clientIdItem.groupId === client.groupId)
          const groupHeader = {
            id: uuid.v4(),
            isGroupHeader: true,
            groupId: clientIdItem.groupId,
            group: clientIdItem.group,
            total: clientIdItem.total,
            size: GroupHeaderListItemHeight,
            type: 'group'
          }
          tempClientsList.push(groupHeader)
        }
        client.isGroupHeader = false
        client.size = StandardListItemHeight
        client.type = 'client'
        client.isMarked = false
        client.isUpdated = false
        client.isOutOfScope = !rootGetters[ClientsCriteriaGetterInterface.isClientInFilterScope](client)
        tempClientsList.push(client)
      })
    } else {
      clientsResponse.forEach(client => {
        client.isGroupHeader = false
        client.size = StandardListItemHeight
        client.type = 'client'
        client.isMarked = false
        client.isUpdated = false
        client.isOutOfScope = !rootGetters[ClientsCriteriaGetterInterface.isClientInFilterScope](client)
        tempClientsList.push(client)
      })
    }

    const clientsModel = {
      isNextPage: true,
      clients: getters.clients.concat(tempClientsList),
      isAllClientsLoaded: tempClientsList[tempClientsList.length - 1].id === getters.allFilteredClientIds[getters.allFilteredClientIds.length - 1].clientId
    }

    commit(InternalClientsMutationInterface.ClientsLoaded, clientsModel)
  },
  verifyNewClientCompositionAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }, clientId) {
    if (!getters.isInitialized || !clientId || getters.allFilteredClientIds.some(clientIdItem => clientIdItem.clientId === clientId)) return

    // INFO: Load New Client
    const clientCriteriaRequest = JSON.parse(JSON.stringify(DefaultClientDynamicCriteriaRequest))
    clientCriteriaRequest.clientId = clientId
    clientCriteriaRequest.isSelected = false
    Vue.$log.debug('[DEV]: clients.verifyNewClientCompositionAsync(criteria) >> LOAD CLIENT >> STARTED', clientId)
    const clientResponse = await rtApiService.loadClientDynamicAsync(clientCriteriaRequest)
    Vue.$log.debug('[DEV]: clients.loadClientCompositionAsync(criteria) >> LOAD CLIENT >> ENDED', clientResponse)

    // INFO: Client not found or not in scope
    if (!clientResponse || !clientResponse.client || !rootGetters[ClientsCriteriaGetterInterface.isClientInFilterScope](clientResponse.client)) return

    commit(InternalClientsMutationInterface.SetPendingNewClient)
  },
  selectClientAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }, selectedClient) {
    // Vue.$log.debug('[DEV]: clients.selectClientAsync(selectedClient)', selectedClient)
    if (typeof selectedClient !== 'string' && typeof selectedClient !== 'object') return

    commit(InternalClientsMutationInterface.ClientReset, true)
    dispatch(ClientsActionInterface.loadClientCompositionAsync, { clientId: selectedClient.id, isUpdated: selectedClient.isUpdated }, { root: true })
  },
  resetClient: function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    // Vue.$log.debug('[DEV]: clients.unselectClientAsync()')
    commit(InternalClientsMutationInterface.ClientReset, true)
  },
  toggleMarkClient: function ({ state, rootState, commit, dispatch, getters, rootGetters }, client) {
    if (typeof client !== 'string' && typeof client !== 'object') return
    if (typeof client === 'string') {
      commit(InternalClientsMutationInterface.ToggledMarkClient, client)
    } else {
      commit(InternalClientsMutationInterface.ToggledMarkClient, client.id)
    }
  },
  toggleMarkAllClients: function ({ state, rootState, commit, dispatch, getters, rootGetters }, isMarkAll) {
    if (typeof isMarkAll !== 'boolean' || getters.isAllClientsMarked === isMarkAll) return
    // Vue.$log.debug('[DEV]: clients.toggleMarkAllClients(isMarkAll)', isMarkAll)
    commit(InternalClientsMutationInterface.ToggledMarkAllClients, isMarkAll)
  },
  mergeClientsAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }, mergeClientsRequest) {
    dispatch(ClientsActionInterface.loadClientsCompositionAsync, null, { root: true })
  },
  // Leads
  expandLeadAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }, lead) {
    if (typeof lead !== 'object') return

    const messagesCriteriaRequest = JSON.parse(JSON.stringify(DefaultMessagesCriteriaRequest))
    messagesCriteriaRequest.clientId = rootGetters[ClientsCriteriaGetterInterface.clientId]
    messagesCriteriaRequest.leadId = lead.id
    messagesCriteriaRequest.sortBy[MessagesCriteriaSortByEnum.CreatedAt] = false // Ascending
    messagesCriteriaRequest.types = []
    messagesCriteriaRequest.statuses = []
    messagesCriteriaRequest.dealerCodes = rootGetters[DealersGetterInterface.selectedDealerCodes]
    const messagesResponse = await rtApiService.loadLeadMessagesAsync(messagesCriteriaRequest)

    const expandedLeadModel = {
      lead,
      messages: messagesResponse
    }

    commit(InternalClientsMutationInterface.LeadExpanded, expandedLeadModel)
  },
  // Messages
  resetMessageFilter: function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    commit(InternalClientsMutationInterface.MessagesFiltered, [])
  },
  setMessageFilter: function ({ state, rootState, commit, dispatch, getters, rootGetters }, payload) {
    if (!Array.isArray(payload)) return
    dispatch(NavigateParticipantsActionInterface.reset, null, { root: true })
    dispatch(NavigateMentionsActionInterface.reset, null, { root: true })
    commit(InternalClientsMutationInterface.MessagesFiltered, payload)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
