<template>
  <div v-if="!isVerifyingAuthentication" class="is-full-height" style="display:grid;place-items:center;">
    <section style="display:grid;">
      <h1 style="font-weight:700;">Eag Live Crm - Authentication</h1>
      <b-field>
        <template #label>Email Address <b-icon pack="fas" icon="star" class="fa-ss" /></template>
        <b-input name="loginEmail" expanded icon-pack="fas" icon="envelope" placeholder="user@email.com" v-model="loginEmail" @keyup.enter="onLoginAsync"/>
      </b-field>
      <b-field>
        <template #label>Password <b-icon pack="fas" icon="star" class="fa-ss" /></template>
        <b-input name="loginPassword" expanded type="password" icon-pack="fas" icon="lock" placeholder="*********" v-model="loginPassword" @keyup.enter="onLoginAsync"/>
      </b-field>
      <b-field position="is-centered" :message="loadingMessage" style="margin:auto;">
        <b-button label="Login" type="is-primary" :loading="isLoading" @click="onLoginAsync" style="margin:auto;"/>
      </b-field>
      <div v-if="errorMessage.length" style="margin:auto;">
        <p>{{ errorMessage }}</p>
      </div>
    </section>
  </div>
</template>

<script>
import { CrmRouteEnum } from '@/enums'
import {
  AuthorizationGetterInterface,
  AuthorizationActionInterface,
  SystemGetterInterface,
  SystemActionInterface
} from '@/store/storeInterfaces'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AppLoginPage',
  data () {
    return {
      CrmRouteEnum,
      AuthorizationGetterInterface,
      AuthorizationActionInterface,
      SystemActionInterface,
      isVerifyingAuthentication: false,
      loginEmail: '',
      loginPassword: '',
      isLoading: false,
      loadingMessage: '',
      errorMessage: ''
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: AuthorizationGetterInterface.isAuthenticated,
      isSystemInitialized: SystemGetterInterface.isSystemInitialized
    })
  },
  methods: {
    ...mapActions({
      loginAsync: AuthorizationActionInterface.loginAsync,
      verifyAsync: AuthorizationActionInterface.verifyAsync,
      systemReset: SystemActionInterface.systemReset
    }),
    onLoginAsync: async function () {
      this.errorMessage = ''
      this.$log.debug(`[DEV]: ${this.$options.name}.onLoginAsync()`, this.loginEmail, this.loginPassword)
      if (this.loginEmail.trim().length === 0 || this.loginPassword.trim().length === 0) return
      this.loadingMessage = 'Verifying your credentials'
      const credentialModel = {
        email: this.loginEmail,
        password: this.loginPassword
      }
      try {
        this.isLoading = true
        this.$log.debug(`[DEV]: ${this.$options.name}.onLoginAsync() >> LOGIN`, credentialModel)
        await this.loginAsync(credentialModel)
        this.isLoading = false
        this.$log.debug(`[DEV]: ${this.$options.name}.onLoginAsync() >> LOGIN SUCCESS`, CrmRouteEnum.LoaderRoute)
        this.$router.push(CrmRouteEnum.LoaderRoute)
      } catch (error) {
        this.$log.debug(`[DEV]: ${this.$options.name}.onLoginAsync() >> LOGIN ERROR`, error)
        this.isLoading = false
        this.errorMessage = error.message
      }
    }
  },
  mounted: async function () {
    this.$log.info(`${this.$options.name} Mounted`)
    this.$log.info(`${this.$options.name} Mounted >> SYSTEM RESET`)
    this.systemReset()
  }
}

</script>

<style scoped>
</style>
