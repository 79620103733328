<template>
  <div style="display: grid; grid-template-rows: 1fr; grid-template-columns: auto auto auto auto; height: 100%;">
    <!-- TODO: INFO: QuickView Notifications -->
    <b-icon pack="fal" icon="bell" size="is-large" type="is-success" style="grid-row: 1; grid-column: 1;" />
    <!-- TODO: INFO: Toggle WiFi Connection -->
    <b-icon pack="fal" icon="wifi" size="is-large" type="is-success" style="grid-row: 1; grid-column: 2;" />
    <!-- INFO: User Logo -->
    <!-- <figure class="image is-32x32 is-flex" style="grid-row: 1; grid-column: 3; margin-top: auto; margin-bottom: auto;">
      <img :src="userLogo" />
    </figure> -->
    <!-- INFO: User Profile Detail -->
    <div style="grid-row: 1; grid-column: 4; display: grid; grid-template-rows: 1f auto; grid-template-columns: 1fr;">
      <!-- INFO: User Menu -->
      <b-dropdown :triggers="['hover']" aria-role="list" style="grid-row: 1; grid-column: 1;">
        <template #trigger>
          <a class="navbar-item" role="button">
            <span>{{ coreProfileUser.fullName }}</span>
            <b-icon pack="fa" icon="angle-down" size="is-small" />
          </a>
        </template>
        <!-- INFO: Signout -->
        <b-dropdown-item aria-role="listitem" @click="onLogoutAsync">Signout</b-dropdown-item>
        <hr class="dropdown-divider">
        <!-- INFO: Versiom -->
        <b-dropdown-item aria-role="listitem">EAG CRM v{{currentAppVersion}}</b-dropdown-item>
      </b-dropdown>
      <!-- INFO: User Designation -->
      <span style="grid-row: 2; grid-column: 1; text-align: center;">{{ coreProfileUser.designationName }}</span>
    </div>
  </div>
</template>

<script>
import {
  AuthorizationActionInterface,
  UsersGetterInterface
} from '@/store/storeInterfaces'
import { CrmRouteEnum } from '@/enums'
import { DefaultAppConfig } from '@/definitions'
import { mapGetters, mapActions } from 'vuex'
import eventBus from '@/eventBus'
import rtApiService from '@/services/rtApiService'
import utilitiesMixin from '@/mixins/utilities'

export default {
  name: 'AppUserProfileComponent',
  mixins: [utilitiesMixin],
  data () {
    return {
      AuthorizationActionInterface,
      UsersGetterInterface
    }
  },
  computed: {
    ...mapGetters({
      coreProfileUser: UsersGetterInterface.coreProfileUser
    }),
    userLogo: function () {
      if (this.coreProfileUser && this.coreProfileUser.fileLocation !== '') {
        return this.coreProfileUser.fileLocation
      }
      return require('@/assets/placeholders/placeholder_48x48.png')
    },
    currentAppVersion: function () {
      return DefaultAppConfig.appVersion
    }
  },
  methods: {
    ...mapActions({
      logoutAsync: AuthorizationActionInterface.logoutAsync
    }),
    onLogoutAsync: async function () {
      if (rtApiService.isConnected()) rtApiService.destroy()
      await this.logoutAsync() // this.$store.dispatch('auth/logoutRequest')
      this.$router.push(CrmRouteEnum.LoginRoute)
    },
    onUpgrade: function (upgradeVersion) {
      window.location.href = process.env.VUE_APP_CRM_SITE + '?v=' + upgradeVersion.currentAppVersion
    },
    onConnectionStatusChanged: function (newValue) {
      if (newValue.connectionStatus === 'Connected') {
        try {
          const announceModel = {
            id: this.coreProfileUser.id,
            name: this.coreProfileUser.fullName,
            appVersion: DefaultAppConfig.appVersion
          }
          const data = rtApiService.announce(announceModel)
          if (data.isError) {
            this.showToast('is-danger', data.message, 5000)
          } else if (data.isUpgradeRequired === true) {
            this.showToast('is-danger', 'A new version of this application is available', 5000)
            this.onUpgrade()
          } else {
            this.showToast('is-success', 'Connected to service successfully', 5000)
          }
        } catch (error) {
          if (error.message !== 'You are not current connected to our service.') {
            this.showToast('is-warning', error.message)
          }
        }
      }
    }
  },
  created: async function () {
    this.$log.info(`${this.$options.name} Created`)
    eventBus.$on('socket-connection-status-changed', this.onConnectionStatusChanged)
  },
  mounted () {
    this.$log.info(`${this.$options.name} Mounted`)
  },
  beforeDestroy () {
    eventBus.$off('socket-connection-status-changed', this.onConnectionStatusChanged)
    this.$log.info(`${this.$options.name} Destroyed`)
  }
}
</script>

<style scoped>
</style>
