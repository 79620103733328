import Vue from 'vue'
import { InternalUiMutationInterface } from '../storeInterfaces.js'

const state = {
  settings: undefined,
  isNavigationPanelExpanded: true,
  isFilterPanelExpanded: true,
  isSidePanelExpanded: true,
  isMessageBoxExpanded: true
}

const getters = {
  isInitialized: state => !!state.settings,
  isNavigationPanelExpanded: state => state.isNavigationPanelExpanded,
  isFilterPanelExpanded: state => state.isFilterPanelExpanded,
  isSidePanelExpanded: state => state.isSidePanelExpanded,
  isMessageBoxExpanded: state => state.isMessageBoxExpanded
}

const mutations = {
  [InternalUiMutationInterface.Initialize] (state) {
    if (!state.settings) {
      const settings = localStorage.getItem('uiSettings')
      if (!settings) {
        state.settings = {
          isNavigationPanelExpanded: true,
          isFilterPanelExpanded: true,
          isSidePanelExpanded: true,
          isMessageBoxExpanded: true
        }
        localStorage.setItem('uiSettings', JSON.stringify(state.settings))
      } else {
        state.settings = { ...JSON.parse(settings) }
      }
    }
    state.isNavigationPanelExpanded = state.settings.isNavigationPanelExpanded
    state.isFilterPanelExpanded = state.settings.isFilterPanelExpanded
    state.isSidePanelExpanded = state.settings.isSidePanelExpanded
    state.isMessageBoxExpanded = state.settings.isMessageBoxExpanded
  },
  [InternalUiMutationInterface.NavigationPanel] (state, isExpanded) {
    if (!state.settings || state.isNavigationPanelExpanded === isExpanded) return
    state.isNavigationPanelExpanded = isExpanded
    state.settings.isNavigationPanelExpanded = state.isNavigationPanelExpanded
    localStorage.setItem('uiSettings', JSON.stringify(state.settings))
  },
  [InternalUiMutationInterface.FilterPanel] (state, isExpanded) {
    if (!state.settings || state.isFilterPanelExpanded === isExpanded) return
    state.isFilterPanelExpanded = isExpanded
    state.settings.isFilterPanelExpanded = state.isFilterPanelExpanded
    localStorage.setItem('uiSettings', JSON.stringify(state.settings))
  },
  [InternalUiMutationInterface.SidePanel] (state, isExpanded) {
    if (!state.settings || state.isSidePanelExpanded === isExpanded) return
    state.isSidePanelExpanded = isExpanded
    state.settings.isSidePanelExpanded = state.isSidePanelExpanded
    localStorage.setItem('uiSettings', JSON.stringify(state.settings))
  },
  [InternalUiMutationInterface.MessageBox] (state, isExpanded) {
    if (!state.settings || state.isMessageBoxExpanded === isExpanded) return
    state.isMessageBoxExpanded = isExpanded
    state.settings.isMessageBoxExpanded = state.isMessageBoxExpanded
    localStorage.setItem('uiSettings', JSON.stringify(state.settings))
  }
}

const actions = {
  initialize: function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    commit(InternalUiMutationInterface.Initialize)
  },
  toggleNavigationPanel: async function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    if (!getters.isInitialized) return
    commit(InternalUiMutationInterface.NavigationPanel, !getters.isNavigationPanelExpanded)
  },
  toggleFilterPanel: async function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    if (!getters.isInitialized) return
    commit(InternalUiMutationInterface.FilterPanel, !getters.isFilterPanelExpanded)
  },
  toggleSidePanel: async function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    if (!getters.isInitialized) return
    commit(InternalUiMutationInterface.SidePanel, !getters.isSidePanelExpanded)
  },
  toggleMessageBox: async function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    if (!getters.isInitialized) return
    commit(InternalUiMutationInterface.MessageBox, !getters.isMessageBoxExpanded)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
