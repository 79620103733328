import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules/modulesIndex'
import adminModules from './admin-modules/modulesIndex'

Vue.use(Vuex)

const isDebug = process.env.NODE_ENV === 'development'

const store = new Vuex.Store({
  strict: isDebug,
  isInternalActive: isDebug,
  modules: {
    ...modules,
    ...adminModules
  }
})

export default store
