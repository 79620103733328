import Vue from 'vue'
import {
  NotificationEventEnum,
  CrmAdminsRouteEnum
} from '../../enums'
import {
  SystemGetterInterface,
  InternalAdministrationMutationInterface,
  AdministrationActionInterface
} from '../storeInterfaces'
import rtApiService from '../../services/rtApiService'

const state = {
  isBusyCounter: 0,
  // Routes
  routeParameters: undefined
}

const getters = {
  isInitialized: state => !!state.routeParameters,
  isBusy: state => state.isBusyCounter > 0,
  // Routes
  hasRouteParamaters: state => !!state.routeParameters,
  routeParameters: state => state.routeParameters
}

const mutations = {
  [InternalAdministrationMutationInterface.Reset] (state) {
    state.isBusyCounter = 0
  },
  [InternalAdministrationMutationInterface.InProgressIndicator] (state, isBusy) {
    if (isBusy) {
      state.isBusyCounter++
    } else if (state.isBusyCounter > 0) {
      state.isBusyCounter--
    }
  },
  [InternalAdministrationMutationInterface.ResetProgressIndicator] (state) {
    state.isBusyCounter = 0
  },
  // Routes
  [InternalAdministrationMutationInterface.RouteParamaters] (state, routeParameters) {
    if (!routeParameters || (!!state.routeParameters && state.routeParameters.filter === routeParameters.filter)) return
    state.routeParameters = { filter: routeParameters.filter }
  }
}

const actions = {
  reset: function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    commit(InternalAdministrationMutationInterface.Reset)
  },
  resetBusy: function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    commit(InternalAdministrationMutationInterface.ResetProgressIndicator)
  },
  setBusy: function ({ state, rootState, commit, dispatch, getters, rootGetters }, isBusy) {
    commit(InternalAdministrationMutationInterface.InProgressIndicator, isBusy)
  },
  notificationAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }, eventData) {
    if (NotificationEventEnum[eventData.event] === NotificationEventEnum.TestNotification) {
      Vue.$log.debug(`[DEV]: ${'admins'}.notificationAsync(eventData) >> ${NotificationEventEnum.keyOf(NotificationEventEnum[eventData.event])} >> START`, eventData)
      // TODO: Add Notifications
      Vue.$log.debug(`[DEV]: ${'admins'}.notificationAsync(eventData) >> ${NotificationEventEnum.keyOf(NotificationEventEnum[eventData.event])} >> END`)
    }
  },
  // Routes
  setRouteParametersAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }, routeParameters) {
    // Vue.$log.debug('[DEV]: AdministrationActionInterface.setRouteParametersAsync(routeParameters)', routeParameters)
    if (!routeParameters || (getters.hasRouteParamaters && JSON.stringify(getters.routeParameters.filter) === JSON.stringify(routeParameters.filter))) return

    commit(InternalAdministrationMutationInterface.InProgressIndicator, true)
    commit(InternalAdministrationMutationInterface.RouteParamaters, routeParameters)
    commit(InternalAdministrationMutationInterface.InProgressIndicator, false)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
