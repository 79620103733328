import Vue from 'vue'
import axios from 'axios'
import config from './config'
import baseService from './baseService'

const subscribersProfileApiService = {
  coreSingleAsync: async function (subscriberId) {
    try {
      const response = await axios.get(config.profileApiUrl + 'subscribers/' + subscriberId, baseService.defaultConfig)
      return response.data
    } catch (error) {
      Vue.$log.debug('[DEV]: subscribersProfileApiService.coreSingleAsync(subscriberId)', error)
      return error.response.data
    }
  }
  // singleAsync: async function (subscriberId) {
  //   try {
  //     Vue.$log.debug('[DEV]: subscribersProfileApiService.singleAsync(subscriberId)', config.crmApiUrl + 'Users/Get/' + subscriberId)
  //     const response = await axios.get('Subscribers/Get/' + subscriberId, baseService.defaultConfig)
  //     return response.data
  //   } catch (error) {
  //     Vue.$log.debug('[DEV]: subscribersProfileApiService.singleAsync(subscriberId)', error)
  //     throw new Error(error)
  //   }
  // }
}

export default subscribersProfileApiService
