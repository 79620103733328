import Vue from 'vue'
import { NotificationEventEnum } from '@/enums'
import {
  SystemActionInterface,
  AuthorizationActionInterface,
  ClientsActionInterface
} from '@/store/storeInterfaces'
import store from '../store/storeIndex'
import eventBus from '@/eventBus'
import { objectKeysToCamelCase } from '@/utils'
import { uuid } from 'vue-uuid'

const signalR = require('@microsoft/signalr')
// const gCacheTimeout = 10000

function createPromise (executor) {
  const result = new Promise(executor)
  result.uuid = uuid.v4()
  // Vue.$log.debug('[DEV]: rtApiService.createPromise(executor)', result)
  return result
}

class RtService {
  connection = undefined
  serviceStatus = undefined
  connectionTimer = undefined
  dataCache = undefined
  loadingListPromise = undefined

  // ===========================================================================================================================
  // System
  // ===========================================================================================================================

  constructor () {
    if (!RtService.instance) {
      this.serviceStatus = {
        isConnected: false,
        isConnecting: false,
        connectionStatus: 'Pending',
        loadingListPromise: null
      }
      this.dataCache = {}
      RtService.instance = this
    }
    return RtService.instance
  }

  getServiceStatus = function () {
    return this.serviceStatus
  }

  isConnected = function () {
    return this.serviceStatus.isConnected
  }

  updateServiceStatus = function (isConnected, isConnecting, newStatus) {
    Vue.$log.info('[RtApiService] updateServiceStatus', isConnected, isConnecting, newStatus)
    this.serviceStatus.isConnected = isConnected
    this.serviceStatus.isConnecting = isConnecting
    this.serviceStatus.connectionStatus = newStatus
    Vue.$log.debug('[Dev - RtApiService] updateServiceStatus >> EVENT >> socket-connection-status-changed', { connectionStatus: newStatus })
    eventBus.emitEvent('socket-connection-status-changed', { connectionStatus: newStatus })
    Vue.$log.debug('[Dev - RtApiService] updateServiceStatus >> DISPATCH >> SystemActionInterface.setConnectionStatus', this.serviceStatus.connectionStatus)
    store.dispatch(SystemActionInterface.setConnectionStatus, this.serviceStatus.connectionStatus) // store.dispatch('auth/setSocketConnectionStatus', this.serviceStatus.connectionStatus)
  }

  toggleSocketAsync = async function (authToken) {
    if (this.connectionTimer) {
      this.connection.stop()
      clearInterval(this.connectionTimer)
      this.connectionTimer = undefined
    } else {
      await this.configureSocketAsync(authToken)
    }
  }

  dispatchNotifictionAsync = async function (eventData) {
    Vue.$log.debug('[Dev - RtApiService]: rtApiService.dispatchNotifictionAsync(eventData)', eventData)
    if (eventData.event) {
      if (NotificationEventEnum[eventData.event].startsWith('NotificationEvent-')) {
        await store.dispatch(ClientsActionInterface.notificationAsync, eventData)
      } else {
        eventBus.emitEvent(NotificationEventEnum[eventData.event], eventData)
      }
    }
  }

  configureSocketAsync = async function (authToken) {
    Vue.$log.info('[RtApiService] configureSocketAsync has been called')
    const that = this
    this.isConnecting = true

    if (this.connection) {
      Vue.$log.info('[RtApiService] configureSocketAsync - Explicitly calling close socket')
      this.connection.stop()
    }

    Vue.$log.debug('[Dev - RtApiService] configureSocketAsync >> CALL >> signalR.HubConnectionBuilder()')
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(process.env.VUE_APP_CRM_API + 'targetHub', {
        accessTokenFactory: () => authToken,
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      })
      .build()
    Vue.$log.debug('[Dev - RtApiService] configureSocketAsync >> RESULT >> signalR.HubConnectionBuilder()', this.connection)

    // INFO: OnNotification: Single Event-Notification
    this.connection.on('OnNotification', async function (target, occurredAt, eventData) {
      Vue.$log.debug('[Dev - RtApiService]: rtApiService.OnNotification(target, occurredAt, eventData)', eventData)
      if (eventData.Event) { // INFO: Ensures camelCase serialization (BUG)
        eventData = objectKeysToCamelCase(eventData)
        Vue.$log.debug('[Dev - RtApiService]: rtApiService.OnNotification(target, occurredAt, eventData) >> CONVERTED', eventData)
      }
      await that.dispatchNotifictionAsync(eventData)
    })

    // INFO: OnNotifications: Multiple Event-Notifications
    this.connection.on('OnNotifications', async function (target, occurredAt, eventsData) {
      Vue.$log.debug('[Dev - RtApiService]: rtApiService.OnNotifications(target, occurredAt, eventsData)', eventsData)
      if (Array.isArray(eventsData)) {
        eventsData.forEach(async (eventData) => {
          if (eventData.Event) { // INFO: Ensures camelCase serialization (BUG)
            eventData = objectKeysToCamelCase(eventData)
            Vue.$log.debug('[Dev - RtApiService]: rtApiService.OnNotification(target, occurredAt, eventsData) >> CONVERTED', eventData)
          }
          await that.dispatchNotifictionAsync(eventData)
        })
      }
    })

    this.connection.onclose(error => {
      that.updateServiceStatus(false, false, 'Disconnected')
      store.dispatch(SystemActionInterface.setConnectionStatus, that.serviceStatus.connectionStatus) // store.dispatch('auth/setSocketConnectionStatus', that.serviceStatus.connectionStatus)
      Vue.$log.info('RPC disconnected')
      if (error) {
        Vue.$log.warn('Closed because of error')
        Vue.$log.warn(error)
        if ((error.response && error.response.status && error.response.status === 401) || (error.statusCode && error.statusCode === 401)) {
          store.dispatch(AuthorizationActionInterface.logoutAsync).then() // store.dispatch('auth/logoutRequest')
        } else {
          that.reconnect(authToken).then()
        }
      } else {
        Vue.$log.info('Closed by user')
      }
    })

    that.updateServiceStatus(false, false, 'Connecting')
    Vue.$log.debug('[Dev - RtApiService] configureSocketAsync >> DISPATCH >> SystemActionInterface.setConnectionStatus', this.serviceStatus.connectionStatus)
    store.dispatch(SystemActionInterface.setConnectionStatus, this.serviceStatus.connectionStatus) // store.dispatch('auth/setSocketConnectionStatus', that.serviceStatus.connectionStatus)

    try {
      Vue.$log.debug('[Dev - RtApiService] configureSocketAsync >> CALL >> connection.start()', this.connection)
      await this.connection.start()
      that.updateServiceStatus(true, false, 'Connected')
      // eventBus.emitEvent('on-connection-established', that.serviceStatus)
    } catch (error) {
      Vue.$log.warn('[RtApiService] configureSocketAsync >> ERROR >> connection.start()', error)
      that.updateServiceStatus(false, false, 'Disconnected')
      if (error.statusCode && error.statusCode === 401) {
        Vue.$log.debug('[Dev - RtApiService] configureSocketAsync >> ERROR >> connection.start() >> DISPATCH >> AuthorizationActionInterface.logoutAsync')
        await store.dispatch(AuthorizationActionInterface.logoutAsync) // store.dispatch('auth/logoutRequest')
      }
    }

    if (this.connectionTimer === undefined) {
      this.connectionTimer = setInterval(async function () {
        if (that.serviceStatus.isConnecting === false && that.serviceStatus.isConnected === false) {
          await that.configureSocketAsync(authToken)
        }
      }, 3000)
    }

    return that.serviceStatus
  }

  reconnect = function (authToken) {
    Vue.$log.debug('[Dev - RtApiService] reconnect(authToken)', authToken)
    const that = this
    clearInterval(that.connectionTimer)
    that.connectionTimer = undefined
    if (that.connectionTimer === undefined) {
      that.connectionTimer = setInterval(function () {
        if (that.serviceStatus.isConnecting === false && that.serviceStatus.isConnected === false) {
          that.configureSocketAsync(authToken).then()
        }
      }, 3000)
    }
  }

  destroy = function () {
    if (this.connectionTimer) {
      clearInterval(this.connectionTimer)
      this.connectionTimer = undefined
    }
    if (this.connection) {
      this.connection.stop()
    }
  }

  announce = async function (data) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          Vue.$log.debug('[DEV]: rtApiService.announce(data)', data)
          const response = await that.connection.invoke('Announce', data)
          Vue.$log.debug('[DEV]: rtApiService.announce(data) >> RESPONSE', response)
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.announce(data) >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  testNotificationAsync = async function () {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          // Vue.$log.debug('[DEV]: rtApiService.testNotificationAsync()')
          const response = await that.connection.invoke('TestNotification')
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.testNotificationAsync() >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  // ===========================================================================================================================
  // Subscriber
  // ===========================================================================================================================

  loadSubscriberReferenceAsync = async function (subscriberId) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          Vue.$log.debug('[DEV]: rtApiService.loadSubscriberReferenceAsync(subscriberId)', subscriberId)
          const response = await that.connection.invoke('GetSubscriberReferenceAsync', subscriberId)
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.loadSubscriberReferenceAsync(subscriberId) >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  loadSubscriberAsync = async function (subscriberId) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          Vue.$log.debug('[DEV]: rtApiService.loadSubscriberAsync(subscriberId)', subscriberId)
          const response = await that.connection.invoke('GetSubscriberAsync', subscriberId)
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.loadSubscriberAsync(subscriberId) >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  // ===========================================================================================================================
  // Users
  // ===========================================================================================================================

  loadUserBySqlIdAsync = async function (sqlId) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('GetUserBySqlIdAsync', sqlId)
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  getAllUsersAsync = async function () {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('GetAllUsersAsync')
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  getAllUserReferencesAsync = async function () {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('GetAllUserReferencesAsync')
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  updateUserSettingsAsync = async function (userId, settings) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('UpdateUserSettingsAsync', userId, settings)
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  deleteUserByIdAsync = async function (userId) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('DeleteUserByIdAsync', userId)
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  // ===========================================================================================================================
  // Teams
  // ===========================================================================================================================

  getAllTeamReferencesAsync = async function (includeMembers) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('GetAllTeamReferencesAsync', null, includeMembers)
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  getAllTeamReferencesByUserIdsAsync = async function (userIds, includeMembers) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('GetAllTeamReferencesByMemberIdsAsync', userIds, includeMembers)
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  getAllTeamMemberReferencesAsync = async function (teamIds) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('GetAllTeamMembersAsync', teamIds)
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  deleteTeamByIdAsync = async function (teamId) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('DeleteTeamByIdAsync', teamId)
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  updateTeamRefAsync = async function (updateTeamCommandModel) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('UpdateTeamRefAsync', updateTeamCommandModel)
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  saveNewTeamRefAsync = async function (createTeamCommandModel) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('SaveNewTeamRefAsync', createTeamCommandModel)
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  // ===========================================================================================================================
  // Clients
  // ===========================================================================================================================

  loadClientsFiltersTotalsAsync = async function () {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('GetClientsFiltersTotalsAsync')
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  // loadClientsGirdAsync = async function (criteria) { (OBSOLETE)
  //   const that = this
  //   return createPromise(async (resolve, reject) => {
  //     if (that.serviceStatus.isConnected) {
  //       try {
  //         Vue.$log.debug('[DEV]: rtApiService.loadClientsGirdAsync(criteria)', criteria)
  //         const response = await that.connection.invoke('FindGridClientsAsync', criteria)
  //         resolve(response)
  //       } catch (error) {
  //         Vue.$log.debug('[DEV]: rtApiService.loadClientsGirdAsync(criteria) >> ERROR', error)
  //         reject(error)
  //       }
  //       return
  //     }
  //     reject(new Error('You are not current connected to our service.'))
  //   })
  // }

  // loadClientsAsync = async function (criteria) { (OBSOLETE)
  //   const that = this
  //   return createPromise(async (resolve, reject) => {
  //     if (that.serviceStatus.isConnected) {
  //       try {
  //         Vue.$log.debug('[DEV]: rtApiService.loadClientsAsync(criteria)', criteria)
  //         const response = await that.connection.invoke('FindClientsAsync', criteria)
  //         resolve(response)
  //       } catch (error) {
  //         Vue.$log.debug('[DEV]: rtApiService.loadClientsAsync(criteria) >> ERROR', error)
  //         reject(error)
  //       }
  //       return
  //     }
  //     reject(new Error('You are not current connected to our service.'))
  //   })
  // }

  // loadDynamicClientsAsync = async function (criteria) { (OBSOLETE) See: loadClientsDynamicAsync(criteria)
  //   const that = this
  //   return createPromise(async (resolve, reject) => {
  //     if (that.serviceStatus.isConnected) {
  //       try {
  //         Vue.$log.debug('[DEV]: rtApiService.loadDynamicClientsAsync(criteria)', criteria)
  //         const response = await that.connection.invoke('FindDynamicClientsAsync', criteria)
  //         resolve(response)
  //       } catch (error) {
  //         Vue.$log.debug('[DEV]: rtApiService.loadDynamicClientsAsync(criteria) >> ERROR', error)
  //         reject(error)
  //       }
  //       return
  //     }
  //     reject(new Error('You are not current connected to our service.'))
  //   })
  // }

  loadClientDynamicAsync = async function (criteria) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          Vue.$log.debug('[DEV]: rtApiService.loadClientDynamicAsync(criteria)', criteria)
          const response = await that.connection.invoke('FindClientDynamicAsync', criteria)
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.loadClientDynamicAsync(criteria) >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  loadClientsDynamicAsync = async function (criteria) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          Vue.$log.debug('[DEV]: rtApiService.loadClientsDynamicAsync(criteria)', criteria)
          const response = await that.connection.invoke('FindClientsDynamicAsync', criteria)
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.loadClientsDynamicAsync(criteria) >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  loadStaticClientsDynamicAsync = async function (criteria, clientIds) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          Vue.$log.debug('[DEV]: rtApiService.loadStaticClientsDynamicAsync(criteria, clientIds)', criteria, clientIds)
          const response = await that.connection.invoke('FindStaticClientsDynamicAsync', criteria, clientIds)
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.loadStaticClientsDynamicAsync(criteria) >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  loadStaticGroupedClientsDynamicAsync = async function (criteria, groupedClientIds) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          Vue.$log.debug('[DEV]: rtApiService.loadStaticGroupedClientsDynamicAsync(criteria, groupedClientIds)', criteria, groupedClientIds)
          const response = await that.connection.invoke('FindStaticGroupedClientsDynamicAsync', criteria, groupedClientIds)
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.loadStaticGroupedClientsDynamicAsync(criteria) >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  loadMergeClientsDynamicAsync = async function (clientIds) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          Vue.$log.debug('[DEV]: rtApiService.loadMergeClientsDynamicAsync(criteria)', clientIds)
          const response = await that.connection.invoke('GetMergeClientsDynamicAsync', clientIds)
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.loadMergeClientsDynamicAsync(clientIds) >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  setClientAssignmentAsync = async function (command) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('SetClientAssignmentAsync', command)
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  changeClientStatusAsync = async function (command) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('ChangeClientStatusAsync', command)
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  changeClientsStatusesAsync = async function (command) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('ChangeClientsStatusesAsync', command)
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  setClientPriorityAsync = async function (command) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('SetClientPriorityAsync', command)
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  setClientsPrioritiesAsync = async function (command) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('SetClientsPrioritiesAsync', command)
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  setClientTagsAsync = async function (command) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('SetClientTagsAsync', command)
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  addWatchListAsync = async function (command) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('AddWatchListAsync', command)
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  removeWatchListAsync = async function (command) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('RemoveWatchListAsync', command)
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  getAllTagsAsync = async function () {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('GetAllTagsAsync')
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  getAllAssignmentsAsync = async function () {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('GetAllAssignmentsAsync')
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  getDuplicateClientsCountAsync = async function (ckientId) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          Vue.$log.debug('[DEV]: rtApiService.getDuplicateClientsCountAsync(ckientId)', ckientId)
          const response = await that.connection.invoke('GetDuplicateClientsCountAsync', ckientId)
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.getDuplicateClientsCountAsync(ckientId) >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  loadDuplicateClientsAsync = async function (ckientId) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          Vue.$log.debug('[DEV]: rtApiService.loadDuplicateClientsAsync(ckientId)', ckientId)
          const response = await that.connection.invoke('GetDuplicateClientsDynamicAsync', ckientId)
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.loadDuplicateClientsAsync(ckientId) >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  mergeClientsAsync = async function (mergeClientsRequest) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          Vue.$log.debug('[DEV]: rtApiService.mergeClientsAsync(mergeClientsRequest)', mergeClientsRequest)
          const response = await that.connection.invoke('MergeClientsAsync', mergeClientsRequest)
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.mergeClientsAsync(mergeClientsRequest) >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  // ===========================================================================================================================
  // Leads
  // ===========================================================================================================================

  loadLeadsGirdAsync = async function (criteria) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          Vue.$log.debug('[DEV]: rtApiService.loadLeadsGirdAsync(criteria)', criteria)
          const response = await that.connection.invoke('FindGridLeadsAsync', criteria)
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.loadLeadsGirdAsync(criteria) >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  loadLeadDynamicAsync = async function (criteria) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          Vue.$log.debug('[DEV]: rtApiService.loadLeadDynamicAsync(criteria)', criteria)
          const response = await that.connection.invoke('FindLeadDynamicAsync', criteria)
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.loadLeadDynamicAsync(criteria) >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  loadLeadsDynamicAsync = async function (criteria) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          Vue.$log.debug('[DEV]: rtApiService.loadLeadsDynamicAsync(criteria)', criteria)
          const response = await that.connection.invoke('FindLeadsDynamicAsync', criteria)
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.loadLeadsDynamicAsync(criteria) >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  getLeadTagsAsync = async function (leadId) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('GetLeadTagsAsync', leadId)
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  getLeadMessageTagsAsync = async function (leadId) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('GetAllLeadMessageTagsAsync', leadId)
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  // ===========================================================================================================================
  // Messages
  // ===========================================================================================================================

  loadLeadMessagesAsync = async function (criteria) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          Vue.$log.debug('[DEV]: rtApiService.loadLeadMessagesAsync(criteria)', criteria)
          const response = await that.connection.invoke('FindMessagesAsync', criteria)
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.loadLeadsGirdAsync(criteria) >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  loadLeadParticipantsAsync = async function (leadId) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          Vue.$log.debug('[DEV]: rtApiService.loadLeadParticipantsAsync(leadId)', leadId)
          const response = await that.connection.invoke('GetAllParticipantsAsync', leadId)
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.loadLeadParticipantsAsync(leadId) >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  createMessageAsync = async function (command) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('CreateMessageAsync', command)
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  findMessageTotalsAsync = async function (criteria) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('FindMessageTotalsAsync', criteria)
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  loadEmailMessageAsync = async function (emailId) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('GetEmail', emailId)
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  getAllLeadMessageMentionUsersAsync = async function (leadId) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('GetAllLeadMessageMentionUsersAsync', leadId)
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  getAllMessageIdsByMentionUserAsync = async function (leadId, userId) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('GetAllMessageIdsByMentionUserAsync', leadId, userId)
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  changeMessageStatusAsync = async function (command) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('ChangeMessageStatusAsync', command)
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  updateMessageTranscriptAsync = async function (command) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          const response = await that.connection.invoke('UpdateMessageTranscriptAsync', command)
          resolve(response)
        } catch (error) {
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  // ===========================================================================================================================
  // Customers
  // ===========================================================================================================================

  loadCustomerDealerPeakAsync = async function (criteria) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          Vue.$log.debug('[DEV]: rtApiService.loadCustomerDealerPeakAsync(criteria)', criteria)
          const response = await that.connection.invoke('FindCustomerDealerPeakAsync', criteria)
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.loadCustomerDealerPeakAsync(criteria) >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  loadSyncSelectedCustomerDealerPeakAsync = async function (criteria) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          Vue.$log.debug('[DEV]: rtApiService.loadSyncSelectedCustomerDealerPeakAsync(criteria)', criteria)
          const response = await that.connection.invoke('SyncSelectedCustomer', criteria)
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.loadSyncSelectedCustomerDealerPeakAsync(criteria) >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  loadCustomersDynamicAsync = async function (byEmailAddress, byPhoneNumber) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          Vue.$log.debug('[DEV]: rtApiService.loadCustomersDynamicAsync(criteria)', byEmailAddress, byPhoneNumber)
          const response = await that.connection.invoke('FindDynamicCustomersAsync', byEmailAddress, byPhoneNumber)
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.loadCustomersDynamicAsync(criteria) >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  loadCustomerAsync = async function (criteria) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          Vue.$log.debug('[DEV]: rtApiService.loadCustomerAsync(criteria)', criteria)
          const response = await that.connection.invoke('FindCustomerAsync', criteria)
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.loadCustomerAsync(criteria) >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  updateCustomerAsync = async function (command) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          Vue.$log.debug('[DEV]: rtApiService.updateCustomerAsync(command)', command)
          const response = await that.connection.invoke('UpdateCustomerAsync', command)
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.updateCustomerAsync(command) >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  addCustomerContactNumberAsync = async function (command) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          Vue.$log.debug('[DEV]: rtApiService.addCustomerContactNumberAsync(command)', command)
          const response = await that.connection.invoke('AddCustomerContactNumberAsync', command)
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.addCustomerContactNumberAsync(command) >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  updateCustomerContactNumberAsync = async function (command) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          Vue.$log.debug('[DEV]: rtApiService.updateCustomerContactNumberAsync(command)', command)
          const response = await that.connection.invoke('UpdateCustomerContactNumberAsync', command)
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.updateCustomerContactNumberAsync(command) >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  deleteCustomerContactNumberAsync = async function (command) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          Vue.$log.debug('[DEV]: rtApiService.deleteCustomerContactNumberAsync(command)', command)
          const response = await that.connection.invoke('DeleteCustomerContactNumberAsync', command)
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.deleteCustomerContactNumberAsync(command) >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  addCustomerEmailAddressAsync = async function (command) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          Vue.$log.debug('[DEV]: rtApiService.addCustomerEmailAddressAsync(command)', command)
          const response = await that.connection.invoke('AddCustomerEmailAddressAsync', command)
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.addCustomerEmailAddressAsync(command) >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  updateCustomerEmailAddressAsync = async function (command) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          Vue.$log.debug('[DEV]: rtApiService.updateCustomerEmailAddressAsync(command)', command)
          const response = await that.connection.invoke('UpdateCustomerEmailAddressAsync', command)
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.updateCustomerEmailAddressAsync(command) >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  deleteCustomerEmailAddressAsync = async function (command) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          Vue.$log.debug('[DEV]: rtApiService.deleteCustomerEmailAddressAsync(command)', command)
          const response = await that.connection.invoke('DeleteCustomerEmailAddressAsync', command)
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.deleteCustomerEmailAddressAsync(command) >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  loadCustomerDuplicatesDynamicAsync = async function () {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          Vue.$log.debug('[DEV]: rtApiService.loadCustomerDuplicatesDynamicAsync()')
          const response = await that.connection.invoke('FindDynamicCustomerDuplicatesAsync')
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.loadCustomerDuplicatesDynamicAsync() >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  // ===========================================================================================================================
  // Blob Storage
  // ===========================================================================================================================

  uploadMessageAttachmentAsync = async function (command) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          Vue.$log.debug('[DEV]: rtApiService.uploadMessageAttachmentAsync(command)', command)
          const response = await that.connection.invoke('UploadMessageAttachmentAsync', command)
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.uploadMessageAttachmentAsync(command) >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }

  deleteMessageAttachmentAsync = async function (transientAttachment) {
    const that = this
    return createPromise(async (resolve, reject) => {
      if (that.serviceStatus.isConnected) {
        try {
          Vue.$log.debug('[DEV]: rtApiService.deleteMessageAttachmentAsync(attachment)', transientAttachment)
          const response = await that.connection.invoke('DeleteMessageAttachmentAsync', transientAttachment)
          resolve(response)
        } catch (error) {
          Vue.$log.debug('[DEV]: rtApiService.deleteMessageAttachmentAsync(attachment) >> ERROR', error)
          reject(error)
        }
        return
      }
      reject(new Error('You are not current connected to our service.'))
    })
  }
}

const rtApiService = new RtService()

export default rtApiService
