import Vue from 'vue'
import Router from 'vue-router'
import { CrmRouteEnum } from '../enums'
import validateAuthorization from './routeGuards'
import clientFilterRoutes from './clientFilters'
import adminFilterRoutes from './adminFilters'
import NotFoundPage from '@/components/generic/NotFoundPage'
import LoginPage from '@/components/AppLoginPage'
import LoaderPage from '@/components/AppLoaderPage'

Vue.use(Router)

const allRoutes = [
  { path: '*', component: NotFoundPage },
  {
    path: '/',
    alias: '/index.html',
    name: 'Loader',
    component: LoaderPage,
    beforeEnter: validateAuthorization
  },
  {
    name: 'Login',
    path: CrmRouteEnum.LoginRoute,
    component: LoginPage,
    props: true
  },
  ...adminFilterRoutes,
  ...clientFilterRoutes
]

const router = new Router({
  mode: 'history',
  linkActiveClass: 'is-active',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: allRoutes
})

router.beforeResolve((to, from, next) => {
  next()
})

// router.afterEach((to, from) => {})

export default router
