import Vue from 'vue'
import authorizationProfileApiService from '../../services/authorizationProfileApiService'
import { AuthorizationStatusEnum } from '../../enums.js'
import { InternalAuthorizationMutationInterface } from '../storeInterfaces.js'

const state = {
  error: '',
  token: undefined,
  status: AuthorizationStatusEnum.None
}

const getters = {
  isAuthenticated: state => !!state.token,
  token: state => state.token
}

const mutations = {
  [InternalAuthorizationMutationInterface.Status]: (state, status) => {
    state.status = status
  },
  [InternalAuthorizationMutationInterface.Success]: (state, token) => {
    state.error = ''
    state.token = token
    state.status = AuthorizationStatusEnum.Success
  },
  [InternalAuthorizationMutationInterface.Failure]: (state, error) => {
    state.error = error
    state.token = undefined
    state.status = AuthorizationStatusEnum.Error
  },
  [InternalAuthorizationMutationInterface.Logout]: (state) => {
    state.error = ''
    state.token = undefined
    state.status = AuthorizationStatusEnum.None
  }
}

const actions = {
  loginAsync: async function ({ commit }, authModel) {
    commit(InternalAuthorizationMutationInterface.Status, AuthorizationStatusEnum.Loading)
    try {
      const response = await authorizationProfileApiService.loginAsync(authModel)
      Vue.$log.debug('[DEV]: store.authorization.loginAsync() >> RESULT', response)
      commit(InternalAuthorizationMutationInterface.Success, response.token)
    } catch (error) {
      Vue.$log.debug('[DEV]: store.authorization.loginAsync() >> ERROR', error)
      commit(InternalAuthorizationMutationInterface.Failure, error)
      await authorizationProfileApiService.logoutAsync()
    }
  },
  verifyAsync: async function ({ commit }) {
    commit(InternalAuthorizationMutationInterface.Status, AuthorizationStatusEnum.Verifying)
    try {
      const response = await authorizationProfileApiService.verifyAsync()
      Vue.$log.debug('[DEV]: store.authorization.verifyAsync() >> RESULT', response)
      commit(InternalAuthorizationMutationInterface.Success, response.token)
    } catch (error) {
      Vue.$log.debug('[DEV]: store.authorization.verifyAsync() >> ERROR', error)
      commit(InternalAuthorizationMutationInterface.Failure, error)
    }
  },
  logoutAsync: async function ({ commit }) {
    const response = await authorizationProfileApiService.logoutAsync()
    Vue.$log.debug('[DEV]: store.authorization.logoutAsync()', response)
    commit(InternalAuthorizationMutationInterface.Logout)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
