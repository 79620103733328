// =====================================================================================================
// Authorization
// =====================================================================================================

export const InternalAuthorizationMutationInterface = {
  get Status () { return 'SET_STATUS' },
  get Success () { return 'SET_SUCCESS' },
  get Failure () { return 'SET_FAIL' },
  get Logout () { return 'SET_LOGOUT' }
}

export const AuthorizationMutationInterface = {
  get Status () { return `authorization/${InternalAuthorizationMutationInterface.Status}` },
  get Success () { return `authorization/${InternalAuthorizationMutationInterface.Success}` },
  get Failure () { return `authorization/${InternalAuthorizationMutationInterface.Failure}` },
  get Logout () { return `authorization/${InternalAuthorizationMutationInterface.Logout}` }
}

export const AuthorizationGetterInterface = {
  get isAuthenticated () { return 'authorization/isAuthenticated' },
  get token () { return 'authorization/token' }
}

export const AuthorizationActionInterface = {
  get loginAsync () { return 'authorization/loginAsync' },
  get verifyAsync () { return 'authorization/verifyAsync' },
  get logoutAsync () { return 'authorization/logoutAsync' }
}

// ==============================================================================================================================
// Users
// ==============================================================================================================================

export const UsersGetterInterface = {
  get isInitialized () { return 'users/isInitialized' },
  get hasCoreProfileUser () { return 'users/hasCoreProfileUser' },
  get coreProfileUser () { return 'users/coreProfileUser' },
  get hasProfileUser () { return 'users/hasProfileUser' },
  get profileUser () { return 'users/profileUser' },
  get hasUsers () { return 'users/hasUsers' },
  get users () { return 'users/users' },
  get user () { return 'users/user' },
  get hasUserReferences () { return 'users/hasUserReferences' },
  get userReferences () { return 'users/userReferences' },
  get userReference () { return 'users/userReference' },
  get defaultTimeZone () { return 'users/defaultTimeZone' },
  get hasProfileSettings () { return 'users/hasProfileSettings' },
  get profileSettings () { return 'users/profileSettings' },
  get hasAllDealerCodes () { return 'users/hasAllDealerCodes' },
  get hasDealerCodes () { return 'users/hasDealerCodes' },
  get dealerCodes () { return 'users/dealerCodes' }
}

export const InternalUsersMutationInterface = {
  get Reset () { return 'SET_RESET' },
  get CoreProfileUser () { return 'SET_CORE_PROFILE_USER' },
  get ProfileUser () { return 'SET_PROFILE_USER' },
  get Users () { return 'SET_USERS' },
  get UserReferences () { return 'SET_USER_REFERENCES' },
  get DefaultTimeZone () { return 'SET_DEFAULT_TIMEZONE' },
  get DealerCodes () { return 'SET_DEALER_CODES' }
}

export const UsersMutationInterface = {
  get Reset () { return `users/${InternalUsersMutationInterface.Reset}` },
  get CoreProfileUser () { return `users/${InternalUsersMutationInterface.CoreProfileUser}` },
  get ProfileUser () { return `users/${InternalUsersMutationInterface.ProfileUser}` },
  get Users () { return `users/${InternalUsersMutationInterface.Users}` },
  get UserReferences () { return `users/${InternalUsersMutationInterface.UserReferences}` },
  get DefaultTimeZone () { return `users/${InternalUsersMutationInterface.DefaultTimeZone}` },
  get DealerCodes () { return `users/${InternalUsersMutationInterface.DealerCodes}` }
}

export const UsersActionInterface = {
  get reset () { return 'users/reset' },
  get loadCoreProfileUserAsync () { return 'users/loadCoreProfileUserAsync' },
  get loadProfileUserAsync () { return 'users/loadProfileUserAsync' },
  get loadAllUsersAsync () { return 'users/loadAllUsersAsync' },
  get loadAllUserReferencesAsync () { return 'users/loadAllUserReferencesAsync' },
  get setDefaultTimeZone () { return 'users/setDefaultTimeZone' },
  get updateDealerCodesAsync () { return 'users/updateDealerCodesAsync' }
}

// ==============================================================================================================================
// Subscriber(s)
// ==============================================================================================================================

export const SubscriberGetterInterface = {
  get isInitialized () { return 'subscriber/isInitialized' },
  // Subscriber
  get hasCoreSubscriber () { return 'subscriber/hasCoreSubscriber' },
  get coreSubscriber () { return 'subscriber/coreSubscriber' },
  get hasSubscriber () { return 'subscriber/hasSubscriber' },
  // Dealers
  get hasDealers () { return 'subscriber/hasDealers' },
  get dealers () { return 'subscriber/dealers' },
  get dealerCodes () { return 'subscriber/dealerCodes' },
  get hasDealerByCode () { return 'subscriber/hasDealerByCode' },
  get dealerByCode () { return 'subscriber/dealerByCode' },
  // Teams
  get hasTeams () { return 'subscriber/hasTeams' },
  get teams () { return 'subscriber/teams' },
  get hasTeamsByDealerCode () { return 'subscriber/hasTeamsByDealerCode' },
  get teamsByDealerCode () { return 'subscriber/teamsByDealerCode' },
  // Team Members
  get hasTeamMembersByDealerCode () { return 'subscriber/hasTeamMembersByDealerCode' },
  get teamMembersByDealerCode () { return 'subscriber/teamMembersByDealerCode' },
  get hasTeamMembersByTeamId () { return 'subscriber/hasTeamMembersByTeamId' },
  get teamMembersByTeamId () { return 'subscriber/teamMembersByTeamId' },
  get hasTeamMemberById () { return 'subscriber/hasTeamMemberById' },
  get teamMemberById () { return 'subscriber/teamMemberById' }
}

export const InternalSubscriberMutationInterface = {
  get Reset () { return 'SET_RESET' },
  get CoreSubscriber () { return 'SET_CORE_SUBSCRIBER' },
  get Subscriber () { return 'SET_SUBSCRIBER' }
}

export const SubscriberMutationInterface = {
  get Reset () { return `subscriber/${InternalSubscriberMutationInterface.Reset}` },
  get CoreSubscriber () { return `subscriber/${InternalSubscriberMutationInterface.CoreSubscriber}` },
  get Subscriber () { return `subscriber/${InternalSubscriberMutationInterface.Subscriber}` }
}

export const SubscriberActionInterface = {
  get reset () { return 'subscriber/reset' },
  get loadCoreSubscriberAsync () { return 'subscriber/loadCoreSubscriberAsync' },
  get loadSubscriberAsync () { return 'subscriber/loadSubscriberAsync' }
}

// ==============================================================================================================================
// Dealers
// ==============================================================================================================================

export const DealersGetterInterface = {
  get isInitialized () { return 'dealers/isInitialized' },
  get coreDealers () { return 'dealers/coreDealers' },
  get dealerCodes () { return 'dealers/dealerCodes' },
  get selectedDealers () { return 'dealers/selectedDealers' },
  get selectedDealerCodes () { return 'dealers/selectedDealerCodes' },
  get isAllDealersSelected () { return 'dealers/coreDealers' }
}

export const InternalDealersMutationInterface = {
  get Reset () { return 'SET_RESET' },
  get CoreDealers () { return 'SET_CORE_DEALERS' },
  get SelectedDealers () { return 'SET_SELECTED_DEALERS' }
}

export const DealersMutationInterface = {
  get Reset () { return `dealers/${InternalDealersMutationInterface.Reset}` },
  get CoreDealers () { return `dealers/${InternalDealersMutationInterface.CoreDealers}` },
  get SelectedDealers () { return `dealers/${InternalDealersMutationInterface.SelectedDealers}` }
}

export const DealersActionInterface = {
  get reset () { return 'dealers/reset' },
  get loadCoreDealersAsync () { return 'dealers/loadCoreDealersAsync' },
  get selectAllDealers () { return 'dealers/selectAllDealers' },
  get selectDealers () { return 'dealers/selectDealers' }
}

// ==============================================================================================================================
// Clients
// ==============================================================================================================================

export const ClientsGetterInterface = {
  get isInitialized () { return 'clients/isInitialized' },
  get isBusy () { return 'clients/isBusy' },
  get isLocked () { return 'clients/isLocked' },
  // Routes
  get hasRouteParamaters () { return 'clients/hasRouteParamaters' },
  get routeParameters () { return 'clients/routeParameters' },
  // get hasRouteFilter () { return 'clients/hasRouteFilter' },
  // get routefilter () { return 'clients/routefilter' },
  get hasRouteTeamId () { return 'clients/hasRouteTeamId' },
  get routeTeamId () { return 'clients/routeTeamId' },
  get hasRouteUserId () { return 'clients/hasRouteUserId' },
  get routeUserId () { return 'clients/routeUserId' },
  // Clients
  get hasPendingNewClients () { return 'clients/hasPendingNewClients' },
  get allFilteredClientIds () { return 'clients/allFilteredClientIds' },
  get hasClientsFiltersTotals () { return 'clients/hasClientsFiltersTotals' },
  get clientsFiltersTotals () { return 'clients/clientsFiltersTotals' },
  get hasClients () { return 'clients/hasClients' },
  get clients () { return 'clients/clients' },
  get clientsTotal () { return 'clients/clientsTotal' },
  get isAllClientsLoaded () { return 'clients/isAllClientsLoaded' },
  get hasClient () { return 'clients/hasClient' },
  // get hasPinnedClient () { return 'clients/hasPinnedClient' },
  get client () { return 'clients/client' },
  get hasClientTags () { return 'clients/hasClientTags' },
  get isAllClientsMarked () { return 'clients/isAllClientsMarked' },
  get hasMarkedClients () { return 'clients/hasMarkedClients' },
  get hasMultipleMarkedClients () { return 'clients/hasMultipleMarkedClients' },
  get canMergeMarkedClients () { return 'clients/canMergeMarkedClients' },
  get markedClients () { return 'clients/markedClients' },
  get isClientMarked () { return 'clients/isClientMarked' },
  // get hasOutOfSyncClients () { return 'clients/hasOutOfSyncClients' },
  // get outOfSyncClientIds () { return 'clients/outOfSyncClientIds' },
  get hasOutOfScopeClients () { return 'clients/hasOutOfScopeClients' },
  get hasUpdatedClients () { return 'clients/hasUpdatedClients' },
  get hasClientDuplicates () { return 'clients/hasClientDuplicates' },
  get clientDuplicatesTotal () { return 'clients/clientDuplicatesTotal' },
  // Customer
  get hasDealerPeakProfile () { return 'clients/hasDealerPeakProfile' },
  get dealerPeakProfile () { return 'clients/dealerPeakProfile' },
  get hasDuplicateDealerPeakProfiles () { return 'clients/hasDuplicateDealerPeakProfiles' },
  // Leads
  get hasLeads () { return 'clients/hasLeads' },
  get leads () { return 'clients/leads' },
  get leadsTotal () { return 'clients/leadsTotal' },
  get hasLead () { return 'clients/hasLead' },
  get hasOpenLead () { return 'clients/hasOpenLead' },
  get lead () { return 'clients/lead' },
  get hasLeadParticipants () { return 'clients/hasLeadParticipants' },
  get hasLeadTags () { return 'clients/hasLeadTags' },
  // Messages
  get hasMessages () { return 'clients/hasMessages' },
  get messages () { return 'clients/messages' },
  get messagesTotal () { return 'clients/messagesTotal' },
  get hasMessagesTags () { return 'clients/hasMessagesTags' },
  get messagesTags () { return 'clients/messagesTags' },
  get leadMessage () { return 'clients/leadMessage' },
  get lastMessage () { return 'clients/lastMessage' },
  get hasMessagesFilter () { return 'clients/hasMessagesFilter' },
  get messagesFilter () { return 'clients/messagesFilter' },
  // Messages Mentions
  get hasMessagesMentions () { return 'clients/hasMessagesMentions' },
  get messageIdsWithMentions () { return 'clients/messageIdsWithMentions' },
  get messagesMentionsUserIds () { return 'clients/messagesMentionsUserIds' },
  get hasMessagesMentionsByUserId () { return 'clients/hasMessagesMentionsByUserId' },
  get messageIdsWithMentionsByUserId () { return 'clients/messageIdsWithMentionsByUserId' }
}

export const InternalClientsMutationInterface = {
  get Reset () { return 'SET_RESET' },
  get InProgressIndicator () { return 'SET_IN_PROGRESS_INDICATOR' },
  get ResetProgressIndicator () { return 'RESET_PROGRESS_INDICATOR' },
  // get LockedIndicator () { return 'SET_LOCKED_INDICATOR' },
  // get ResetLockedIndicator () { return 'RESET_LOCKED_INDICATOR' },
  // Routes
  get RouteParamaters () { return 'SET_ROUTE_PARAMETERS' },
  // Clients
  get ClientsReset () { return 'SET_CLIENTS_RESET' },
  get ClientReset () { return 'SET_CLIENT_RESET' },
  get ClientsFiltersTotals () { return 'SET_CLIENTS_FILTERS_TOTALS' },
  get ClientsTotals () { return 'SET_CLIENTS_TOTALS' },
  get SetPendingNewClient () { return 'SET_PENDING_NEW_CLIENT' },
  get ClientsLoaded () { return 'SET_CLIENTS_LOADED' },
  get ClientLoaded () { return 'SET_CLIENT_LOADED' },
  // get ClientUpdated () { return 'SET_CLIENT_UPDATED' },
  get ToggledMarkClient () { return 'SET_CLIENT_MARKED' },
  get ToggledMarkAllClients () { return 'TOOGLE_CLIENTS_MARKED_ALL' },
  // get ToggledPinClient () { return 'TOOGLE_CLIENT_PINNED' },
  // Leads
  get LeadsLoaded () { return 'SET_LEADS_LOADED' },
  get LeadAdded () { return 'SET_LEAD_ADDED' },
  get LeadExpanded () { return 'SET_LEAD_EXPANDED' },
  // Messages
  get MessageLoaded () { return 'SET_MESSAGE_LOADED' },
  get MessagesLoaded () { return 'SET_MESSAGES_LOADED' },
  // get MessageAdded () { return 'SET_MESSAGE_ADDED' },
  get MessagesFiltered () { return 'SET_MESSAGES_FILTERED' }
}

export const ClientsMutationInterface = {
  get Reset () { return `clients/${InternalClientsMutationInterface.Reset}` },
  get InProgressIndicator () { return `clients/${InternalClientsMutationInterface.InProgressIndicator}` },
  get ResetProgressIndicator () { return `clients/${InternalClientsMutationInterface.ResetProgressIndicator}` },
  // get LockedIndicator () { return `clients/${InternalClientsMutationInterface.LockedIndicator}` },
  // get ResetLockedIndicator () { return `clients/${InternalClientsMutationInterface.ResetLockedIndicator}` },
  // Routes
  get RouteParamaters () { return `clients/${InternalClientsMutationInterface.RouteParamaters}` },
  // Clients
  get ClientsReset () { return `clients/${InternalClientsMutationInterface.ClientsReset}` },
  get ClientReset () { return `clients/${InternalClientsMutationInterface.ClientReset}` },
  get ClientsFiltersTotals () { return `clients/${InternalClientsMutationInterface.ClientsFiltersTotals}` },
  get ClientsTotals () { return `clients/${InternalClientsMutationInterface.SetClientsTotals}` },
  get SetPendingNewClient () { return `clients/${InternalClientsMutationInterface.SetPendingNewClient}` },
  get ClientsLoaded () { return `clients/${InternalClientsMutationInterface.ClientsLoaded}` },
  get ClientLoaded () { return `clients/${InternalClientsMutationInterface.ClientLoaded}` },
  // get ClientUpdated () { return `clients/${InternalClientsMutationInterface.ClientUpdated}` },
  get ToggledMarkClient () { return `clients/${InternalClientsMutationInterface.ToggledMarkClient}` },
  get ToggledMarkAllClients () { return `clients/${InternalClientsMutationInterface.ToggledMarkAllClients}` },
  // get ToggledPinClient () { return `clients/${InternalClientsMutationInterface.ToggledPinClient}` },
  // Leads
  get LeadsLoaded () { return `clients/${InternalClientsMutationInterface.LeadsLoaded}` },
  get LeadExpanded () { return `clients/${InternalClientsMutationInterface.LeadExpanded}` },
  // Messages
  get MessageLoaded () { return `clients/${InternalClientsMutationInterface.MessageLoaded}` },
  get MessagesLoaded () { return `clients/${InternalClientsMutationInterface.MessagesLoaded}` },
  get MessagesFiltered () { return `clients/${InternalClientsMutationInterface.MessagesFiltered}` }
}

export const ClientsActionInterface = {
  get reset () { return 'clients/reset' },
  get resetBusy () { return 'clients/resetBusy' },
  get setBusy () { return 'clients/setBusy' },
  // get resetLocked () { return 'clients/resetLocked' },
  // get setLocked () { return 'clients/setLocked' },
  get notificationAsync () { return 'clients/notificationAsync' },
  // Routes
  get setRouteParametersAsync () { return 'clients/setRouteParametersAsync' },
  // Clients
  get loadClientsFiltersTotalsAsync () { return 'clients/loadClientsFiltersTotalsAsync' },
  get loadClientCompositionAsync () { return 'clients/loadClientCompositionAsync' },
  get loadClientsCompositionAsync () { return 'clients/loadClientsCompositionAsync' },
  get loadClientsCompositionNextPageAsync () { return 'clients/loadClientsCompositionNextPageAsync' },
  get verifyNewClientCompositionAsync () { return 'clients/verifyNewClientCompositionAsync' },
  // get setClientsTotals () { return 'clients/setClientsTotals' },
  get selectClientAsync () { return 'clients/selectClientAsync' },
  get resetClient () { return 'clients/resetClient' },
  // get updatedClient () { return 'clients/updatedClient' },
  get toggleMarkClient () { return 'clients/toggleMarkClient' },
  get toggleMarkAllClients () { return 'clients/toggleMarkAllClients' },
  // get togglePinClient () { return 'clients/togglePinClient' },
  get mergeClientsAsync () { return 'clients/mergeClientsAsync' },
  // Leads
  get expandLeadAsync () { return 'clients/expandLeadAsync' },
  // Messages
  get resetMessageFilter () { return 'clients/resetMessageFilter' },
  get setMessageFilter () { return 'clients/setMessageFilter' }
}

// ==============================================================================================================================
// ClientsCriteria
// ==============================================================================================================================

export const ClientsCriteriaGetterInterface = {
  get isInitialized () { return 'clientsCriteria/isInitialized' },
  get criteria () { return 'clientsCriteria/criteria' },
  get isClientInFilterScope () { return 'clientsCriteria/isClientInFilterScope' },
  get hasFilterSubType () { return 'clientsCriteria/hasFilterSubType' },
  // Criteria Properties
  get page () { return 'clientsCriteria/page' },
  get target () { return 'clientsCriteria/target' },
  get clientId () { return 'clientsCriteria/clientId' },
  get queryText () { return 'clientsCriteria/queryText' },
  get sortBy () { return 'clientsCriteria/sortBy' },
  get startDate () { return 'clientsCriteria/startDate' },
  get endDate () { return 'clientsCriteria/endDate' },
  get clientType () { return 'clientsCriteria/clientType' },
  get filterType () { return 'clientsCriteria/filterType' },
  get filterSubType () { return 'clientsCriteria/filterSubType' },
  get waitingType () { return 'clientsCriteria/waitingType' },
  get waitingSortOrder () { return 'clientsCriteria/waitingSortOrder' },
  get watchListStatus () { return 'clientsCriteria/watchListStatus' },
  get statuses () { return 'clientsCriteria/statuses' },
  get assignedUsers () { return 'clientsCriteria/assignedUsers' },
  get assignedTeams () { return 'clientsCriteria/assignedTeams' },
  get dealerCodes () { return 'clientsCriteria/dealerCodes' },
  get isGrouped () { return 'clientsCriteria/isGrouped' },
  // SearchTags Properties
  get hasSearchTags () { return 'clientsCriteria/hasSearchTags' },
  get searchTags () { return 'clientsCriteria/searchTags' }
}

export const InternalClientsCriteriaMutationInterface = {
  get Reset () { return 'SET_RESET' },
  get SearchTags () { return 'SET_CRITERIA_SEARCH_TAGS' },
  get Criteria () { return 'SET_CRITERIA' }
}

export const ClientsCriteriaMutationInterface = {
  get Reset () { return `clientsCriteria/${InternalClientsCriteriaMutationInterface.Reset}` },
  get SearchTags () { return `clientsCriteria/${InternalClientsCriteriaMutationInterface.SearchTags}` },
  get Criteria () { return `clientsCriteria/${InternalClientsCriteriaMutationInterface.Criteria}` }
}

export const ClientsCriteriaActionInterface = {
  get reset () { return 'clientsCriteria/reset' },
  get setCriteria () { return 'clientsCriteria/setCriteria' },
  get clearSearchTags () { return 'clientsCriteria/clearSearchTags' },
  get setSearchTags () { return 'clientsCriteria/setSearchTags' },
  get setCriteriaByFilterType () { return 'clientsCriteria/setCriteriaByFilterType' }
}

// ==============================================================================================================================
// Teams
// ==============================================================================================================================

export const TeamsGetterInterface = {
  get isInitialized () { return 'teams/isInitialized' },
  get hasTeams () { return 'teams/hasTeams' },
  get teams () { return 'teams/teams' },
  get hasTeamRefs () { return 'teams/hasTeamRefs' },
  get teamRefs () { return 'teams/teamRefs' },
  get teamRef () { return 'teams/teamRef' },
  get isAnyTeamRefMember () { return 'teams/isAnyTeamRefMember' },
  get teamRefsByMember () { return 'teams/teamRefsByMember' },
  // Filters
  get hasFilterTeamRef () { return 'teams/hasFilterTeamRef' },
  get filterTeamRef () { return 'teams/filterTeamRef' },
  get hasFilterTeamRefMember () { return 'teams/hasFilterTeamRefMember' },
  get filterTeamRefMember () { return 'teams/filterTeamRefMember' },
  get hasExpandedFilterTeamRef () { return 'teams/hasExpandedFilterTeamRef' },
  get expandedFilterTeamRef () { return 'teams/expandedFilterTeamRef' },
  get isExpandedFilterTeamRef () { return 'teams/isExpandedFilterTeamRef' },
  get hasFilterTeamRefsByTeamMember () { return 'teams/hasFilterTeamRefsByTeamMember' },
  get filterTeamRefsByTeamMember () { return 'teams/filterTeamRefsByTeamMember' }
}

export const InternalTeamsMutationInterface = {
  get Reset () { return 'SET_RESET' },
  get TeamRefs () { return 'SET_TEAM_REFS' },
  // Filters
  get ClearRefFilters () { return 'SET_CLEAR_REF_FILTERS' },
  get FilterTeamRef () { return 'SET_FILTER_TEAM_REF' },
  get FilterTeamRefMember () { return 'SET_FILTER_TEAM_REF_MEMBER' },
  get ExpandedFilterTeamRef () { return 'SET_EXPANDED_FILTER_TEAM_REF' }
}

export const TeamsMutationInterface = {
  get Reset () { return `teams/${InternalTeamsMutationInterface.Reset}` },
  get TeamRefs () { return `teams/${InternalTeamsMutationInterface.TeamRefs}` },
  // Filters
  get ClearRefFilters () { return `teams/${InternalTeamsMutationInterface.ClearRefFilters}` },
  get FilterTeamRef () { return `teams/${InternalTeamsMutationInterface.FilterTeamRef}` },
  get FilterTeamRefMember () { return `teams/${InternalTeamsMutationInterface.FilterTeamRefMember}` },
  get ExpandedFilterTeamRef () { return `teams/${InternalTeamsMutationInterface.ExpandedFilterTeamRef}` }
}

export const TeamsActionInterface = {
  get reset () { return 'teams/reset' },
  get loadAllTeamReferencesAsync () { return 'teams/loadAllTeamReferencesAsync' },
  get loadAllTeamsAsync () { return 'teams/loadAllTeamsAsync' },
  get clearFilters () { return 'teams/clearFilters' },
  get setFilterTeamRef () { return 'teams/setFilterTeamRef' },
  get setFilterTeamRefMember () { return 'teams/setFilterTeamRefMember' },
  get expandFilterTeamRef () { return 'teams/expandFilterTeamRef' },
  get collapseFilterTeamRef () { return 'teams/collapseFilterTeamRef' }
}

// ==============================================================================================================================
// Customers
// ==============================================================================================================================

export const CustomersGetterInterface = {
  get isInitialized () { return 'customers/isInitialized' },
  get criteria () { return 'customers/criteria' },
  // Customers
  get isAllCustomersLoaded () { return 'customers/isAllCustomersLoaded' },
  get hasCustomers () { return 'customers/hasCustomers' },
  get customersTotal () { return 'customers/customersTotal' },
  get customers () { return 'customers/customers' },
  get hasCustomer () { return 'customers/hasCustomer' },
  get customer () { return 'customers/customer' },
  get hasAdditions () { return 'customers/hasAdditions' },
  get hasContactNumbers () { return 'customers/hasContactNumbers' },
  get hasEmailAddresses () { return 'customers/hasEmailAddresses' },
  // Duplicates
  get hasCustomerDuplicates () { return 'customers/hasCustomerDuplicates' },
  get customerDuplicatesTotal () { return 'customers/customerDuplicatesTotal' },
  get customerDuplicates () { return 'customers/customerDuplicates' }
}

export const InternalCustomersMutationInterface = {
  get Reset () { return 'SET_RESET' },
  // Customers
  get CustomersReset () { return 'SET_CUSTOMERS_RESET' },
  get CustomerReset () { return 'SET_CUSTOMER_RESET' },
  get CustomersLoaded () { return 'SET_CUSTOMERS_LOADED' },
  get CustomerLoaded () { return 'SET_CUSTOMER_LOADED' },
  // Duplicates
  get CustomerDuplicatesLoaded () { return 'SET_CUSTOMER_DUPLICATES_LOADED' }
}

export const CustomersMutationInterface = {
  get Reset () { return `customers/${InternalCustomersMutationInterface.Reset}` },
  // Customers
  get CustomersReset () { return `customers/${InternalCustomersMutationInterface.CustomersReset}` },
  get CustomerReset () { return `customers/${InternalCustomersMutationInterface.CustomerReset}` },
  get CustomersLoaded () { return `customers/${InternalCustomersMutationInterface.CustomersLoaded}` },
  get CustomerLoaded () { return `customers/${InternalCustomersMutationInterface.CustomerLoaded}` },
  // Duplicates
  get CustomerDuplicatesLoaded () { return `customers/${InternalCustomersMutationInterface.CustomerDuplicatesLoaded}` }
}

export const CustomersActionInterface = {
  get reset () { return 'customers/reset' },
  // Customers
  get loadCustomersCompositionAsync () { return 'customers/loadCustomersCompositionAsync' },
  get loadCustomersCompositionNextPageAsync () { return 'customers/loadCustomersCompositionNextPageAsync' },
  get loadCustomerAsync () { return 'customers/loadCustomerAsync' },
  // Duplicates
  get loadCustomerDuplicatesAsync () { return 'customers/loadCustomerDuplicatesAsync' }
}

// =====================================================================================================
// NavigateParticipants
// =====================================================================================================

export const NavigateParticipantsGetterInterface = {
  get hasParticipant () { return 'navigateParticipants/hasParticipant' },
  get hasMessages () { return 'navigateParticipants/hasMessages' },
  get hasMessage () { return 'navigateParticipants/hasMessage' },
  get participant () { return 'navigateParticipants/participant' },
  get messageIds () { return 'navigateParticipants/messageIds' },
  get messageIndex () { return 'navigateParticipants/messageIndex' },
  get messageId () { return 'navigateParticipants/messageId' },
  get messageStatus () { return 'navigateParticipants/messageStatus' }
}
export const InternalNavigateParticipantsMutationInterface = {
  get Reset () { return 'SET_RESET' },
  get Initialize () { return 'SET_INITIALIZE' },
  get InitializeMessages () { return 'SET_MESSAGES' },
  get Select () { return 'SET_SELECT' }
}

export const NavigateParticipantsMutationInterface = {
  get Reset () { return `navigateParticipants/${InternalNavigateParticipantsMutationInterface.Reset}` },
  get Initialize () { return `navigateParticipants/${InternalNavigateParticipantsMutationInterface.Initialize}` },
  get InitializeMessages () { return `navigateParticipants/${InternalNavigateParticipantsMutationInterface.InitializeMessages}` },
  get Select () { return `navigateParticipants/${InternalNavigateParticipantsMutationInterface.Select}` }
}

export const NavigateParticipantsActionInterface = {
  get reset () { return 'navigateParticipants/reset' },
  get setParticipant () { return 'navigateParticipants/setParticipant' },
  get setMessageIds () { return 'navigateParticipants/setMessageIds' },
  get selectPrevMessage () { return 'navigateParticipants/selectPrevMessage' },
  get selectNextMessage () { return 'navigateParticipants/selectNextMessage' }
}

// =====================================================================================================
// NavigateMentions
// =====================================================================================================

export const NavigateMentionsGetterInterface = {
  get hasUser () { return 'navigateMentions/hasUser' },
  get hasMessages () { return 'navigateMentions/hasMessages' },
  get hasMessage () { return 'navigateMentions/hasMessage' },
  get user () { return 'navigateMentions/user' },
  get messageIds () { return 'navigateMentions/messageIds' },
  get messageIndex () { return 'navigateMentions/messageIndex' },
  get messageId () { return 'navigateMentions/messageId' },
  get messageStatus () { return 'navigateMentions/messageStatus' }
}

export const InternalNavigateMentionsMutationInterface = {
  get Reset () { return 'SET_RESET' },
  get Initialize () { return 'SET_INITIALIZE' },
  get InitializeMessages () { return 'SET_MESSAGES' },
  get Select () { return 'SET_SELECT' }
}

export const NavigateMentionsMutationInterface = {
  get Reset () { return `navigateMentions/${InternalNavigateMentionsMutationInterface.Reset}` },
  get Initialize () { return `navigateMentions/${InternalNavigateMentionsMutationInterface.Initialize}` },
  get InitializeMessages () { return `navigateMentions/${InternalNavigateMentionsMutationInterface.InitializeMessages}` },
  get Select () { return `navigateMentions/${InternalNavigateMentionsMutationInterface.Select}` }
}

export const NavigateMentionsActionInterface = {
  get reset () { return 'navigateMentions/reset' },
  get setUser () { return 'navigateMentions/setUser' },
  get setMessageIds () { return 'navigateMentions/setMessageIds' },
  get selectPrevMessage () { return 'navigateMentions/selectPrevMessage' },
  get selectNextMessage () { return 'navigateMentions/selectNextMessage' }
}

// =====================================================================================================
// CoreInventories
// =====================================================================================================

export const InventoriesGetterInterface = {
  get hasCoreInventories () { return 'inventories/hasCoreInventories' },
  get coreInventories () { return 'inventories/coreInventories' },
  get isCoreInventoriesEmpty () { return 'inventories/isCoreInventoriesEmpty' },
  get hasCoreVehicleMakes () { return 'inventories/hasCoreVehicleMakes' },
  get coreVehicleMakes () { return 'inventories/coreVehicleMakes' }
}
export const InternalInventoriesMutationInterface = {
  get Reset () { return 'SET_RESET' },
  get CoreInventories () { return 'SET_INVENTORIES' },
  get CoreVehicleMakes () { return 'SET_VEHICLE_MAKES' }
}

export const InventoriesMutationInterface = {
  get Reset () { return `inventories/${InternalInventoriesMutationInterface.Reset}` },
  get CoreInventories () { return `inventories/${InternalInventoriesMutationInterface.CoreInventories}` },
  get CoreVehicleMakes () { return `inventories/${InternalInventoriesMutationInterface.CoreVehicleMakes}` }
}

export const InventoriesActionInterface = {
  get reset () { return 'inventories/reset' },
  get loadCoreInventoriesAsync () { return 'inventories/loadCoreInventoriesAsync' },
  get loadInventoriesNextPageAsync () { return 'inventories/loadInventoriesNextPageAsync' },
  get loadCoreVehicleMakesAsync () { return 'inventories/loadCoreVehicleMakesAsync' }
}

// ==============================================================================================================================
// InventoriesCriteria
// ==============================================================================================================================

export const InventoriesCriteriaGetterInterface = {
  get criteria () { return 'inventoriesCriteria/criteria' },
  get searchCriteria () { return 'inventoriesCriteria/searchCriteria' }
}

export const InternalInventoriesCriteriaMutationInterface = {
  get Reset () { return 'SET_RESET' },
  get Criteria () { return 'SET_CRITERIA' },
  get SearchCriteria () { return 'SET_SERACH_CRITERIA' }
}

export const InventoriesCriteriaMutationInterface = {
  get Reset () { return `inventoriesCriteria/${InternalInventoriesCriteriaMutationInterface.Reset}` },
  get Criteria () { return `inventoriesCriteria/${InternalInventoriesCriteriaMutationInterface.Criteria}` },
  get SearchCriteria () { return `inventoriesCriteria/${InternalInventoriesCriteriaMutationInterface.SearchCriteria}` }
}

export const InventoriesCriteriaActionInterface = {
  get reset () { return 'inventoriesCriteria/reset' },
  get setCriteria () { return 'inventoriesCriteria/setCriteria' }
}

// ==============================================================================================================================
// ProcessMessages
// ==============================================================================================================================

export const ProcessMessagesGetterInterface = {
  get hasMessages () { return 'processMessages/hasMessages' },
  get messages () { return 'processMessages/messages' },
  get hasLeadMessages () { return 'processMessages/hasLeadMessages' },
  get leadId () { return 'processMessages/leadId' },
  get hasLeadTypeMessage () { return 'processMessages/hasLeadTypeMessage' },
  get hasMessage () { return 'processMessages/hasMessage' },
  get isDelaerPeakMessage () { return 'processMessages/isDelaerPeakMessage' },
  get message () { return 'processMessages/message' },
  get messageType () { return 'processMessages/messageType' },
  get subject () { return 'processMessages/subject' },
  get content () { return 'processMessages/content' },
  get quillContent () { return 'processMessages/quillContent' },
  get messageKey () { return 'processMessages/messageKey' },
  get isMessageValid () { return 'processMessages/isMessageValid' },
  get hasAttachments () { return 'processMessages/hasAttachments' },
  get attachments () { return 'processMessages/attachments' },
  get hasMentions () { return 'processMessages/hasMentions' },
  get mentionedUserIds () { return 'processMessages/mentionedUserIds' },
  get mentionedTeamIds () { return 'processMessages/mentionedTeamIds' },
  get onBehalfOf () { return 'processMessages/onBehalfOf' }
}

export const InternalProcessMessagesMutationInterface = {
  get Reset () { return 'SET_RESET' },
  get SetMessages () { return 'SET_MESSAGES' },
  get SetMessage () { return 'SET_MESSAGE' },
  get SetAttachments () { return 'SET_ATTACHMENTS' },
  get SetMentionedUserIds () { return 'SET_MENTION_USER_IDS' },
  get SetMentionedTeamIds () { return 'SET_MENTION_TEAM_IDS' }
}

export const ProcessMessagesMutationInterface = {
  get Reset () { return `processMessages/${InternalProcessMessagesMutationInterface.Reset}` },
  get SetMessages () { return `processMessages/${InternalProcessMessagesMutationInterface.SetMessages}` },
  get SetMessage () { return `processMessages/${InternalProcessMessagesMutationInterface.SetMessage}` },
  get SetAttachments () { return `processMessages/${InternalProcessMessagesMutationInterface.SetAttachments}` },
  get SetMentionedUserIds () { return `processMessages/${InternalProcessMessagesMutationInterface.SetMentionedUserIds}` },
  get SetMentionedTeamIds () { return `processMessages/${InternalProcessMessagesMutationInterface.SetMentionedTeamIds}` }
}

export const ProcessMessagesActionInterface = {
  get reset () { return 'processMessages/reset' },
  get selectMessage () { return 'processMessages/selectMessage' },
  get setDealerPeakMessageType () { return 'processMessages/setDealerPeakMessageType' },
  get removeLeadMessages () { return 'processMessages/removeLeadMessages' },
  get removeLeadMessage () { return 'processMessages/removeLeadMessage' },
  get resetLeadMessage () { return 'processMessages/resetLeadMessage' },
  get setOnBehalfOf () { return 'processMessages/setOnBehalfOf' },
  get setSubject () { return 'processMessages/setSubject' },
  // get setContent () { return 'processMessages/setContent' },
  get setDraftContent () { return 'processMessages/setDraftContent' },
  get processMessageContent () { return 'processMessages/processMessageContent' },
  get setTypeContent () { return 'processMessages/setTypeContent' },
  get addAttachment () { return 'processMessages/addAttachment' },
  get removeAttachmentAsync () { return 'processMessages/removeAttachmentAsync' },
  get markAttachmentAsDeleted () { return 'processMessages/markAttachmentAsDeleted' },
  get addMentionedUserId () { return 'processMessages/addMentionedUserId' },
  get removeMentionedUserId () { return 'processMessages/removeMentionedUserId' },
  get addMentionedTeamId () { return 'processMessages/addMentionedTeamId' },
  get removeMentionedTeamId () { return 'processMessages/removeMentionedTeamId' }
}

// ==============================================================================================================================
// System
// ==============================================================================================================================

export const SystemGetterInterface = {
  get isLocal () { return 'system/isLocal' },
  get isDevelopment () { return 'system/isDevelopment' },
  get isStaging () { return 'system/isStaging' },
  get isProduction () { return 'system/isProduction' },
  get isSystemInitialized () { return 'system/isSystemInitialized' },
  get connectionStatus () { return 'system/connectionStatus' },
  get hasRoute () { return 'system/hasRoute' },
  get route () { return 'system/route' },
  get hasRouteFilter () { return 'system/hasRouteFilter' },
  get routeFilter () { return 'system/routeFilter' }
}

export const InternalSystemMutationInterface = {
  get SystemReady () { return 'SET_SYSTEM_READY' },
  get ConnectionStatus () { return 'SET_CONNECTION_STATUS' },
  get Route () { return 'SET_ROUTE' }
}

export const SystemMutationInterface = {
  get SystemReady () { return `system/${InternalSystemMutationInterface.SystemReady}` },
  get ConnectionStatus () { return `system/${InternalSystemMutationInterface.ConnectionStatus}` },
  get Route () { return `system/${InternalSystemMutationInterface.Route}` }
}

export const SystemActionInterface = {
  get systemReset () { return 'system/systemReset' },
  get systemInitializeAsync () { return 'system/systemInitializeAsync' },
  get setConnectionStatus () { return 'system/setConnectionStatus' },
  get setRouteAsync () { return 'system/setRouteAsync' }
}

// ==============================================================================================================================
// UI Settings
// ==============================================================================================================================

export const UiGetterInterface = {
  get isInitialized () { return 'uiSettings/isInitialized' },
  get isNavigationPanelExpanded () { return 'uiSettings/isNavigationPanelExpanded' },
  get isFilterPanelExpanded () { return 'uiSettings/isFilterPanelExpanded' },
  get isSidePanelExpanded () { return 'uiSettings/isSidePanelExpanded' },
  get isMessageBoxExpanded () { return 'uiSettings/isMessageBoxExpanded' }
}

export const InternalUiMutationInterface = {
  get Initialize () { return 'SET_INITIALIZE' },
  get NavigationPanel () { return 'SET_NAVIGATIONPANEL' },
  get FilterPanel () { return 'SET_FILTERPANEL' },
  get SidePanel () { return 'SET_SIDEPANEL' },
  get MessageBox () { return 'SET_MESSAGEBOX' }
}

export const UiMutationInterface = {
  get Initialize () { return `uiSettings/${InternalUiMutationInterface.Initialize}` },
  get NavigationPanel () { return `uiSettings/${InternalUiMutationInterface.NavigationPanel}` },
  get FilterPanel () { return `uiSettings/${InternalUiMutationInterface.FilterPanel}` },
  get SidePanel () { return `uiSettings/${InternalUiMutationInterface.SidePanel}` },
  get MessageBox () { return `uiSettings/${InternalUiMutationInterface.MessageBox}` }
}

export const UiActionInterface = {
  get initialize () { return 'uiSettings/initialize' },
  get toggleNavigationPanel () { return 'uiSettings/toggleNavigationPanel' },
  get toggleFilterPanel () { return 'uiSettings/toggleFilterPanel' },
  get toggleSidePanel () { return 'uiSettings/toggleSidePanel' },
  get toggleMessageBox () { return 'uiSettings/toggleMessageBox' }
}

// ==============================================================================================================================
// Administration
// ==============================================================================================================================

export const AdministrationGetterInterface = {
  get isInitialized () { return 'admin/isInitialized' },
  get isBusy () { return 'admin/isBusy' },
  // Routes
  get hasRouteParamaters () { return 'admin/hasRouteParamaters' },
  get routeParameters () { return 'admin/routeParameters' }
}

export const InternalAdministrationMutationInterface = {
  get Reset () { return 'SET_RESET' },
  get InProgressIndicator () { return 'SET_IN_PROGRESS_INDICATOR' },
  get ResetProgressIndicator () { return 'RESET_PROGRESS_INDICATOR' },
  // Routes
  get RouteParamaters () { return 'SET_ROUTE_PARAMETERS' }
}

export const AdministrationMutationInterface = {
  get Reset () { return `admin/${InternalAdministrationMutationInterface.Reset}` },
  get InProgressIndicator () { return `admin/${InternalAdministrationMutationInterface.InProgressIndicator}` },
  get ResetProgressIndicator () { return `admin/${InternalAdministrationMutationInterface.ResetProgressIndicator}` },
  // Routes
  get RouteParamaters () { return `admin/${InternalAdministrationMutationInterface.RouteParamaters}` }
}

export const AdministrationActionInterface = {
  get reset () { return 'admin/reset' },
  get resetBusy () { return 'admin/resetBusy' },
  get setBusy () { return 'admin/setBusy' },
  get notificationAsync () { return 'admin/notificationAsync' },
  // Routes
  get setRouteParametersAsync () { return 'admin/setRouteParametersAsync' }
}
