const crmApiUrl = process.env.VUE_APP_CRM_API
const profileApiUrl = process.env.VUE_APP_PROFILE_API
const eagLiveSiteUrl = process.env.VUE_APP_EAGLIVE_SITE
const inboundEmailApiUrl = process.env.VUE_APP_INBOUND_EMAIL_API
const rerouteApiUrl = process.env.VUE_APP_REROUTE_API
const dealerPeakApiUrl = process.env.VUE_APP_ROOT_DEALERPEAK_API

export default {
  profileApiUrl,
  crmApiUrl,
  eagLiveSiteUrl,
  inboundEmailApiUrl,
  rerouteApiUrl,
  dealerPeakApiUrl
}
