// TODO: Resolve Leads related issues
// TODO: Add MMS-Media content for SMS Messages
// TODO: Add Draft Messages (per Lead)
// TODO: Add Client-List Search functionality

// TODO: Implement Filter Leads
// TODO: Implement Toggle MessageType In/Out Bounds

import Vue from 'vue'
import {
  NotificationEventEnum,
  CrmRouteEnum,
  CrmClientsRouteEnum,
  ClientsFilterTypeEnum,
  ClientStatusEnum,
  LeadsCriteriaSortByEnum,
  LeadStatusEnum,
  MessagesCriteriaSortByEnum,
  MessageTypeEnum
} from '../../enums'
import {
  InternalClientsMutationInterface,
  DealersGetterInterface,
  ClientsActionInterface,
  ClientsCriteriaGetterInterface,
  ClientsCriteriaActionInterface,
  NavigateParticipantsActionInterface,
  NavigateMentionsActionInterface
} from '../storeInterfaces'
import {
  DefaultClientDynamicCriteriaRequest,
  DefaultLeadCriteriaRequest,
  DefaultLeadsCriteriaRequest,
  DefaultMessagesCriteriaRequest,
  StandardListItemHeight,
  GroupHeaderListItemHeight
} from '../../definitions'
import rtApiService from '../../services/rtApiService'

// const TemplateRouteParameters = {
//   filter: CrmClientsRouteEnum.You,
//   teamId: undefined,
//   userId: undefined
// }

// async function loadClientsAsync (clientsCriteria) {
//   if (typeof clientsCriteria !== 'object') return

//   // const clientsResponse = await rtApiService.loadDynamicClientsAsync(clientsCriteria)
//   const clientsResponse = await rtApiService.loadClientsDynamicAsync(clientsCriteria)

//   if (!clientsResponse) return null

//   // Extend Clients, Grouping, etc.
//   const tempClientsList = clientsResponse.clients
//   if (clientsCriteria.isGrouped) {
//     tempClientsList.forEach(client => {
//       if (!client.groupId) { // Missing groupId field?
//         client.isGroupHeader = true
//         client.type = 'group'
//         client.size = GroupHeaderListItemHeight
//       } else {
//         client.isGroupHeader = false
//         client.type = 'client'
//         client.size = StandardListItemHeight
//       }
//       client.isMarked = false
//       client.isPinned = false
//       client.isOutOfSync = false
//       return client
//     })
//   } else {
//     tempClientsList.forEach(client => {
//       client.size = StandardListItemHeight
//       client.type = 'client'
//       client.isGroupHeader = false
//       client.isMarked = false
//       client.isPinned = false
//       client.isOutOfSync = false
//       return client
//     })
//   }
//   clientsResponse.clients = tempClientsList
//   return clientsResponse
// }
// async function loadClientAsync (clientCriteria) {
//   if (typeof clientCriteria !== 'object') return

//   const clientResponse = await rtApiService.loadClientDynamicAsync(clientCriteria)

//   if (!clientResponse.client) return null

//   // Extend Client, Grouping, etc.
//   clientResponse.client.size = StandardListItemHeight
//   clientResponse.client.type = 'client'
//   clientResponse.client.isGroupHeader = false
//   clientResponse.client.isMarked = false
//   clientResponse.client.isPinned = false
//   clientResponse.client.isOutOfSync = false

//   return clientResponse
// }
// async function loadMessagesAsync (messagesCriteria) {
//   if (typeof messagesCriteria !== 'object') return

//   const messagesResponse = await rtApiService.loadLeadMessagesAsync(messagesCriteria)

//   return messagesResponse
// }

const state = {
  isBusyCounter: 0,
  isLockedCounter: 0,
  // Routes
  routeParameters: undefined,
  // Clients
  clientsFiltersTotals: undefined,
  clients: [],
  clientsTotal: 0,
  isAllClientsLoaded: false,
  client: undefined,
  clientDuplicatesTotal: 0,
  isAllClientsMarked: false,
  // Customer
  clientCustomer: undefined,
  dealerPeakProfile: undefined,
  // Leads
  leads: [],
  lead: undefined,
  // Messages
  messages: [],
  messagesFilter: []
}

const getters = {
  isInitialized: state => !!state.routeParameters,
  isBusy: state => state.isBusyCounter > 0,
  // isLocked: state => state.isLockedCounter > 0,
  // Routes
  hasRouteParamaters: state => !!state.routeParameters,
  routeParameters: state => state.routeParameters,
  hasRouteTeamId: state => !!state.routeParameters && !!state.routeParameters.teamId,
  routeTeamId: state => state.routeParameters.teamId,
  hasRouteUserId: state => !!state.routeParameters && !!state.routeParameters.userId,
  routeUserId: state => state.routeParameters.userId,
  // Clients
  hasClientsFiltersTotals: state => !!state.clientsFiltersTotals,
  clientsFiltersTotals: state => state.clientsFiltersTotals,
  hasClients: state => Array.isArray(state.clients) && state.clients.length > 0,
  clients: state => state.clients,
  clientsTotal: state => state.clientsTotal,
  isAllClientsLoaded: state => state.isAllClientsLoaded,
  hasClient: state => !!state.client,
  // hasPinnedClient: state => !!state.client && state.client.isPinned,
  client: state => state.client,
  hasClientTags: state => !!state.client && Array.isArray(state.client.tags) && state.client.tags.length > 0,
  isAllClientsMarked: state => state.isAllClientsMarked,
  hasMarkedClients: state => Array.isArray(state.clients) && state.clients.length > 0 && state.clients.some(client => client.isMarked),
  hasMultipleMarkedClients: state => Array.isArray(state.clients) && state.clients.length > 0 && state.clients.filter(client => client.isMarked).length > 1,
  canMergeMarkedClients: state => Array.isArray(state.clients) && state.clients.length > 0 && state.clients.filter(client => client.isMarked).length === 2,
  markedClients: state => state.clients.filter(client => client.isMarked),
  isClientMarked: (state, getters, rootState, rootGetters) => (clientId) => state.clients.some(client => client.id === clientId && client.isMarked),
  // hasOutOfSyncClients: state => Array.isArray(state.clients) && state.clients.length > 0 && state.clients.some(client => client.isOutOfSync),
  // outOfSyncClientIds: state => Array.isArray(state.clients) && state.clients.length > 0 && state.clients.filter(client => client.isOutOfSync).map(client => client.id),
  hasClientDuplicates: state => state.clientDuplicatesTotal > 1,
  clientDuplicatesTotal: state => state.clientDuplicatesTotal,
  // Customer
  hasDealerPeakProfile: state => state.hasClient && !!state.dealerPeakProfile,
  dealerPeakProfile: state => state.dealerPeakProfile,
  hasDuplicateDealerPeakProfiles: state => state.hasDealerPeakProfile && state.dealerPeakProfile.requiresCrmSelection,
  // Leads
  hasLeads: state => Array.isArray(state.leads) && state.leads.length > 0,
  leads: state => state.leads,
  leadsTotal: state => state.leads.length,
  hasLead: state => !!state.lead,
  hasOpenLead: state => !!state.lead && state.lead.status === LeadStatusEnum.Open,
  lead: state => state.lead,
  hasLeadParticipants: state => !!state.lead && Array.isArray(state.lead.participants) && state.lead.participants.length > 0,
  hasLeadTags: state => !!state.lead && Array.isArray(state.lead.tags) && state.lead.tags.length > 0,
  // Messages
  hasMessages: state => Array.isArray(state.messages) && state.messages.length > 0,
  messagesTotal: state => state.messages.length,
  hasMessagesFilter: state => Array.isArray(state.messagesFilter) && state.messagesFilter.length > 0,
  messagesFilter: state => state.messagesFilter,
  hasMessagesTags: state => Array.isArray(state.messages) && state.messages.length > 0 && state.messages.some(message => Array.isArray(message.tags) && message.tags.length > 0),
  messagesTags: state => [...new Set(state.messages.filter(message => Array.isArray(message.tags) && message.tags.length > 0).map(message => message.tags).flat())],
  leadMessage: state => state.messages.find(message => message.type === MessageTypeEnum.Lead),
  lastMessage: state => state.messages[state.messages.length - 1],
  messages: state => state.messages,
  // Messages Mentions
  // TODO: Add additional Mentioned Users Sorting - See: CrmDetailsPanelComponent.sortedMentionedUserList (MentionsCriteriaSortEnum)
  // TODO: Refactor/Optimize store.navigateMentions
  hasMessagesMentions: state => {
    return Array.isArray(state.messages) && state.messages.length > 0 && // Verify Messages
      state.messages.some(message => Array.isArray(message.mentions) && message.mentions.length > 0) // Verify Messages with Mentions
  },
  messageIdsWithMentions: state => {
    return state.messages.filter(message => Array.isArray(message.mentions) && message.mentions.length > 0) // Filter Messages with Mentions
      .sort((a, b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0)) // Messages Ascending
      .map(message => message.id) // Flatten Message Ids
  },
  messagesMentionsUserIds: state => {
    return [...new Set(
      state.messages.filter(message => Array.isArray(message.mentions) && message.mentions.length > 0) // Filter Messages with Mentions
        .map(message => message.mentions).flat() // Flatten Messages Mentions
        .map(mention => mention.user.id).flat() // Flatten User Ids
    )]
  },
  hasMessagesMentionsByUserId: (state, getters, rootState, rootGetters) => (userId) => {
    return Array.isArray(state.messages) && state.messages.length > 0 && // Verify Messages
      state.messages.some(message => Array.isArray(message.mentions) && // Verify Messages with Mentions
      message.mentions.length > 0 && message.mentions.some(mention => mention.user.id === userId)) // Verify Messages Mentions User Ids
  },
  messageIdsWithMentionsByUserId: (state, getters, rootState, rootGetters) => (userId) => {
    return state.messages.filter(message => Array.isArray(message.mentions) && message.mentions.length > 0 && // Filter Messages
      message.mentions.some(mention => mention.user.id === userId)) // Filter Messages Mentions
      .sort((a, b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0)) // Messages Ascending
      .map(message => message.id) // Flatten Messages Ids
  }
}

const mutations = {
  [InternalClientsMutationInterface.Reset] (state) {
    state.messagesFilter = []
    // state.message = undefined
    state.messages = []
    state.lead = undefined
    state.leads = []
    state.client = undefined
    state.clientDuplicatesTotal = 0
    state.dealerPeakProfile = undefined
    state.clientsTotal = 0
    state.isAllClientsMarked = false
    state.isAllClientsLoaded = false
    state.clientCustomer = undefined
    state.clients = []
    state.routeParameters = undefined
    state.clientsFiltersTotals = undefined
    state.isBusyCounter = 0
    state.isLockedCounter = 0
  },
  [InternalClientsMutationInterface.InProgressIndicator] (state, isBusy) {
    if (state.isLockedCounter > 0) {
      state.isBusyCounter = 0
      return
    }
    if (isBusy) {
      state.isBusyCounter++
    } else if (state.isBusyCounter > 0) {
      state.isBusyCounter--
    }
  },
  [InternalClientsMutationInterface.ResetProgressIndicator] (state) {
    state.isBusyCounter = 0
  },
  // [InternalClientsMutationInterface.LockedIndicator] (state, isLocked) {
  //   if (state.isBusyCounter > 0) {
  //     state.isLockedCounter = 0
  //     return
  //   }
  //   if (isLocked) {
  //     state.isLockedCounter++
  //   } else if (state.isLockedCounter > 0) {
  //     state.isLockedCounter--
  //   }
  // },
  // [InternalClientsMutationInterface.ResetLockedIndicator] (state) {
  //   state.isLockedCounter = 0
  // },
  // Routes
  [InternalClientsMutationInterface.RouteParamaters] (state, routeParameters) {
    if (!routeParameters || (!!state.routeParameters && state.routeParameters.filter === routeParameters.filter && state.routeParameters.teamId === routeParameters.team && state.routeParameters.userId === routeParameters.userId)) return

    // state.message = undefined
    state.messages = []
    state.lead = undefined
    state.leads = []
    state.client = undefined
    state.clientDuplicatesTotal = 0
    state.dealerPeakProfile = undefined
    state.clientsTotal = 0
    state.isAllClientsLoaded = false
    state.clients = []
    state.routeParameters = { filter: routeParameters.filter, teamId: routeParameters.team, userId: routeParameters.user }
  },
  // Clients
  [InternalClientsMutationInterface.ClientsReset] (state) {
    // state.message = undefined
    state.messages = []
    state.lead = undefined
    state.leads = []
    state.client = undefined
    state.clientDuplicatesTotal = 0
    state.dealerPeakProfile = undefined
    state.clientsTotal = 0
    state.isAllClientsLoaded = false
    state.clients = []
  },
  [InternalClientsMutationInterface.ClientReset] (state, fullReset) {
    // state.message = undefined
    state.messages = []
    state.lead = undefined
    state.leads = []
    if (fullReset) {
      state.client = undefined
      state.clientDuplicatesTotal = 0
      state.dealerPeakProfile = undefined
    }
  },
  [InternalClientsMutationInterface.ClientsFiltersTotals] (state, clientsFiltersTotals) {
    if (typeof clientsFiltersTotals !== 'object') return
    state.clientsFiltersTotals = { ...clientsFiltersTotals }
  },
  [InternalClientsMutationInterface.ClientsLoaded] (state, loadedClientsModel) {
    if (!loadedClientsModel) return
    if (!loadedClientsModel.isNextPage) {
      state.messages = loadedClientsModel.messages
      state.lead = loadedClientsModel.lead
      state.leads = loadedClientsModel.leads
      state.client = loadedClientsModel.client
      state.clientDuplicatesTotal = loadedClientsModel.clientDuplicatesTotal
      state.dealerPeakProfile = loadedClientsModel.dealerPeakProfile
    }
    state.clientsTotal = loadedClientsModel.clientsTotal
    state.isAllClientsLoaded = loadedClientsModel.isAllClientsLoaded
    state.clients = [...loadedClientsModel.clients]
  },
  [InternalClientsMutationInterface.ClientLoaded] (state, loadedClientModel) {
    if (typeof loadedClientModel !== 'object') return
    state.messages = loadedClientModel.messages
    state.lead = loadedClientModel.lead
    state.leads = loadedClientModel.leads
    state.client = loadedClientModel.client
    state.clientDuplicatesTotal = loadedClientModel.clientDuplicatesTotal
    state.dealerPeakProfile = loadedClientModel.dealerPeakProfile
    state.clients = [...state.clients]
  },
  [InternalClientsMutationInterface.ClientUpdated] (state, updatedClient) {
    if (typeof updatedClient !== 'object') return
    Vue.$log.debug('[DEV]: clients >> InternalClientsMutationInterface.ClientUpdated >> START', updatedClient)

    // const isTopClient = !!updatedClient.isTopClient
    // updatedClient.isTopClient = false

    if (state.client.id === updatedClient.id) { // Is current Client?
      // updatedClient.isPinned = true
      state.client = { ...updatedClient } // Update current Client
    }

    const clients = [...state.clients]
    // if (isTopClient) {
    //   clients = clients.filter(client => client.id !== updatedClient.id)
    //   clients = [{ ...updatedClient }, ...clients]
    // } else {
    const clientIndex = clients.findIndex(client => client.id === updatedClient.id)
    clients[clientIndex] = { ...updatedClient }
    // }

    state.clients = [...clients]

    Vue.$log.debug('[DEV]: clients >> InternalClientsMutationInterface.ClientUpdated >> END', updatedClient)
  },
  [InternalClientsMutationInterface.ClientsTotals] (state, clientsTotal) {
    state.clientsTotal = clientsTotal
  },
  [InternalClientsMutationInterface.ToggledMarkClient] (state, clientId) {
    if (typeof clientId !== 'string' || state.isAllClientsMarked) return
    const clientIndex = state.clients.findIndex(client => client.id === clientId)
    const affectedClient = state.clients[clientIndex]
    if (state.client.id === affectedClient.id) {
      state.client.isMarked = !affectedClient.isMarked
    }
    affectedClient.isMarked = !affectedClient.isMarked
    state.clients[clientIndex] = { ...affectedClient }
    state.isAllClientsMarked = state.isAllClientsMarked && affectedClient.isMarked
    state.clients = [...state.clients]
    // Vue.$log.debug('[DEV]: clients >> InternalClientsMutationInterface.ToggledMarkClient', state.clients[clientIndex])
  },
  [InternalClientsMutationInterface.ToggledMarkAllClients] (state, isAllMarked) {
    if (typeof isAllMarked !== 'boolean' || state.isAllClientsMarked === isAllMarked) return
    state.isAllClientsMarked = isAllMarked
    state.clients.forEach(client => {
      client.isMarked = state.isAllClientsMarked
      return client
    })
    state.clients = [...state.clients]
  },
  // [InternalClientsMutationInterface.ToggledPinClient] (state) {
  //   if (!state.client) return
  //   state.clients.forEach(client => {
  //     if (client.id === state.client.id) {
  //       client.isPinned = !client.isPinned
  //       state.client = { ...client }
  //     }
  //     return client
  //   })
  //   state.clients = [...state.clients]
  //   state.client.isPinned = !state.client.isPinned
  // },
  // Leads
  [InternalClientsMutationInterface.LeadsLoaded] (state, loadedLeadsModel) {
    if (typeof loadedLeadsModel !== 'object') return
    state.lead = loadedLeadsModel.lead
    state.leads = loadedLeadsModel.leads
  },
  [InternalClientsMutationInterface.LeadAdded] (state, addedLeadModel) {
    if (typeof addedLeadModel !== 'object') return
    // state.lead = addedLeadModel.lead
    state.leads = addedLeadModel.leads
  },
  [InternalClientsMutationInterface.LeadExpanded] (state, expandedLeadModel) {
    if (typeof expandedLeadModel !== 'object') return
    state.messages = expandedLeadModel.messages
    state.lead = { ...expandedLeadModel.lead }
    state.leads = [...state.leads]
  },
  // Messages
  [InternalClientsMutationInterface.MessageLoaded] (state, loadedMessage) {
    if (!loadedMessage) return
    const messageIndex = state.messages.findIndex(message => message.id === loadedMessage.id)
    if (messageIndex !== -1) {
      state.messages[messageIndex] = { ...loadedMessage }
    }
  },
  [InternalClientsMutationInterface.MessagesLoaded] (state, loadedMessages) {
    if (!Array.isArray(loadedMessages)) return
    state.messages = loadedMessages
  },
  [InternalClientsMutationInterface.MessagesFiltered] (state, messagesFilter) {
    if (!Array.isArray(messagesFilter)) return
    state.messagesFilter = messagesFilter
  }
}

const actions = {
  reset: function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    commit(InternalClientsMutationInterface.Reset)
  },
  resetBusy: function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    commit(InternalClientsMutationInterface.ResetProgressIndicator)
  },
  setBusy: function ({ state, rootState, commit, dispatch, getters, rootGetters }, isBusy) {
    commit(InternalClientsMutationInterface.InProgressIndicator, isBusy)
  },
  // resetLocked: function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
  //   commit(InternalClientsMutationInterface.ResetLockedIndicator)
  // },
  // setLocked: function ({ state, rootState, commit, dispatch, getters, rootGetters }, isLocked) {
  //   commit(InternalClientsMutationInterface.LockedIndicator, isLocked)
  // },
  notificationAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }, eventData) {
    Vue.$log.debug(`[DEV]: ${'clients'}.notificationAsync(eventData)`, eventData.event, NotificationEventEnum[eventData.event])

    // INFO: Update Root-Filter Totals
    await dispatch(ClientsActionInterface.loadClientsFiltersTotalsAsync, null, { root: true })

    if (NotificationEventEnum[eventData.event] === NotificationEventEnum.LeadAdded) {
      const effectedClientIndex = getters.clients.findIndex(client => client.id === eventData.clientId)
      if (effectedClientIndex !== -1) { // Is Client available?
        Vue.$log.debug(`[DEV]: ${'clients'}.notificationAsync(eventData) >> ${NotificationEventEnum.keyOf(NotificationEventEnum[eventData.event])} >> START`, eventData)

        const effectedClient = getters.clients[effectedClientIndex]
        dispatch(ClientsActionInterface.updatedClient, effectedClient, { root: true })

        if (getters.client.id === eventData.clientId) { // Is current Client?
          // Load & append the new Lead
          const leadCriteriaRequest = JSON.parse(JSON.stringify(DefaultLeadCriteriaRequest))
          leadCriteriaRequest.clientId = getters.client.id
          leadCriteriaRequest.leadId = eventData.leadId
          const leadResponse = await rtApiService.loadLeadDynamicAsync(leadCriteriaRequest)

          if (leadResponse) {
            const allLeads = [...getters.leads, leadResponse]
            const addedLeadModel = {
              lead: leadResponse,
              leads: allLeads
            }

            // Update Leads
            commit(InternalClientsMutationInterface.LeadAdded, addedLeadModel)
          }
        }

        Vue.$log.debug(`[DEV]: ${'clients'}.notificationAsync(eventData) >> ${NotificationEventEnum.keyOf(NotificationEventEnum[eventData.event])} >> END`)
      }
    } else if (NotificationEventEnum[eventData.event] === NotificationEventEnum.MessageAdded) {
      const effectedClientIndex = getters.clients.findIndex(client => client.id === eventData.clientId)
      if (effectedClientIndex !== -1) { // Is Client available?
        Vue.$log.debug(`[DEV]: ${'clients'}.notificationAsync(eventData) >> ${NotificationEventEnum.keyOf(NotificationEventEnum[eventData.event])} >> START`, eventData)

        // Load Client
        const clientCriteriaRequest = JSON.parse(JSON.stringify(DefaultClientDynamicCriteriaRequest))
        clientCriteriaRequest.clientId = eventData.clientId
        const clientResponse = await rtApiService.loadClientDynamicAsync(clientCriteriaRequest)

        if (!clientResponse || !clientResponse.client) return

        const upadetedClient = clientResponse.client
        // if (rootGetters[ClientsCriteriaGetterInterface.isGrouped]) { // Require Group Header Id?
        //   upadetedClient.groupId = getters.clients[effectedClientIndex].groupId
        // }
        // upadetedClient.isMarked = getters.isClientMarked(upadetedClient.id)
        // upadetedClient.isTopClient = true
        dispatch(ClientsActionInterface.updatedClient, upadetedClient, { root: true })

        if (getters.client.id === eventData.clientId && getters.lead.id === eventData.leadId) { // Is current Client and Lead?
          // Load Lead
          const leadCriteriaRequest = JSON.parse(JSON.stringify(DefaultLeadCriteriaRequest))
          leadCriteriaRequest.clientId = eventData.clientId
          leadCriteriaRequest.leadId = eventData.leadId
          const leadResponse = await rtApiService.loadLeadDynamicAsync(leadCriteriaRequest)

          dispatch(ClientsActionInterface.expandLeadAsync, getters.lead, { root: true })
        }

        Vue.$log.debug(`[DEV]: ${'clients'}.notificationAsync(eventData) >> ${NotificationEventEnum.keyOf(NotificationEventEnum[eventData.event])} >> END`)
      }
    } else if (NotificationEventEnum[eventData.event] === NotificationEventEnum.MessageStatusUpdated ||
      NotificationEventEnum[eventData.event] === NotificationEventEnum.MessageTransactionUpdated) {
      const effectedClientIndex = getters.clients.findIndex(client => client.id === eventData.clientId)
      if (effectedClientIndex !== -1) { // Is Client available?
        Vue.$log.debug(`[DEV]: ${'clients'}.notificationAsync(eventData) >> ${NotificationEventEnum.keyOf(NotificationEventEnum[eventData.event])} >> START`, eventData)

        const effectedClient = { ...getters.clients[effectedClientIndex] }
        dispatch(ClientsActionInterface.updatedClient, effectedClient, { root: true })

        if (getters.client.id === eventData.clientId && getters.lead.id === eventData.leadId) { // Is current Client and Lead?
          dispatch(ClientsActionInterface.expandLeadAsync, getters.lead, { root: true })
        }

        Vue.$log.debug(`[DEV]: ${'clients'}.notificationAsync(eventData) >> ${NotificationEventEnum.keyOf(NotificationEventEnum[eventData.event])} >> END`)
      }
    } else if (NotificationEventEnum[eventData.event] === NotificationEventEnum.ClientAssignmentUpdated ||
      NotificationEventEnum[eventData.event] === NotificationEventEnum.ClientTagsUpdated ||
      NotificationEventEnum[eventData.event] === NotificationEventEnum.ClientStatusUpdated ||
      NotificationEventEnum[eventData.event] === NotificationEventEnum.ClientPriorityUpdated ||
      NotificationEventEnum[eventData.event] === NotificationEventEnum.ClientWatchListAdded ||
      NotificationEventEnum[eventData.event] === NotificationEventEnum.ClientWatchListRemoved ||
      NotificationEventEnum[eventData.event] === NotificationEventEnum.ClientsMerged) {
      if (getters.clients.some(client => client.id === eventData.clientId)) { // Is Client available?
        Vue.$log.debug(`[DEV]: ${'clients'}.notificationAsync(eventData) >> ${NotificationEventEnum.keyOf(NotificationEventEnum[eventData.event])} >> START`, eventData)

        const effectedClientIndex = getters.clients.findIndex(client => client.id === eventData.clientId)

        // Load Client
        const clientCriteriaRequest = JSON.parse(JSON.stringify(DefaultClientDynamicCriteriaRequest))
        clientCriteriaRequest.clientId = eventData.clientId
        const clientResponse = await rtApiService.loadClientDynamicAsync(clientCriteriaRequest)

        if (clientResponse && clientResponse.client) {
          const upadetedClient = clientResponse.client
          if (rootGetters[ClientsCriteriaGetterInterface.isGrouped]) { // Require Group Header Id?
            upadetedClient.groupId = getters.clients[effectedClientIndex].groupId
          }
          upadetedClient.isMarked = getters.isClientMarked(upadetedClient.id)
          // TODO: Fix upadetedClient.ClosedMessageContent...
          if (NotificationEventEnum[eventData.event] === NotificationEventEnum.ClientStatusUpdated && clientResponse.client.status === ClientStatusEnum.Closed) {
            upadetedClient.ClosedMessageContent = eventData.message.content
          }
          dispatch(ClientsActionInterface.updatedClient, upadetedClient, { root: true })

          if (getters.client.id === eventData.clientId) { // Is current Client and Lead?
            dispatch(ClientsActionInterface.expandLeadAsync, getters.lead, { root: true })
          }
        }

        Vue.$log.debug(`[DEV]: ${'clients'}.notificationAsync(eventData) >> ${NotificationEventEnum.keyOf(NotificationEventEnum[eventData.event])} >> END`)
      }
    } else if (NotificationEventEnum[eventData.event] === NotificationEventEnum.ClientsStatusesUpdated ||
      NotificationEventEnum[eventData.event] === NotificationEventEnum.ClientsPrioritiesUpdated ||
      NotificationEventEnum[eventData.event] === NotificationEventEnum.ClientsCustomersUpdated) {
      Vue.$log.debug(`[DEV]: ${'clients'}.notificationAsync(eventData) >> ${NotificationEventEnum.keyOf(NotificationEventEnum[eventData.event])} >> START`, eventData)

      // TODO: FIX >> NotificationEventEnum.ClientsCustomersUpdated

      // INFO: Reload "available" updated Clients
      const criteria = { ...rootGetters[ClientsCriteriaGetterInterface.criteria] }
      criteria.clientIds = eventData.clientIds.filter(clientId => getters.clients.some(client => client.id === clientId))
      criteria.isGrouped = false
      const clientsResponse = await rtApiService.loadClientsDynamicAsync(criteria)

      if (clientsResponse) {
        await clientsResponse.clients.forEach(async (upadetedClient) => {
          const effectedClientIndex = getters.clients.findIndex(client => client.id === upadetedClient.id)
          if (effectedClientIndex !== -1) { // Is Client available?
            if (rootGetters[ClientsCriteriaGetterInterface.isGrouped]) { // Require Group Header Id?
              upadetedClient.groupId = getters.clients[effectedClientIndex].groupId
            }
            upadetedClient.isMarked = getters.isClientMarked(upadetedClient.id)
            dispatch(ClientsActionInterface.updatedClient, upadetedClient, { root: true })

            if (eventData.clientIds.some(clientId => clientId === getters.client.id)) { // Is current Client and Lead included?
              dispatch(ClientsActionInterface.expandLeadAsync, getters.lead, { root: true })
            }
          }
        })
      }

      Vue.$log.debug(`[DEV]: ${'clients'}.notificationAsync(eventData) >> ${NotificationEventEnum.keyOf(NotificationEventEnum[eventData.event])} >> END`)
    }
  },
  // Routes
  setRouteParametersAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }, routeParameters) {
    // Vue.$log.debug('[DEV]: clients.setRouteParametersAsync(routeParameters)', routeParameters)
    if (!routeParameters || (getters.hasRouteParamaters && JSON.stringify(getters.routeParameters) === JSON.stringify(routeParameters))) return

    commit(InternalClientsMutationInterface.InProgressIndicator, true)

    // INFO: Reset any currently pinned Client
    // if (getters.hasPinnedClient) {
    //   commit(InternalClientsMutationInterface.ToggledPinClient)
    // }

    commit(InternalClientsMutationInterface.RouteParamaters, routeParameters)
    // Vue.$log.debug('[DEV]: clients.routeParameters', getters.routeParameters)
    const targetPath = `/${CrmRouteEnum.Clients}/${getters.routeParameters.filter}`
    // Vue.$log.debug('[DEV]: clients.setRouteParametersAsync(routeParameters)', targetRouteFilter)
    const filterType = ClientsFilterTypeEnum[CrmClientsRouteEnum.keyOf(targetPath)]
    // Vue.$log.debug('[DEV]: clients.setRouteParametersAsync(routeParameters)', filterType)

    dispatch(ClientsCriteriaActionInterface.setCriteriaByFilterType, filterType, { root: true })
    // Vue.$log.debug('[DEV]: clients.setRouteParametersAsync(routeParameters) >> rootGetters[ClientsCriteriaGetterInterface.filterType]', rootGetters[ClientsCriteriaGetterInterface.filterType])
    await dispatch(ClientsActionInterface.loadClientsFiltersTotalsAsync, null, { root: true })
    await dispatch(ClientsActionInterface.loadClientsCompositionAsync, null, { root: true })

    commit(InternalClientsMutationInterface.InProgressIndicator, false)
  },
  // Clients
  loadClientsFiltersTotalsAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }, criteria) {
    if (!getters.isInitialized || (!criteria && !rootGetters[ClientsCriteriaGetterInterface.isInitialized])) return
    // commit(InternalClientsMutationInterface.InProgressIndicator, true)
    const totalsResponse = await rtApiService.loadClientsFiltersTotalsAsync()
    commit(InternalClientsMutationInterface.ClientsFiltersTotals, totalsResponse)
    // commit(InternalClientsMutationInterface.InProgressIndicator, false)
  },
  loadClientsCompositionAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }, criteria) {
    if (!getters.isInitialized || (!criteria && !rootGetters[ClientsCriteriaGetterInterface.isInitialized])) return

    commit(InternalClientsMutationInterface.InProgressIndicator, true)

    const hasSelectedClient = getters.hasClient
    const selectedClientId = getters.hasClient ? getters.client.id : undefined
    // const pinnedClient = getters.hasPinnedClient ? { ...getters.client } : undefined
    // if (pinnedClient) {
    //   pinnedClient.isMarked = false
    //   pinnedClient.isOutOfSync = false
    // }

    const isCustomCriteria = !!criteria

    // Load Clients
    if (!isCustomCriteria) {
      // Vue.$log.debug('[DEV]: clients.loadClientsCompositionAsync(criteria) >> rootGetters[ClientsCriteriaGetterInterface.criteria]', rootGetters[ClientsCriteriaGetterInterface.criteria])
      if (getters.isAllClientsMarked) {
        commit(InternalClientsMutationInterface.ToggledMarkAllClients, false)
      }
      criteria = { ...rootGetters[ClientsCriteriaGetterInterface.criteria] }
    }

    // Vue.$log.debug(`[DEV]: ${'clients'}.loadClientsCompositionAsync(criteria) >> rtApiService.loadDynamicClientsAsync(criteria)`, clientsResponse)
    const clientsResponse = await rtApiService.loadClientsDynamicAsync(criteria)
    Vue.$log.debug(`[DEV]: ${'clients'}.loadClientsCompositionAsync(criteria) >> rtApiService.loadClientsDynamicAsync(criteria)`, clientsResponse)

    if (!clientsResponse) {
      commit(InternalClientsMutationInterface.InProgressIndicator, false)
      return
    }

    if (!isCustomCriteria) { // Update Criteria?
      dispatch(ClientsCriteriaActionInterface.setCriteria, criteria, { root: true })
    }

    // Extend Clients, Grouping, etc.
    const tempClientsList = clientsResponse.clients
    if (rootGetters[ClientsCriteriaGetterInterface.isGrouped]) {
      tempClientsList.forEach(client => {
        if (!client.groupId) { // Missing groupId field?
          client.isGroupHeader = true
          client.size = GroupHeaderListItemHeight
          client.type = 'group'
        } else {
          client.isGroupHeader = false
          client.size = StandardListItemHeight
          client.type = 'client'
        }
        client.isMarked = false
        // client.isPinned = false
        // client.isOutOfSync = false
        client.dealerPeakProfile = undefined
        return client
      })
    } else {
      tempClientsList.forEach(client => {
        client.isGroupHeader = false
        client.size = StandardListItemHeight
        client.type = 'client'
        client.isMarked = false
        // client.isPinned = false
        // client.isOutOfSync = false
        client.dealerPeakProfile = undefined
        return client
      })
    }

    let selectedClient = tempClientsList.length > 0 ? tempClientsList[rootGetters[ClientsCriteriaGetterInterface.isGrouped] ? 1 : 0] : undefined
    if (selectedClient && selectedClientId && tempClientsList.some(client => client.id === selectedClientId)) {
      selectedClient = tempClientsList.find(client => client.id === selectedClientId)
    }

    const clientsModel = {
      isNextPage: false,
      clients: tempClientsList,
      clientsTotal: clientsResponse.total,
      isAllClientsLoaded: clientsResponse.isLastPage,
      // client: pinnedClient || tempClientsList[rootGetters[ClientsCriteriaGetterInterface.isGrouped] ? 1 : 0],
      client: selectedClient,
      clientDuplicatesTotal: 0,
      leads: [],
      leadsTotal: 0,
      lead: undefined,
      messages: [],
      messagesTotal: 0,
      dealerPeakProfile: undefined
    }

    if (clientsModel.client) {
      // INFO: Update selected Client Duplicates Count
      clientsModel.clientDuplicatesTotal = await rtApiService.getDuplicateClientsCountAsync(clientsModel.client.id)
    }

    if (clientsModel.clients.length > 0) {
      Vue.$log.debug('[DEV]: clients.loadClientsCompositionAsync(criteria) >> Client', clientsModel.client)

      // Load first Client
      const clientCriteriaRequest = JSON.parse(JSON.stringify(DefaultClientDynamicCriteriaRequest))
      if (hasSelectedClient && clientsModel.clients.some(client => client.id === selectedClientId)) {
        clientCriteriaRequest.clientId = selectedClientId
        // Vue.$log.debug('[DEV]: clients.loadClientsCompositionAsync(criteria) >> LOAD CLIENT >> hasSelectedClient', hasSelectedClient, selectedClientId)
      } else {
        clientCriteriaRequest.clientId = clientsModel.client.id
        // Vue.$log.debug('[DEV]: clients.loadClientsCompositionAsync(criteria) >> LOAD CLIENT >> hasSelectedClient', hasSelectedClient, clientsModel.client.id)
      }
      // clientCriteriaRequest.isOutOfSync = true
      Vue.$log.debug('[DEV]: clients.loadClientsCompositionAsync(criteria) >> LOAD CLIENT & DEALERPEAK >> STARTED', clientsModel.client.id, clientCriteriaRequest)
      const clientResponse = await rtApiService.loadClientDynamicAsync(clientCriteriaRequest)
      Vue.$log.debug('[DEV]: clients.loadClientsCompositionAsync(criteria) >> LOAD CLIENT & DEALERPEAK >> ENDED', clientResponse.client.id, clientResponse)

      if (!clientResponse || !clientResponse.client) return

      clientResponse.client.size = clientsModel.client.size
      clientResponse.client.type = clientsModel.client.type
      clientResponse.client.isGroupHeader = clientsModel.client.isGroupHeader
      // clientResponse.client.isMarked = clientsModel.client.isMarked
      clientResponse.client.isMarked = false
      // clientResponse.client.isPinned = clientsModel.client.isPinned
      // clientResponse.client.isOutOfSync = clientsModel.client.isOutOfSync
      clientsModel.client = { ...clientResponse.client }
      clientsModel.dealerPeakProfile = clientResponse.dealerPeakProfile ? clientResponse.dealerPeakProfile : undefined

      // Load Leads
      const leadsCriteriaRequest = JSON.parse(JSON.stringify(DefaultLeadsCriteriaRequest))
      leadsCriteriaRequest.clientId = clientsModel.client.id
      leadsCriteriaRequest.sortBy[LeadsCriteriaSortByEnum.UpdatedAt] = false // Ascending
      leadsCriteriaRequest.statuses = []
      leadsCriteriaRequest.dealerCodes = rootGetters[DealersGetterInterface.selectedDealerCodes]
      // const leadsResponse = await rtApiService.loadLeadsGirdAsync(leadsCriteriaRequest)
      const leadsResponse = await rtApiService.loadLeadsDynamicAsync(leadsCriteriaRequest)
      // TODO: Resolve Leads related issues
      // INFO: Default-Leads gets filtered out on the service-side >> const defaultLead = leadsResponse.find(lead => lead.type === LeadTypeEnum.Default)
      const clientLeads = leadsResponse || []
      clientsModel.leads = [...clientLeads]
      clientsModel.leadsTotal = clientsModel.leads.length

      if (clientsModel.leadsTotal > 0) {
        // Default to Focused/Expanded Lead
        clientsModel.lead = clientsModel.leads[clientsModel.leads.length - 1]
        // Load Messages
        const messagesCriteriaRequest = JSON.parse(JSON.stringify(DefaultMessagesCriteriaRequest))
        messagesCriteriaRequest.clientId = rootGetters[ClientsCriteriaGetterInterface.clientId]
        messagesCriteriaRequest.leadId = clientsModel.lead.id
        messagesCriteriaRequest.sortBy[MessagesCriteriaSortByEnum.CreatedAt] = false // Ascending
        messagesCriteriaRequest.types = []
        messagesCriteriaRequest.statuses = []
        messagesCriteriaRequest.dealerCodes = rootGetters[DealersGetterInterface.selectedDealerCodes]
        const messagesResponse = await rtApiService.loadLeadMessagesAsync(messagesCriteriaRequest)
        clientsModel.messages = messagesResponse
        clientsModel.messagesTotal = clientsModel.messages.length
      } else {
        clientsModel.lead = undefined
        clientsModel.messages = []
        clientsModel.messagesTotal = 0
      }
    } else {
      commit(InternalClientsMutationInterface.ClientsReset)
    }

    commit(InternalClientsMutationInterface.ClientsLoaded, clientsModel)
    commit(InternalClientsMutationInterface.InProgressIndicator, false)
  },
  loadClientsCompositionNextPageAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    Vue.$log.debug('[DEV]: clients.loadClientsCompositionNextPageAsync()', !getters.isInitialized, !rootGetters[ClientsCriteriaGetterInterface.isInitialized], getters.isAllClientsLoaded)
    if (!getters.isInitialized || !rootGetters[ClientsCriteriaGetterInterface.isInitialized] || getters.isAllClientsLoaded) return

    // Vue.$log.debug('[DEV]: clients.loadClientsCompositionNextPageAsync()')
    const criteria = { ...rootGetters[ClientsCriteriaGetterInterface.criteria] }

    if (getters.hasClients) {
      // Vue.$log.debug('[DEV]: clients.loadClientsCompositionNextPageAsync() >> isGrouped', rootGetters[ClientsCriteriaGetterInterface.isGrouped])
      if (rootGetters[ClientsCriteriaGetterInterface.isGrouped]) {
        criteria.excludeIds = getters.clients.filter(client => !client.isGroupHeader).map(client => client.id)
        // Vue.$log.debug('[DEV]: clients.loadClientsCompositionNextPageAsync() >> criteria.excludeIds', criteria.excludeIds, getters.clients.filter(client => !client.isGroupHeader))
      } else {
        criteria.excludeIds = getters.clients.map(client => client.id)
      }
    }

    // Load Clients Next Page
    // Vue.$log.debug('[DEV]: clients.loadClientsCompositionNextPageAsync() >> rootGetters[ClientsCriteriaGetterInterface.criteria]', rootGetters[ClientsCriteriaGetterInterface.criteria])
    const clientsResponse = await rtApiService.loadClientsDynamicAsync(criteria)
    // Vue.$log.debug('[DEV]: clients.loadClientsCompositionNextPageAsync() >> rootGetters[ClientsCriteriaGetterInterface.criteria]', rootGetters[ClientsCriteriaGetterInterface.criteria])

    if (!clientsResponse) return
    // Extend & Append Clients, Grouping, etc.
    const tempClientsList = clientsResponse.clients
    if (rootGetters[ClientsCriteriaGetterInterface.isGrouped]) {
      Vue.$log.debug('[DEV]: clients.loadClientsCompositionNextPageAsync() >> criteria, tempClientsList[0], getters.clients[getters.clients.length - 1]', criteria, tempClientsList[0], getters.clients[getters.clients.length - 1])
      if (getters.hasClients && tempClientsList[0].group === getters.clients[getters.clients.length - 1].group) {
        tempClientsList.shift() // Prevent duplicate Group Header
      }
      tempClientsList.forEach(client => {
        if (!client.groupId) { // Missing groupId field?
          client.isGroupHeader = true
          client.type = 'group'
          client.size = GroupHeaderListItemHeight
        } else {
          client.isGroupHeader = false
          client.type = 'client'
          client.size = StandardListItemHeight
        }
        client.isMarked = getters.isAllClientsMarked
        // client.isPinned = false
        // client.isOutOfSync = false
        return client
      })
    } else {
      tempClientsList.forEach(client => {
        client.size = StandardListItemHeight
        client.type = 'client'
        client.isGroupHeader = false
        client.isMarked = getters.isAllClientsMarked
        // client.isPinned = false
        // client.isOutOfSync = false
        return client
      })
    }
    const clientsModel = {
      isNextPage: true,
      clients: getters.clients.concat(tempClientsList),
      clientsTotal: clientsResponse.total,
      isAllClientsLoaded: clientsResponse.isLastPage
    }

    commit(InternalClientsMutationInterface.ClientsLoaded, clientsModel)
    // commit(InternalClientsMutationInterface.InProgressIndicator, false)
  },
  selectClientAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }, selectedClient) {
    // Vue.$log.debug('[DEV]: clients.selectClientAsync(selectedClient)', selectedClient)
    if (typeof selectedClient !== 'string' && typeof selectedClient !== 'object') return

    commit(InternalClientsMutationInterface.InProgressIndicator, true)

    if (typeof selectedClient === 'string') { // Is Client Id?
      selectedClient = getters.clients.find(client => client.id === selectedClient)
    }

    // Load new selected Client
    const clientCriteriaRequest = JSON.parse(JSON.stringify(DefaultClientDynamicCriteriaRequest))
    clientCriteriaRequest.clientId = selectedClient.id
    // clientCriteriaRequest.isOutOfSync = true
    Vue.$log.debug('[DEV]: clients.selectClientAsync(selectedClient) >> LOAD CLIENT & DEALERPEAK >> STARTED', selectedClient.id, clientCriteriaRequest)
    const clientResponse = await rtApiService.loadClientDynamicAsync(clientCriteriaRequest)
    Vue.$log.debug('[DEV]: clients.selectClientAsync(selectedClient) >> LOAD CLIENT & DEALERPEAK >> ENDED', selectedClient.id, clientResponse.dealerPeakProfile)

    if (!clientResponse || !clientResponse.client) return

    clientResponse.client.size = selectedClient.size
    clientResponse.client.type = selectedClient.type
    clientResponse.client.isGroupHeader = selectedClient.isGroupHeader
    clientResponse.client.isMarked = selectedClient.isMarked
    // clientResponse.client.isPinned = selectedClient.isPinned
    // clientResponse.client.isOutOfSync = selectedClient.isOutOfSync
    selectedClient = { ...clientResponse.client }

    const clientModel = {
      client: selectedClient,
      clientDuplicatesTotal: clientResponse.totalDuplicates,
      leads: [],
      leadsTotal: 0,
      lead: undefined,
      messages: [],
      messagesTotal: 0,
      dealerPeakProfile: clientResponse.dealerPeakProfile ? clientResponse.dealerPeakProfile : undefined
    }

    // Load Leads
    const leadsCriteriaRequest = JSON.parse(JSON.stringify(DefaultLeadsCriteriaRequest))
    leadsCriteriaRequest.clientId = clientModel.client.id
    leadsCriteriaRequest.sortBy[LeadsCriteriaSortByEnum.UpdatedAt] = false // Ascending
    leadsCriteriaRequest.statuses = []
    leadsCriteriaRequest.dealerCodes = rootGetters[DealersGetterInterface.selectedDealerCodes]
    // const leadsResponse = await rtApiService.loadLeadsGirdAsync(leadsCriteriaRequest)
    const leadsResponse = await rtApiService.loadLeadsDynamicAsync(leadsCriteriaRequest)
    // TODO: Resolve Leads related issues
    // INFO: Default-Leads gets filtered out on the service-side >> const defaultLead = leadsResponse.find(lead => lead.type === LeadTypeEnum.Default)
    const clientLeads = leadsResponse || []
    clientModel.leads = [...clientLeads]
    clientModel.leadsTotal = clientModel.leads.length

    if (clientModel.leadsTotal > 0) {
      // Default to Focused/Expanded Lead
      clientModel.lead = clientModel.leads[clientModel.leads.length - 1]
      // Load Messages
      const messagesCriteriaRequest = JSON.parse(JSON.stringify(DefaultMessagesCriteriaRequest))
      messagesCriteriaRequest.clientId = clientModel.client.id
      messagesCriteriaRequest.leadId = clientModel.lead.id
      messagesCriteriaRequest.sortBy[MessagesCriteriaSortByEnum.CreatedAt] = false // Ascending
      messagesCriteriaRequest.types = []
      messagesCriteriaRequest.statuses = []
      messagesCriteriaRequest.dealerCodes = rootGetters[DealersGetterInterface.selectedDealerCodes]
      const messagesResponse = await rtApiService.loadLeadMessagesAsync(messagesCriteriaRequest)
      clientModel.messages = messagesResponse
      clientModel.messagesTotal = clientModel.messages.length
    } else {
      clientModel.lead = undefined
      clientModel.messages = []
      clientModel.messagesTotal = 0
    }

    Vue.$log.debug('[DEV]: clients.selectClientAsync(selectedClient) >> LOADED CLIENT', selectedClient.id, clientModel)

    // Vue.$log.debug('[DEV]: clients.selectClientAsync(selectedClient) >> COMMIT >> InternalClientsMutationInterface.ClientLoaded', clientModel)
    commit(InternalClientsMutationInterface.ClientLoaded, clientModel)
    commit(InternalClientsMutationInterface.InProgressIndicator, false)
  },
  resetClient: function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    // Vue.$log.debug('[DEV]: clients.unselectClientAsync()')
    commit(InternalClientsMutationInterface.ClientReset, true)
  },
  updatedClient: function ({ state, rootState, commit, dispatch, getters, rootGetters }, client) {
    if (typeof client !== 'object') return
    if (rootGetters[ClientsCriteriaGetterInterface.isGrouped]) {
      client.size = (client.groupId ? StandardListItemHeight : GroupHeaderListItemHeight)
      client.type = !client.groupId ? 'group' : 'client'
      client.isGroupHeader = !client.groupId
    } else {
      client.size = StandardListItemHeight
      client.type = 'client'
      client.isGroupHeader = false
    }
    client.isMarked = client.isMarked || state.isAllClientsMarked
    // client.isPinned = client.isPinned || (getters.client.id === client.id && getters.client.isPinned)
    // client.isOutOfSync = true
    commit(InternalClientsMutationInterface.ClientUpdated, client)
  },
  toggleMarkClient: function ({ state, rootState, commit, dispatch, getters, rootGetters }, client) {
    if (typeof client !== 'string' && typeof client !== 'object') return
    if (typeof client === 'string') {
      commit(InternalClientsMutationInterface.ToggledMarkClient, client)
    } else {
      commit(InternalClientsMutationInterface.ToggledMarkClient, client.id)
    }
  },
  toggleMarkAllClients: function ({ state, rootState, commit, dispatch, getters, rootGetters }, isMarkAll) {
    if (typeof isMarkAll !== 'boolean' || getters.isAllClientsMarked === isMarkAll) return
    // Vue.$log.debug('[DEV]: clients.toggleMarkAllClients(isMarkAll)', isMarkAll)
    commit(InternalClientsMutationInterface.ToggledMarkAllClients, isMarkAll)
  },
  // togglePinClient: function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
  //   if (!getters.hasClient) return
  //   commit(InternalClientsMutationInterface.ToggledPinClient)
  // },
  mergeClientsAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }, mergeClientsRequest) {
    dispatch(ClientsActionInterface.loadClientsCompositionAsync, null, { root: true })
  },
  // Leads
  expandLeadAsync: async function ({ state, rootState, commit, dispatch, getters, rootGetters }, lead) {
    if (typeof lead !== 'object') return

    const messagesCriteriaRequest = JSON.parse(JSON.stringify(DefaultMessagesCriteriaRequest))
    messagesCriteriaRequest.clientId = rootGetters[ClientsCriteriaGetterInterface.clientId]
    messagesCriteriaRequest.leadId = lead.id
    messagesCriteriaRequest.sortBy[MessagesCriteriaSortByEnum.CreatedAt] = false // Ascending
    messagesCriteriaRequest.types = []
    messagesCriteriaRequest.statuses = []
    messagesCriteriaRequest.dealerCodes = rootGetters[DealersGetterInterface.selectedDealerCodes]
    const messagesResponse = await rtApiService.loadLeadMessagesAsync(messagesCriteriaRequest)

    const expandedLeadModel = {
      lead,
      messages: messagesResponse,
      messagesTotal: messagesResponse.length
    }

    commit(InternalClientsMutationInterface.LeadExpanded, expandedLeadModel)
  },
  // Messages
  resetMessageFilter: function ({ state, rootState, commit, dispatch, getters, rootGetters }) {
    commit(InternalClientsMutationInterface.MessagesFiltered, [])
  },
  setMessageFilter: function ({ state, rootState, commit, dispatch, getters, rootGetters }, payload) {
    if (!Array.isArray(payload)) return
    dispatch(NavigateParticipantsActionInterface.reset, null, { root: true })
    dispatch(NavigateMentionsActionInterface.reset, null, { root: true })
    commit(InternalClientsMutationInterface.MessagesFiltered, payload)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
