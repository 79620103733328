import Vue from 'vue'
import axios from 'axios'
import config from './config'

const authorizationProfileApiService = {
  loginAsync: async function (credentials) {
    axios.defaults.withCredentials = true
    try {
      const response = await axios.post(config.profileApiUrl + 'auth', credentials)
      Vue.$log.debug('[DEV]: authorizationProfileApiService.loginAsync() >> RESULT', response)
      return response.data
    } catch (error) {
      if (error.response) {
        Vue.$log.debug('[DEV]: authorizationProfileApiService.loginAsync() >> RESPONSE ERROR', error.response)
        return error.response.data
      } else if (error.request) {
        Vue.$log.debug('[DEV]: authorizationProfileApiService.loginAsync() >> REQUEST ERROR', error.request)
        return new Error('Unable to reach authentication services. Please check network connection and try again.')
      } else {
        Vue.$log.debug('[DEV]: authorizationProfileApiService.loginAsync() >> ERROR', error)
        return error
      }
    }
  },
  verifyAsync: async function () {
    axios.defaults.withCredentials = true
    try {
      const response = await axios.post(config.profileApiUrl + 'auth/verify')
      Vue.$log.debug('[DEV]: authorizationProfileApiService.verifyAsync() >> RESULT', response)
      return response.data
    } catch (error) {
      if (error.response) {
        Vue.$log.debug('[DEV]: authorizationProfileApiService.verifyAsync() >> RESPONSE ERROR', error.response)
        return error.response.data
      } else if (error.request) {
        Vue.$log.debug('[DEV]: authorizationProfileApiService.verifyAsync() >> REQUEST ERROR', error.request)
        return new Error('Unable to reach authentication services. Please check network connection and try again.')
      } else {
        Vue.$log.debug('[DEV]: authorizationProfileApiService.verifyAsync() >> ERROR', error)
        return error
      }
    }
  },
  logoutAsync: async function () {
    axios.defaults.withCredentials = true
    try {
      const response = await axios.post(config.profileApiUrl + 'auth/logout')
      return response.data
    } catch (error) {
      if (error.response) {
        Vue.$log.debug('[DEV]: authorizationProfileApiService.logoutAsync() >> RESPONSE ERROR', error.response)
        return error.response.data
      } else if (error.request) {
        Vue.$log.debug('[DEV]: authorizationProfileApiService.logoutAsync() >> REQUEST ERROR', error.request)
        return new Error('Unable to reach authentication services. Please check network connection and try again.')
      } else {
        Vue.$log.debug('[DEV]: authorizationProfileApiService.logoutAsync() >> ERROR', error)
        return error
      }
    }
  }
}

export default authorizationProfileApiService
